import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";
import { SwipeableDrawer } from "@mui/material";
import { getJobList } from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";
import dayjs from "dayjs";
import PendingJobModal from "./jobmodal/PendingJobModal";
import PendingJobOfficerAceeptModal from "./jobmodal/PendingJobOfficerAceeptModal";
import UnservedJobModal from "./jobmodal/UnservedJobModal";
import ChatModal from "./jobmodal/ChatModal";
import Loader from "../../../../common/loader/Loader";
import { Api } from "../../../../config/Api";
import { newExportExcel } from "../../../../Export/ExportAll";
// import PendingEditJobModal from "./jobmodal/PendingEditJobModal";
import PendingRateJobModal from "./jobmodal/PendingEditJobModal";
import Supervisor from "./jobmodal/Supervisor";
import NonSupervisor from "./jobmodal/NonSupervisor";
import { Checkbox, FormControlLabel } from "@mui/material";
import DataService from "../../../../config/DataService";
import {convertHoursToHHMM} from "../../../../common/timeCalculation/ExtendTime"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const AgencyJobManagement = () => {
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;

  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const { state } = PagesIndex.useLocation();
  // const [tabValue, setTabValue] = useState(state ? state : "PendingUser");
  const [tabValue, setTabValue] = useState(state ? state.status !== undefined ? state.status : state  : "PendingUser");
  const [loading, setLoading] = useState(false);
  const [agencyJobData, setAgencyJobData] = useState([]);
  const [filterAgencyjobsList, setFilterAgencyjobsList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [chatId, setChatId] = useState("");
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [viewOfficerDetails, setViewOfficerDetails] = useState(false);
  const [viewOfficerId, setViewOfficerId] = useState(0);
  const handleClickShowOfficerDetails = (data) => {
    setViewOfficerDetails((view) => !view);
    setViewOfficerId(data?._id);
  };

  // State to track the checked state of each checkbox
  const [checkedItems, setCheckedItems] = React.useState({});
  // State to store the selected item IDs
  const [selectedItemIds, setSelectedItemIds] = React.useState([]);
  // State to track if all checkboxes are checked
  const [allChecked, setAllChecked] = React.useState(false);

  const handleChange = (e, jobId) => {
    const isChecked = e.target.checked;
    // Update the checked state for the individual checkbox
    setCheckedItems((prevState) => ({
      ...prevState,
      [jobId]: isChecked,
    }));

    // Update the selected item IDs array
    setSelectedItemIds((prevState) => {
      if (isChecked) {
        // Add the job ID to the array if checked
        return [...prevState, jobId];
      } else {
        // Remove the job ID from the array if unchecked
        return prevState.filter((id) => id !== jobId);
      }
    });
  };

  const handleToggleCheckAll = () => {
    const newCheckedItems = {};
    const newSelectedItemIds = [];

    if (!allChecked) {
      filterAgencyjobsList.forEach((item) => {
        newCheckedItems[item._id] = true;
        newSelectedItemIds.push(item._id);
      });
    }

    setCheckedItems(newCheckedItems);
    setSelectedItemIds(newSelectedItemIds);
    setAllChecked(!allChecked);
  };

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [ratingvalue, setRatingValue] = React.useState(2);
  useEffect(() => {
    navigate({ state: null });
  }, [navigate]);
  // Pending Job request modal
  const [pendingjobId, setPendingJobId] = useState();
  const [pandingRequestopen, setPandingRequestOpen] = React.useState(false);
  // Pending Rate Job request modal
  const [pendingEditjobId, setPendingEditJobId] = useState();
  const [pandingEditRequestopen, setPandingEditRequestOpen] =
    React.useState(false);
  // Pending Supervisor Job request modal
  const [supervisorjobId, setSupervisorJobId] = useState();
  const [supervisorRequestopen, setSupervisorRequestOpen] = useState(false);
  // Pending Non-Supervisor Job request modal
  const [nonSupervisorjobId, setNonSupervisorJobId] = useState();
  const [nonSupervisorRequestopen, setNonSupervisorRequestOpen] =
    useState(false);
  const handleClosePandingRequest = () => setPandingRequestOpen(false);
  const handleCloseEditPandingRequest = () => setPandingEditRequestOpen(false);
  const handleCloseSupervisor = () => setSupervisorRequestOpen(false);

  // const handleCloseNonSupervisor = () => setNonSupervisorRequestOpen(false);
  const handleCloseUnservedJobs = () => setUnservedJobsOpen(false);
  const handleOpenPandingRequest = (id) => {
    setPendingJobId(id);
    setPandingRequestOpen(true);
  };
  // Edit button
  const handleEditPandingRequest = (id) => {
    setPendingEditJobId(id);
    setPandingEditRequestOpen(true);
  };

  // supervisor
  const handleSupervisorRequest = (id) => {
    setSupervisorJobId(id);
    setSupervisorRequestOpen(true);
  };

  // // Non-supervisor
  // const handleNonSupervisorRequest = (id) => {
  //   setNonSupervisorJobId(id);
  //   setNonSupervisorRequestOpen(true);
  // };

  // Unfilled/Unserved modal
  const [unservedJobsopen, setUnservedJobsOpen] = useState(false);
  const [unservedjobsData, setUnservedJobsData] = useState();
  const handleOpenUnservedJobs = (item) => {
    setUnservedJobsData(item);
    setUnservedJobsOpen(true);
  };

  // Pending Job request after ofiice modal
  const [pendingjobopen, setPendingJobOpen] = React.useState(false);
  const [pendingjobOfficerAcceptData, setPendingjobOfficerAcceptData] =
    useState();
  const pendingjobhandleOpen = (item) => {
    setPendingjobOfficerAcceptData(item);
    setPendingJobOpen(true);
  };
  const pendingjobhandleClose = () => setPendingJobOpen(false);

  // filter
  const [agencyJobManageFilter, setAgencyJobManageFilter] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setAgencyJobManageFilter({ ...state, [anchor]: open });
  };
  // get job list data
  const getJobListData = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("type", tabValue);
    dispatch(getJobList(urlEncoded)).then((res) => {
      if (res?.payload?.status == 200) {
        setAgencyJobData(res?.payload?.data);
        console.log(res?.payload?.data, "dataaaaa");
        setFilterAgencyjobsList(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  PagesIndex.useEffect(() => {
    getJobListData();
  }, [tabValue]);

  // Search on list
  const requestSearch = (searched) => {
    let filteredData = agencyJobData.filter((data) => {
      return !searched
        ? data
        : `${data.title.toLowerCase()} ${data.userId?.name.toLowerCase()}  
        ${data.rate} ${data?.approveBy?.agencyName}
         ${data.supervisor} ${data.nonSupervisor}
         ${data.totalOfficer}
        ${PagesIndex.dayjs(data?.createdAt).format(
          "MM-DD-YYYY"
        )} ${PagesIndex.timeDifferenceCalculation(
          data?.jobStartTime,
          data?.jobEndTime
        )}`.includes(searched?.toLowerCase());
    });
    setFilterAgencyjobsList(filteredData);
  };

  const handleExportData = () => {
    if (tabValue === "PendingUser") {
      newExportExcel(`${Api.Agency.JOB_LIST_CSV_EXPORT}`, "Pending_User_List", {
        type: "PendingUser",
      });
    } 
    // else if (tabValue === "PendingOfficer") {
    //   newExportExcel(
    //     `${Api.Agency.JOB_LIST_CSV_EXPORT}`,
    //     "Pending_Officer_List",
    //     { type: "PendingOfficer" }
    //   );
    // }
     else if (tabValue === "ActiveJobs") {
      newExportExcel(`${Api.Agency.JOB_LIST_CSV_EXPORT}`, "Active_Jobs_List", {
        type: "ActiveJobs",
      });
    } else if (tabValue === "CompletedJobs") {
      newExportExcel(
        `${Api.Agency.JOB_LIST_CSV_EXPORT}`,
        "Completed_Jobs_List",
        { type: "CompletedJobs" }
      );
    } else if (tabValue === "UnservedJobs") {
      newExportExcel(
        `${Api.Agency.JOB_LIST_CSV_EXPORT}`,
        "Unserved_Jobs_List",
        { type: "UnservedJobs" }
      );
    } else {
    }
  };

  const officerMarkedAsPaid = async () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("jobId", selectedItemIds);
    try {
      const response = await DataService.post(
        PagesIndex.Api.Agency.PAYMENT_OFFICER_MARKED,
        urlEncoded
      );
      if (response?.data?.status === 200) {
        setLoading(false);
        PagesIndex.toast.success(response?.data?.message);
        getJobListData();
      }
    } catch (error) {
      setLoading(true);
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (selectedItemIds.length) {
      officerMarkedAsPaid();
    }
  }, [selectedItemIds]);

  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          Job Management
        </Index.Typography>
        <Index.Box className="userlist-btn-flex">
          {/* Top search */}
          <Index.Box className="user-search-main">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => requestSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.value.trim() === "") {
                      e.preventDefault();
                    }
                  }}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Top Filter */}
          {/* <Index.Box className="filter-main">
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Index.Box className="export-btn-main border-btn-main">
                  <Index.Button
                    className="export-btn border-btn"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <img
                      src={PagesIndex.Svg.filter}
                      className="down-icon"
                      alt="download icon"
                    />
                    Filter
                  </Index.Button>
                </Index.Box>
                <SwipeableDrawer
                  className="filter-main"
                  anchor={anchor}
                  open={agencyJobManageFilter[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  <Index.Box className="filter-header">
                    <Index.Typography className="filter-title">
                      User Filter
                    </Index.Typography>
                    <img
                      src={PagesIndex.Svg.closeicon}
                      className="filter-close-icon"
                      onClick={toggleDrawer(anchor, false)}
                      alt=""
                    />
                  </Index.Box>
                  <Index.Box className="filter-inner-main">
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder=""
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        City
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder=""
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        Status
                      </Index.FormHelperText>
                      <Index.Box className="checkbox-main filter-checkbox-main input-box">
                        <Index.FormControlLabel
                          control={<Index.Checkbox defaultChecked />}
                          label="Active"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Deactivate"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Pending"
                          className="checkbox-lable"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="filter-footer">
                    <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                      <Index.Button className="border-btn filter-cancel-btn">
                        Cancel
                      </Index.Button>
                      <Index.Button className="btn-primary filter-btn">
                        Filter
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </Index.Box> */}
          {/* Top Export Button */}
        </Index.Box>
      </Index.Box>
      {loading ? (
        <Loader />
      ) : (
        <Index.Box className="agency-managment">
          <Index.Box className="cus-tabs-main-box">
            <Index.Box sx={{ width: "100%" }}>
              <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Index.Tabs
                  value={tabValue}
                  onChange={handleTabsChange}
                  aria-label="basic tabs example"
                  className="cus-tabs-main"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Index.Tab
                    label="Pending Job Requests (From Users)"
                    value="PendingUser"
                    className="cus-tab"
                  />
                  {/* <Index.Tab
                    label="Pending Job Requests (After officer has accepted)"
                    value="PendingOfficer"
                    className="cus-tab"
                  /> */}
                  <Index.Tab
                    label="Approved Job"
                    value="afterOfficerApprove"
                    className="cus-tab"
                  />
                  <Index.Tab
                    label="Active Jobs"
                    value="ActiveJobs"
                    className="cus-tab"
                  />
                  <Index.Tab
                    label="Completed jobs"
                    value="CompletedJobs"
                    className="cus-tab"
                  />
                  <Index.Tab
                    label="Unfilled/Unserved jobs"
                    value="UnservedJobs"
                    className="cus-tab"
                  />
                </Index.Tabs>
              </Index.Box>
              <TabPanel
                value={tabValue}
                index="PendingUser"
                className="cus-tabpanel"
              >
                {loading ? (
                  <Loader />
                ) : (
                  <Index.Box className="tabpanel-main tabpanel-1">
                    {filterAgencyjobsList.length ? (
                      filterAgencyjobsList.map((item, index) => (
                        <Index.Box className="card-main">
                          <Index.Box className="card-left">
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Job Title :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.title}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Job Started :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {PagesIndex.moment(item?.jobStartDate).format(
                                  "MM-DD-YYYY"
                                )}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Total Officer's :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.totalOfficer}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Posted On :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {PagesIndex.moment(item?.createdAt).format(
                                  "MM-DD-YYYY"
                                )}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Posted By :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.userId?.name}
                              </Index.Typography>
                            </Index.Box>

                            {item?.supervisor ? (
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Supervisor:&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.supervisor}
                                </Index.Typography>
                              </Index.Box>
                            ) : null}

                            {item?.nonSupervisor ? (
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Non Supervisor :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.nonSupervisor}
                                </Index.Typography>
                              </Index.Box>
                            ) : null}
                            {item?.rate == undefined ? null : (
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Pay Rate (Per hour) :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.rate ? "$" : null}
                                  {item?.rate}
                                </Index.Typography>
                                &nbsp;
                                {item?.isRate == true ? (
                                  <img
                                    src={PagesIndex.Svg.checkbutton}
                                    alt="sidebar icon"
                                    className="admin-sidebar-icons"
                                  />
                                ) : null}
                              </Index.Box>
                            )}
                          </Index.Box>
                          <Index.Box className="card-right">
                            {" "}
                            <Index.Box className="progress-job-box">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className={
                                  item?.status === "APPROVED"
                                    ? "status_tag"
                                    : item?.status === "REJECTED"
                                      ? "status_red"
                                      : "status_blue"
                                }
                              >
                                {item?.status === "APPROVED"
                                  ? "Approved"
                                  : item?.status === "REJECTED"
                                    ? "Rejected"
                                    : "In progress"}
                              </Index.Typography>
                              <Index.Box className="job-btn-flex">
                                <Index.PrimaryButton
                                  btnLabel="View Job"
                                  onClick={() => handleOpenPandingRequest(item)}
                                  className="btn-primary"
                                />
                                {console.log(
                                  "611",
                                  item?.supervisor > 0 ||
                                  item?.nonSupervisor > 0,
                                  typeof item.supervisor
                                )}
                                {item?.supervisor > 0 ||
                                  item?.nonSupervisor > 0 ? null : (
                                  <Index.PrimaryButton
                                    btnLabel="Add No Of Officer"
                                    onClick={() =>
                                      handleSupervisorRequest(item)
                                    }
                                    className="btn-primary"
                                  />
                                )}
                              {  console.log("699",item?.rate, item?.supervisor > 0 ||
                                  item?.nonSupervisor > 0)}
                                {(item?.rate == undefined) &&  (item?.supervisor > 0 ||
                                  item?.nonSupervisor > 0) ? (
                                  <Index.Box className="btn-main-primary">
                                    <Index.PrimaryButton
                                      btnLabel="Add Rate"
                                      onClick={() =>
                                        handleEditPandingRequest(item)
                                      }
                                      className="btn-primary"
                                    />
                                  </Index.Box>
                                ) : null}

                                {
                                   (item?.supervisor > 0 ||
                                    item?.nonSupervisor > 0) ? (
                                      <Index.Box>
                                      <Index.PrimaryButton
                                        btnLabel="Chat"
                                        className="btn-primary"
                                        onClick={() => {
                                          toggleModal();
                                          setChatId(item?._id);
                                          setSelectedData(item);
                                        }}
                                      />
                                    </Index.Box>
                                    ) : null
                                }

                                {/* )} */}
                          
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      ))
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </Index.Box>
                )}
              </TabPanel>
              {/* <TabPanel
                value={tabValue}
                index="PendingOfficer"
                className="cus-tabpanel"
              >
                {loading ? (
                  <Loader />
                ) : (
                  <Index.Box className="tabpanel-main tabpanel-2">
                    {filterAgencyjobsList.length ? (
                      filterAgencyjobsList.map((item, index) => (
                        <>
                          <Index.Box className="common-card job-mamangment-card">
                            <Index.Box className="admin-sub-flex">
                              <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title"
                                >
                                  Job Title :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title-value-text"
                                >
                                  {item?.title}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="common-btn-flex">
                                <Index.PrimaryButton
                                  btnLabel="View Job"
                                  className="btn-primary"
                                  onClick={() =>
                                    navigate(
                                      `/${roleName}/agency-job-management/detail-of-pending-officer`,
                                      {
                                        state: {
                                          officerDetails: item,
                                          tab: "PendingOfficer",
                                        },
                                      }
                                    )
                                  }
                                />
                                <Index.PrimaryButton
                                  btnLabel="Chat"
                                  onClick={() => {
                                    toggleModal();
                                    setChatId(item?._id);
                                    setSelectedData(item);
                                  }}
                                  className="btn-primary"
                                />
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="card-main">
                              <Index.Box className="card-left">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Posted By :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.userId?.name}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Date/Time :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.jobSchedule?.map((item, index) => {
                                      return (
                                        <>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-value-text"
                                          >
                                            <span className="day-title">
                                              {PagesIndex.dayjs(
                                                item?.jobStartDate
                                              ).format("MM-DD-YYYY") +
                                                " to " +
                                                PagesIndex.dayjs(
                                                  item?.jobEndDate
                                                ).format("MM-DD-YYYY")}
                                            </span>{" "}
                                            -{" "}
                                            <span>
                                              {item?.jobStartTime
                                                ? item?.jobStartTime + " to "
                                                : null}
                                              {item?.jobEndTime}
                                            </span>
                                          </Index.Typography>
                                        </>
                                      );
                                    })}
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Total Hours :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.timeDifferenceCalculation(item)}{" "}
                                    hour
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Pay Rate (Per hour) :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.rate ? "$" : null}
                                    {item?.rate}
                                  </Index.Typography>
                                  &nbsp;
                                  {item?.isRate == true ? (
                                    <img
                                      src={PagesIndex.Svg.checkbutton}
                                      alt="sidebar icon"
                                      className="admin-sidebar-icons"
                                    />
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="accordian-main">
                              <Index.Accordion className="accordian">
                                <Index.AccordionSummary
                                  className="accordian-summary"
                                  expandIcon={
                                    <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                  }
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Index.Box className="page-inner-title-flex">
                                    <Index.Typography className="page-inner-title">
                                      View Officer Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.AccordionSummary>
                                <Index.AccordionDetails className="accordian-details">
                                  {item?.officerId.map((row) => {
                                    const shortOfficerDetail = row?.officerId;
                                    const particularOfficer = {
                                      officerId: row?.officerId?._id,
                                      jobId: item?._id,
                                    };
                                    return (
                                      <Index.Box className="card-main">
                                        <Index.Box className="card-left">
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Name :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.firstName +
                                                " " +
                                                shortOfficerDetail?.lastName}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Email Address :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.email}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Type :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.officerType}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="card-right">
                                          {" "}
                                          <Index.Box className="progress-job-box">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className={
                                                row?.officerStatus ===
                                                  "APPROVED"
                                                  ? "status_tag"
                                                  : row?.officerStatus ===
                                                    "REJECTED"
                                                    ? "status_red work-title-total"
                                                    : "status_blue work-title-total"
                                              }
                                            >
                                              {row?.officerStatus === "APPROVED"
                                                ? "Approved"
                                                : row?.officerStatus ===
                                                  "REJECTED"
                                                  ? "Rejected"
                                                  : "In progress"}
                                            </Index.Typography>
                                            <Index.Box className="border-btn-main">
                                              <Index.PrimaryButton
                                                btnLabel="View Officer"
                                                onClick={() => {
                                                  pendingjobhandleOpen(
                                                    particularOfficer
                                                  );
                                                }}
                                                className="btn-primary border-btn"
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    );
                                  })}
                                </Index.AccordionDetails>
                              </Index.Accordion>
                            </Index.Box>
                          </Index.Box>
                        </>
                      ))
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </Index.Box>
                )}
              </TabPanel> */}
              <TabPanel
                value={tabValue}
                index="afterOfficerApprove"
                className="cus-tabpanel"
              >
                {loading ? (
                  <Loader />
                ) : (
                  <Index.Box className="tabpanel-main tabpanel-3">
                    {filterAgencyjobsList.length ? (
                      filterAgencyjobsList.map((item, index) => (
                        <>
                          <Index.Box className="common-card job-mamangment-card">
                            <Index.Box className="admin-sub-flex">
                              <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title"
                                >
                                  Job Title :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title-value-text"
                                >
                                  {item?.title}
                                </Index.Typography>
                              </Index.Box>
                              <Index.PrimaryButton
                                btnLabel="View Job"
                                className="btn-primary"
                                onClick={() =>
                                  navigate(
                                    `/${roleName}/agency-job-management/detail-of-pending-officer`,
                                    {
                                      state: {
                                        officerDetails: item,
                                        tab: "afterOfficerApprove",
                                      },
                                    }
                                  )
                                }
                              />
                            </Index.Box>
                            <Index.Box className="card-main">
                              <Index.Box className="card-left">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Posted By :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.userId?.name}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Date/Time :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.jobSchedule?.map((item, index) => {
                                      return (
                                        <>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-value-text"
                                          >
                                            <span className="day-title">
                                              {PagesIndex.dayjs(
                                                item?.jobStartDate
                                              ).format("MM-DD-YYYY") +
                                                " to " +
                                                PagesIndex.dayjs(
                                                  item?.jobEndDate
                                                ).format("MM-DD-YYYY")}
                                            </span>{" "}
                                            -{" "}
                                            <span>
                                              {item?.jobStartTime
                                                ? item?.jobStartTime + " to "
                                                : null}
                                              {item?.jobEndTime}
                                            </span>
                                          </Index.Typography>
                                        </>
                                      );
                                    })}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Total Hours :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.timeDifferenceCalculation(item)}{" "}
                                    hour
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable "
                                  >
                                    Pay Rate (Per hour) :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.rate ? "$" : null}
                                    {item?.rate}
                                  </Index.Typography>
                                  &nbsp;
                                  {item?.isRate == true ? (
                                    <img
                                      src={PagesIndex.Svg.checkbutton}
                                      alt="sidebar icon"
                                      className="admin-sidebar-icons"
                                    />
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="accordian-main">
                              <Index.Accordion className="accordian">
                                <Index.AccordionSummary
                                  className="accordian-summary"
                                  expandIcon={
                                    <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                  }
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Index.Box className="page-inner-title-flex">
                                    <Index.Typography className="page-inner-title">
                                      View Officer Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.AccordionSummary>
                                <Index.AccordionDetails className="accordian-details">
                                  {item?.officerId.map((row) => {
                                    const shortOfficerDetail = row?.officerId;
                                    const particularOfficer = {
                                      officerId: row?.officerId?._id,
                                      jobId: item?._id,
                                    };
                                    return (
                                      <Index.Box className="card-main job-mamangment-inner-card">
                                        <Index.Box className="card-left">
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Name :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.firstName +
                                                " " +
                                                shortOfficerDetail?.lastName}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Email Address :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.email}
                                              {console.log(row, "row")}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Type :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.officerType}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box className="card-right">
                                          {" "}
                                          <Index.Box className="border-btn-main">
                                            <Index.PrimaryButton
                                              btnLabel="View Officer"
                                              onClick={() =>
                                                pendingjobhandleOpen(
                                                  particularOfficer
                                                )
                                              }
                                              className="btn-primary border-btn"
                                            />
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    );
                                  })}
                                </Index.AccordionDetails>
                              </Index.Accordion>
                            </Index.Box>
                          </Index.Box>
                        </>
                      ))
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </Index.Box>
                )}
              </TabPanel>
              <TabPanel
                value={tabValue}
                index="ActiveJobs"
                className="cus-tabpanel"
              >
                <Index.Box className="tabpanel-main tablepanel-4">
                  <>
                    {filterAgencyjobsList?.length ? (
                      filterAgencyjobsList.map((item, parentIndex) => (
                        <Index.Box className="common-card job-mamangment-card">
                          <Index.Box className="admin-sub-flex">
                            <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                              <Index.Typography className="admin-sub-title">
                                Job Title :&nbsp;
                              </Index.Typography>
                              <Index.Typography className="admin-sub-title-value-text">
                                {item?.title}
                              </Index.Typography>
                            </Index.Box>
                            <Index.PrimaryButton
                              btnLabel="View Job"
                              onClick={() =>
                                navigate(
                                  `/${roleName}/agency-job-management/detail-active-jobs`,
                                  { state: item }
                                )
                              }
                              className="btn-primary"
                            />
                          </Index.Box>
                          <Index.Box className="card-main">
                            <Index.Box className="card-left">
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Posted By :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.userId?.name}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Posted On :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {PagesIndex.dayjs(item?.createdAt).format(
                                    "MM-DD-YYYY"
                                  )}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Approved By :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.approveBy?.agencyName}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Pay Rate (Per hour) :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {" "}
                                  {item?.rate ? "$" : null}
                                  {item?.rate}
                                </Index.Typography>
                                &nbsp;
                                {item?.isRate == true ? (
                                  <img
                                    src={PagesIndex.Svg.checkbutton}
                                    alt="sidebar icon"
                                    className="admin-sidebar-icons"
                                  />
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="card-right">
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable work-title-total"
                                >
                                  Total :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  $
                                  {PagesIndex.TotalCalculateByTime(
                                    item,
                                    item.rate
                                  )}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="accordian-main">
                            <Index.Accordion className="accordian">
                              <Index.AccordionSummary
                                className="accordian-summary"
                                expandIcon={
                                  <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Index.Box className="page-inner-title-flex">
                                  <Index.Typography className="page-inner-title">
                                    View Officer Details
                                  </Index.Typography>
                                </Index.Box>
                              </Index.AccordionSummary>
                              <Index.AccordionDetails className="accordian-details">
                                {item?.jobSchedule.map((row, jobIndex) => {
                                  return (
                                    <Index.Box
                                      className="card-main job-mamangment-inner-card"
                                      key={row?._id}
                                    >
                                      <Index.Box className="card-left">
                                        <Index.Box className="work-title-flex">
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-title-lable"
                                          >
                                            Date/Time :&nbsp;
                                          </Index.Typography>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-value-text"
                                          >
                                            <span className="day-title">
                                              {PagesIndex.dayjs(
                                                row?.jobStartDate
                                              ).format("MM-DD-YYYY") +
                                                " to " +
                                                PagesIndex.dayjs(
                                                  row?.jobEndDate
                                                ).format("MM-DD-YYYY")}
                                            </span>{" "}
                                            -{" "}
                                            <span>
                                              {row?.jobStartTime
                                                ? row?.jobStartTime + " to "
                                                : null}
                                              {row?.jobEndTime}
                                            </span>
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>

                                      {item?.extendHours == 0 ||
                                        item?.extendHours == undefined ? null : (
                                        <Index.Box className="work-title-flex">
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-title-lable"
                                          >
                                            Extend Time :&nbsp;
                                          </Index.Typography>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-value-text"
                                          >
                                            {/* {convertHoursToHHMM(item?.extendHours)} */}
                                                  {row?.extendHours != null &&
                                                  row?.extendHours !== ""
                                                    ? convertHoursToHHMM(
                                                        row?.extendHours
                                                      )
                                                    : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      )}
                                      <Index.Box className="card-right">
                                        {item?.officerId.map((row) => {
                                          const particularOfficer = {
                                            officerId: row?.officerId?._id,
                                            jobId: item?._id,
                                          };
                                          return (
                                            <Index.Box className="border-btn-main">
                                              <Index.PrimaryButton
                                                btnLabel="View Officer"
                                                onClick={() =>
                                                  pendingjobhandleOpen(
                                                    particularOfficer
                                                  )
                                                }
                                                // className="border-btn"
                                                className="btn-primary border-btn"
                                              />
                                            </Index.Box>
                                          );
                                        })}
                                      </Index.Box>
                                    </Index.Box>
                                  );
                                })}
                              </Index.AccordionDetails>
                            </Index.Accordion>
                          </Index.Box>
                        </Index.Box>
                      ))
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </>
                </Index.Box>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index="CompletedJobs"
                className="cus-tabpanel"
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <Index.Box className="box-checkall">
                      <Index.Box className="tabpanel-main tablepanel-5">
                        {filterAgencyjobsList.length > 0 &&
                          (filterAgencyjobsList[0]?.approveBy
                            ?.departmentName === "Agency" ||
                            (filterAgencyjobsList[0]?.approveBy
                              ?.departmentName === "Department" &&
                              filterAgencyjobsList[0]?.approveBy
                                ?.paymentType !== undefined &&
                              filterAgencyjobsList[0]?.approveBy
                                ?.paymentType ===
                              "Through Police Department")) && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className="role-check-box"
                                  checked={allChecked}
                                  onChange={handleToggleCheckAll}
                                />
                              }
                              label="Check All For Paid"
                            />

                            // <Index.Button onClick={handleToggleCheckAll}>
                            //   {allChecked ? "Uncheck All" : "Check All"}
                            // </Index.Button>
                          )}
                      </Index.Box>
                    </Index.Box>
                    {/* } */}

                  {/* {  console.log("15888",filterAgencyjobsList)} */}

                    {filterAgencyjobsList.length ? (
                      filterAgencyjobsList.map((item, index) => (
                        <>
                          <Index.Box
                            key={item._id}
                            className="common-card job-mamangment-card"
                          >
                            <Index.Box className="admin-sub-flex">
                              <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title"
                                >
                                  Job :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title-value-text"
                                >
                                  {item?.title}
                                </Index.Typography>
                              </Index.Box>

                              {/* {console.log("checked",checked)} */}

                              {/* {(!item.isAgencyPaymentToOfficer && item.approveBy.departmentName == "Agency") || (!item.isAgencyPaymentToOfficer && item.approveBy.departmentName == "Department" && item.approveBy.paymentType !== undefined && item.approveBy.paymentType == "Through Police Department") ? ( */}

                              <Index.Box className="common-btn-flex">
                                {(!item.isAgencyPaymentToOfficer &&
                                  item.approveBy?.departmentName == "Agency") ||
                                  (!item.isAgencyPaymentToOfficer &&
                                    item.approveBy?.departmentName ==
                                    "Department" &&
                                    item.approveBy.paymentType !== undefined &&
                                    item?.approveBy?.paymentType ==
                                    "Through Police Department") ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        className="role-check-box"
                                        checked={!!checkedItems[item._id]}
                                        onChange={(e) =>
                                          handleChange(e, item._id)
                                        }
                                      />
                                    }
                                    label="for paid"
                                  />
                                ) : null}
                                <Index.PrimaryButton
                                  btnLabel="View Job"
                                  className="btn-primary border-btn"
                                  onClick={() =>
                                    navigate(
                                      `/${roleName}/agency-job-management/detail-of-completed-jobs`,
                                      { state: item }
                                    )
                                  }
                                />
                                <Index.PrimaryButton
                                  btnLabel="Chat"
                                  onClick={() => {
                                    toggleModal();
                                    // item?._id
                                    setChatId(item?.jobId);
                                    setSelectedData(item);
                                  }}
                                  className="btn-primary"
                                />
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="card-main">
                              <Index.Box className="card-left">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Posted By :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.userId?.name}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Location :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.location}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Date/Time :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.jobSchedule?.map((item, index) => {
                                      return (
                                        <>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-value-text"
                                          >
                                            <span className="day-title">
                                              {PagesIndex.dayjs(
                                                item?.jobStartDate
                                              ).format("MM-DD-YYYY") +
                                                " to " +
                                                PagesIndex.dayjs(
                                                  item?.jobEndDate
                                                ).format("MM-DD-YYYY")}
                                            </span>{" "}
                                            -{" "}
                                            <span>
                                              {item?.jobStartTime
                                                ? item?.jobStartTime + " to "
                                                : null}
                                              {item?.jobEndTime}
                                            </span>
                                          </Index.Typography>
                                        </>
                                      );
                                    })}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Total Hours :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.timeDifferenceCalculation(item)}{" "}
                                    hour
                                    {/* {PagesIndex.timeDifferenceCalculation(
                                      item?.jobSchedule[0]?.jobStartTime,
                                      item?.jobSchedule[0]?.jobEndTime
                                    )} */}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Pay Rate (Per hour) :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.rate ? "$" : null}
                                    {item?.rate}
                                  </Index.Typography>
                                  &nbsp;
                                  {item?.isRate == true ? (
                                    <img
                                      src={PagesIndex.Svg.checkbutton}
                                      alt="sidebar icon"
                                      className="admin-sidebar-icons"
                                    />
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="card-right">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable work-title-total"
                                  >
                                    Total :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    $
                                    {PagesIndex.TotalCalculateByTime(
                                      item,
                                      item.rate
                                    )}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="accordian-main">
                              <Index.Accordion className="accordian">
                                <Index.AccordionSummary
                                  className="accordian-summary"
                                  expandIcon={
                                    <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                  }
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Index.Box className="page-inner-title-flex">
                                    <Index.Typography className="page-inner-title">
                                      View Officer Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.AccordionSummary>
                                <Index.AccordionDetails className="accordian-details">
                                  {item?.officerId.map((row) => {
                                    const shortOfficerDetail = row?.officerId;
                                    const particularOfficer = {
                                      officerId: row?.officerId?._id,
                                      jobId: item?._id,
                                    };
                                    return (
                                      <Index.Box className="card-main">
                                        <Index.Box className="card-left">
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Name :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.firstName +
                                                " " +
                                                shortOfficerDetail?.lastName}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Email Address :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.email}
                                              {console.log(row, "row")}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Type :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.officerType}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box className="card-right">
                                          {" "}
                                          <Index.Box className="border-btn-main">
                                            <Index.PrimaryButton
                                              btnLabel="View Officer"
                                              onClick={() =>
                                                pendingjobhandleOpen(
                                                  particularOfficer
                                                )
                                              }
                                              // className="border-btn"
                                              className="btn-primary border-btn"
                                            />
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    );
                                  })}
                                </Index.AccordionDetails>
                              </Index.Accordion>
                            </Index.Box>
                          </Index.Box>
                        </>
                      ))
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={"UnservedJobs"}
                className="cus-tabpanel"
              >
                {loading ? (
                  <Loader />
                ) : (
                  <Index.Box className="tabpanel-main tabpanel-6">
                    {filterAgencyjobsList.length ? (
                      filterAgencyjobsList.map((item, index) => (
                        <>
                          <Index.Box className="common-card job-mamangment-card">
                            <Index.Box className="admin-sub-flex">
                              <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title"
                                >
                                  Job :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title-value-text"
                                >
                                  {item?.title}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="common-btn-flex">
                                <Index.Box className="btn-main-primary">
                                  <Index.PrimaryButton
                                    btnLabel="View Job"
                                    className="btn-primary"
                                    onClick={() => handleOpenUnservedJobs(item)}
                                  />
                                </Index.Box>
                                <Index.Box>
                                  <Index.PrimaryButton
                                    btnLabel="Chat"
                                    onClick={() => {
                                      toggleModal();
                                      setChatId(item?.jobId);
                                      // setChatId(item?._id);
                                      setSelectedData(item);
                                    }}
                                    className="btn-primary"
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="card-main">
                              <Index.Box className="card-left">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Client :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.userId?.name}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Location :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.location}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Job Starting Date :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.moment(
                                      item?.jobSchedule[0]?.jobStartDate
                                    ).format("MM-DD-YYYY")}
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Job Ending Date :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.moment(item?.jobSchedule[0]?.jobEndDate).format(
                                      "MM-DD-YYYY"
                                    )}
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Total Hours :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.timeDifferenceCalculation(item)}{" "}
                                    hour
                                    {/* {PagesIndex.timeDifferenceCalculation(
                                      item?.jobSchedule[0]?.jobStartTime,
                                      item?.jobSchedule[0]?.jobEndTime
                                    )} */}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Pay Rate (Per hour) :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.rate ? "$" : null}
                                    {item?.rate}
                                  </Index.Typography>
                                  &nbsp;
                                  {item?.isRate == true ? (
                                    <img
                                      src={PagesIndex.Svg.checkbutton}
                                      alt="sidebar icon"
                                      className="admin-sidebar-icons"
                                    />
                                  ) : null}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="accordian-main">
                              <Index.Accordion className="accordian">
                                <Index.AccordionSummary
                                  className="accordian-summary"
                                  expandIcon={
                                    <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                  }
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Index.Box className="page-inner-title-flex">
                                    <Index.Typography className="page-inner-title">
                                      View Officer Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.AccordionSummary>
                                <Index.AccordionDetails className="accordian-details">
                                  {item?.officerId.map((row) => {
                                    const shortOfficerDetail = row?.officerId;
                                    const particularOfficer = {
                                      officerId: row?.officerId?._id,
                                      jobId: item?._id,
                                    };
                                    return (
                                      <Index.Box className="card-main">
                                        <Index.Box className="card-left">
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Name :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.firstName +
                                                " " +
                                                shortOfficerDetail?.lastName}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Email Address :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.email}
                                              {console.log(row, "row")}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Type :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.officerType}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box className="card-right">
                                          {" "}
                                          <Index.Box className="border-btn-main">
                                            <Index.PrimaryButton
                                              btnLabel="View Officer"
                                              onClick={() =>
                                                pendingjobhandleOpen(
                                                  particularOfficer
                                                )
                                              }
                                              // className="border-btn"
                                              className="btn-primary border-btn"
                                            />
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    );
                                  })}
                                </Index.AccordionDetails>
                              </Index.Accordion>
                            </Index.Box>
                          </Index.Box>
                        </>
                      ))
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </Index.Box>
                )}
              </TabPanel>
            </Index.Box>
          </Index.Box>

          {/* Pending job Request modal */}
          <PendingJobModal
            pandingRequestopen={pandingRequestopen}
            handleClosePandingRequest={handleClosePandingRequest}
            pendingjobId={pendingjobId}
            getJobListData={getJobListData}
            agencyJobData={agencyJobData}
          />
          {/* Pending Edit job Request modal */}
          <PendingRateJobModal
            pandingEditRequestopen={pandingEditRequestopen}
            handleCloseEditPandingRequest={handleCloseEditPandingRequest}
            pendingEditjobId={pendingEditjobId}
            getJobListData={getJobListData}
            agencyJobData={agencyJobData}
          />
          {/* supervisor modal */}
          <Supervisor
            supervisorRequestopen={supervisorRequestopen}
            handleCloseSupervisor={handleCloseSupervisor}
            supervisorjobId={supervisorjobId}
            getJobListData={getJobListData}
            agencyJobData={agencyJobData}
            nonSupervisorjobId={nonSupervisorjobId}
            filterAgencyjobsList={filterAgencyjobsList}
          />
          {/* Non-Supervisor modal */}
          {/* <NonSupervisor
            nonSupervisorRequestopen={nonSupervisorRequestopen}
            handleCloseNonSupervisor={handleCloseNonSupervisor}
            nonSupervisorjobId={nonSupervisorjobId}
            getJobListData={getJobListData}
            agencyJobData={agencyJobData}
          /> */}

          {/* Unfilled/Unserved job modal */}
          <UnservedJobModal
            unservedJobsopen={unservedJobsopen}
            handleCloseUnservedJobs={handleCloseUnservedJobs}
            unservedjobsData={unservedjobsData}
          />
          {/* Pending job Request after officer accepted modal */}
          <PendingJobOfficerAceeptModal
            pendingjobopen={pendingjobopen}
            pendingjobhandleClose={pendingjobhandleClose}
            pendingjobOfficerAcceptData={pendingjobOfficerAcceptData}
            getJobListData={getJobListData}
            isShowApproveRejectButton={
              tabValue == "PendingOfficer" ? true : false
            }
          />

          {/* <ChatModal
            open={isModalOpen}
            onClose={toggleModal}
        
            chatId={chatId}
            setChatId={setChatId}
          /> */}
          {isModalOpen && (
            <ChatModal
              open={isModalOpen}
              onClose={toggleModal}
              chatId={chatId}
              agencyJobData={agencyJobData}
              setChatId={setChatId}
              tabName={tabValue}
              selectedData={selectedData}
            />
          )}
        </Index.Box>
      )}
    </>
  );
};

export default AgencyJobManagement;

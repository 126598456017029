import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import PropTypes from "prop-types";
import { SwipeableDrawer } from "@mui/material";
import Rating from "@mui/material/Rating";
import { getClientJobList } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import { timeDifferenceCalculation } from "../../../../common/timeCalculation/CalculateTimeDifference";
import { CostCalculateByTime } from "../../../../common/timeCalculation/CostCalculateByTime";
import { useLocation, useParams } from "react-router-dom";
import PendingJobOfficerAceeptModal from "../agencyjobmanagement/jobmodal/PendingJobOfficerAceeptModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const VendorHistory = () => {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const { id } = useParams();
  const [userjobData, setUserJobData] = useState([]);
  const [filteredUserJobData, setFilteredUserJobData] = useState([]);
  const [tabValue, setTabValue] = React.useState("ActiveJobs");
  const [userID, setUserID] = useState(id);

  // Pending Job request after ofiice modal
  const [pendingjobopen, setPendingJobOpen] = React.useState(false);
  const [pendingjobOfficerAcceptData, setPendingjobOfficerAcceptData] =
    useState();
  const pendingjobhandleOpen = (item) => {
    setPendingjobOfficerAcceptData(item);
    setPendingJobOpen(true);
  };
  const pendingjobhandleClose = () => setPendingJobOpen(false);

  // Pagination states and methods
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // role permission
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
    setUserID(id);
  };

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const clientJobList = () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("type", tabValue);
    urlEncoded.append("userId", userID);
    dispatch(getClientJobList(urlEncoded)).then((res) => {
      if (res?.payload?.status == 200) {
        setUserJobData(res?.payload?.data);
        setFilteredUserJobData(res?.payload?.data);
      }
    });
  };
  useEffect(() => {
    clientJobList();
  }, [tabValue]);

  // search
  const handleSearch = (searched) => {
    if (!searched) return setFilteredUserJobData(userjobData);
    setFilteredUserJobData(
      userjobData?.filter(
        (item) =>
          item?.title
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.location
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.agency?.agencyName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase())
      )
    );
    setPage(0);
  };


  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          {roleName === "Agency" ||
            roleName === "Department" ||
            AgencyPermission?.Client?.permission?.read == true ? (
            <Index.Link
              to={`/${roleName}/vendor-management`}
            >
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
              />
            </Index.Link>
          ) : null}
          Vendor's job History
        </Index.Typography>


        <Index.Box className="userlist-btn-flex">
          <Index.Box className="user-search-main">
            {/* Top search */}
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value.trim())}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* Top filter */}
          {/* <Index.Box className="filter-main">
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Index.Box className="export-btn-main border-btn-main">
                  <Index.Button
                    className="export-btn border-btn"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <img
                      src={PagesIndex.Svg.filter}
                      className="down-icon"
                      alt="download icon"
                    />
                    Filter
                  </Index.Button>
                </Index.Box>
                <SwipeableDrawer
                  className="filter-main"
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  <Index.Box className="filter-header">
                    <Index.Typography className="filter-title">
                      User Filter
                    </Index.Typography>
                    <img
                      src={PagesIndex.Svg.closeicon}
                      className="filter-close-icon"
                      onClick={toggleDrawer(anchor, false)}
                      alt=""
                    />
                  </Index.Box>
                  <Index.Box className="filter-inner-main">
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder=""
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        City
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder=""
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        Status
                      </Index.FormHelperText>
                      <Index.Box className="checkbox-main filter-checkbox-main input-box">
                        <Index.FormControlLabel
                          control={<Index.Checkbox defaultChecked />}
                          label="Active"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Deactivate"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Pending"
                          className="checkbox-lable"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="filter-footer">
                    <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                      <Index.Button className="border-btn filter-cancel-btn">
                        Cancel
                      </Index.Button>
                      <Index.Button className="btn-primary filter-btn">
                        Filter
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </Index.Box> */}
          <Index.Box className="userlist-inner-btn-flex">
            <Index.Box className="adduser-btn-main btn-main-primary">
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="vendor-history-sec">
        <Index.Box className="cus-tabs-main-box">
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={tabValue}
              onChange={handleTabsChange}
              variant="scrollable"
              // scrollButtons
              allowScrollButtonsMobile
              aria-label="basic tabs example"
              className="cus-tabs-main"
            >
              <Index.Tab
                label="Active Jobs"
                value="ActiveJobs"
                className="cus-tab"
              />
              <Index.Tab
                label="Completed jobs"
                value="CompletedJobs"
                className="cus-tab"
              />
            </Index.Tabs>
          </Index.Box>

          <TabPanel
            value={tabValue}
            index="ActiveJobs"
            className="cus-tabpanel"
          >
            <Index.Box className="tabpanel-main tabpanel-1">
              {filteredUserJobData.length ? (
                filteredUserJobData.map((item, index) => (
                  <>
                    <Index.Box className="common-card job-mamangment-card">
                      <Index.Box className="admin-sub-flex">
                          <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="admin-sub-title"
                          >
                            Job Title :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="admin-sub-title-value-text"
                          >
                            {item?.title}
                          </Index.Typography>
                        </Index.Box>
                        <Index.PrimaryButton
                          onClick={() =>
                            navigate(`/${roleName}/vendor-management/vendorhistorydetailsofactivejob/${item._id}`, { state: item })
                          }
                          btnLabel="View Job"
                          className="btn-primary"
                        />
                      </Index.Box>
                      <Index.Box className="card-main">
                        <Index.Box className="card-left">
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Location :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {item?.location}
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              {/* Agency :&nbsp; */}
                              {item?.department == "Police department"? "Associated Department:" : "Associated Agency:"}
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {item?.approveBy?.agencyName}
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Total Hours :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                             {PagesIndex.timeDifferenceCalculation(item)} hour
                            </Index.Typography>
                          </Index.Box>


                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Pay Rate :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              ${item?.rate}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="card-right">
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Total :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              ${PagesIndex.TotalCalculateByTime(item, item.rate)}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="accordian-main">
                        <Index.Accordion className="accordian">
                          <Index.AccordionSummary
                            className="accordian-summary"
                            expandIcon={
                              <Index.ExpandMoreIcon className="accordian-expan-icon" />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Index.Box className="page-inner-title-flex">
                              <Index.Typography className="page-inner-title">
                                View Officer Details
                              </Index.Typography>
                            </Index.Box>
                          </Index.AccordionSummary>

                        </Index.Accordion>
                      </Index.Box>
                    </Index.Box>
                    {item?.officerId.map((row) => {
                      const shortOfficerDetail = row?.officerId;
                      const particularOfficer = {
                        officerId: row?.officerId?._id,
                        jobId: item?._id,
                        officerJobId: row?._id,
                      };
                      return (
                        <>
                          <Index.AccordionDetails className="accordian-details">
                            <Index.Box className="card-main job-mamangment-inner-card">
                              <Index.Box className="card-left">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Name :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    {shortOfficerDetail
                                      ? shortOfficerDetail?.firstName
                                      : "" + " " + shortOfficerDetail?.lastName}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Email Address :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {shortOfficerDetail?.email}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Type :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {shortOfficerDetail?.officerType}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="border-btn-main">
                                              <Index.PrimaryButton
                                                btnLabel="View Officer"
                                                onClick={() => {
                                                  pendingjobhandleOpen(
                                                    particularOfficer
                                                  );
                                                }}
                                                className="btn-primary"
                                              />
                                            </Index.Box>
                            </Index.Box>
                          </Index.AccordionDetails>
                        </>
                      );
                    })}
                  </>
                ))
              ) : (
                <PagesIndex.NoDataFound />
              )}
            </Index.Box>
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="CompletedJobs"
            className="cus-tabpanel"
          >
            <Index.Box className="tabpanel-main tabpanel-2">
              <>
                {filteredUserJobData.length ? (
                  filteredUserJobData.map((item, index) => (
                    <Index.Box className="common-card job-mamangment-card">
                      <Index.Box className="admin-sub-flex">
                        <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="admin-sub-title"
                          >
                            Job Title :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="admin-sub-title-value-text"
                          >
                            {item?.title}
                          </Index.Typography>
                        </Index.Box>
                        <Index.PrimaryButton
                          onClick={() =>
                            navigate(`/${roleName}/vendor-management/vendorhistorydetailsofcompletedjobs/${item._id}`, { state: item })
                          }
                          btnLabel="View Job"
                          className="btn-primary"
                        />
                      </Index.Box>
                      <Index.Box className="card-main">
                        <Index.Box className="card-left">
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Location :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {item?.location}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              {item?.department == "Police department"? "Associated Department:" : "Associated Agency:"}

                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {item?.approveBy?.agencyName}

                            </Index.Typography>
                          </Index.Box>

                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Total Hours :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {PagesIndex.timeDifferenceCalculation(item)} hour
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Pay Rate :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              ${item?.rate}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right">
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable work-title-total"
                            >
                              Total :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              ${PagesIndex.TotalCalculateByTime(item, item.rate)}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="accordian-main">
                        <Index.Accordion className="accordian">
                          <Index.AccordionSummary
                            className="accordian-summary"
                            expandIcon={
                              <Index.ExpandMoreIcon className="accordian-expan-icon" />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Index.Box className="page-inner-title-flex">
                              <Index.Typography className="page-inner-title">
                                View Officer Detail
                              </Index.Typography>
                            </Index.Box>
                          </Index.AccordionSummary>
                          <Index.AccordionDetails className="accordian-details">
                            {item?.officerId.map((row) => {
                              const shortOfficerDetail = row?.officerId;
                              const particularOfficer = {
                                officerId: row?.officerId?._id,
                                jobId: item?._id,
                                officerJobId: row?._id,
                              };
                              return (
                                <>
                                  <Index.Box className="card-main job-mamangment-inner-card">
                                    <Index.Box className="card-left">
                                      <Index.Box className="work-title-flex">
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="work-title-lable"
                                        >
                                          Name :&nbsp;
                                        </Index.Typography>
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="work-value-text"
                                        >
                                          {shortOfficerDetail
                                            ? shortOfficerDetail?.firstName
                                            : "" + " " + shortOfficerDetail?.lastName}
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="work-title-flex">
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="work-title-lable"
                                        >
                                          Email Address :&nbsp;
                                        </Index.Typography>
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="work-value-text"
                                        >
                                          {shortOfficerDetail?.email}
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="work-title-flex">
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="work-title-lable"
                                        >
                                          Type :&nbsp;
                                        </Index.Typography>
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="work-value-text"
                                        >
                                          {shortOfficerDetail?.officerType}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="border-btn-main">
                                              <Index.PrimaryButton
                                                btnLabel="View Officer"
                                                onClick={() => {
                                                  pendingjobhandleOpen(
                                                    particularOfficer
                                                  );
                                                }}
                                                className="btn-primary"
                                              />
                                            </Index.Box>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.AccordionDetails>
                        </Index.Accordion>
                      </Index.Box>
                    </Index.Box>
                  ))
                ) : (
                  <PagesIndex.NoDataFound />
                )}
              </>
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>
       {/* Pending job Request modal */}
       <PendingJobOfficerAceeptModal
            pendingjobopen={pendingjobopen}
            pendingjobhandleClose={pendingjobhandleClose}
            pendingjobOfficerAcceptData={pendingjobOfficerAcceptData}
            clientJobList={clientJobList}
            isShowApproveRejectButton={
              tabValue == "PendingOfficer" ? true : false
            }
          />
    </>
  );
};
export default VendorHistory;

import React, { useEffect, useState } from "react";
import Index from "../../../index";

import { ADMIN_IMAGE_ENDPOINT } from "../../../../config/DataService";
import PagesIndex from "../../../PageIndex";
import { AdminPasswordChangeModal } from "./AdminPasswordChangeModal";
import { getAdminData } from "../../../../redux-toolkit/slice/admin-slice/AdminSlice";
import Loader from "../../../../common/loader/Loader";

export default function AdminProfile() {
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const [addnewopen, setAddNewOpen] = React.useState(false);
  const addnewhandleOpen = () => setAddNewOpen(true);
  const addnewhandleClose = () => setAddNewOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;

  //  phone number functionality
  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
    
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");
    
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }
    
    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
};
  const initialValues = {
    name: adminLoginData ? adminLoginData?.name : "",
    email: adminLoginData ? adminLoginData?.email : "",
    mobileNumber: adminLoginData ? adminLoginData?.mobileNumber : "",
    image: adminLoginData ? adminLoginData?.image : "",
  };

  const adminProfileUpdate = async (values) => {
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("name", values.name);
    formdata.append("email", values.email.toLowerCase());
    formdata.append("mobileNumber", values.mobileNumber);
    formdata.append("image", values.image);

    try {
      const response = await PagesIndex.DataService.post(
        `${PagesIndex.Api.Admin.PROFILE_UPDATE}/${adminLoginData._id}`,
        formdata
      );

      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customId",
        });
        const data = response?.data?.data;
        dispatch(getAdminData(data));
        setIsLoading(false);
        navigate("/admin/dashboard");
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customId",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Box className="title-header-flex">
          <Index.Link to="/admin/dashboard">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            />
          </Index.Link>
        </Index.Box>
      </Index.Box>
      <PagesIndex.Formik
        validationSchema={PagesIndex.adminProfileUpdateSchema}
        initialValues={initialValues}
        onSubmit={adminProfileUpdate}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            {console.log(errors, "error")}
            <Index.Box className="profile-main common-card w-100">
              <Index.Grid container spacing={3} justifyContent="center">
                <Index.Grid md={4} xs={12} item>
                  <Index.Box className="profile-edit-flex">
                    <Index.Box className="profile-img-box">
                      <img
                        src={
                          values?.image
                            ? values?.image instanceof Blob
                              ? URL.createObjectURL(values.image)
                              : `${ADMIN_IMAGE_ENDPOINT}${values?.image}`
                            : PagesIndex.Png.usericon
                        }
                        className="upload-prof-img"
                        alt=""
                      />
                    </Index.Box>
                    <Index.Box className="profile-file-upload-main">
                      <Index.IconButton
                        color="primary"
                        className="profile-upload-btn"
                        aria-label="upload picture"
                        component="label"
                        disableRipple
                      >
                        <input
                          hidden
                          name="image"
                          accept="image/*"
                          type="file"
                          className="upload-input-details"
                          onChange={(e) => {
                            try {
                              if (e.target?.files?.length !== 0) {
                                setFieldValue("image", e.target.files[0]);
                              }
                            } catch (error) {
                              console.error(error);
                              e.target.value = null;
                            }
                          }}
                        />

                        <img
                          src={PagesIndex.Svg.editNew}
                          className="profile-edit-icon"
                          alt="
                            "
                        />
                      </Index.IconButton>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="profile-error-box">
                    {errors.image && (
                      <Index.FormHelperText error>
                        {errors.image}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Full Name
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter full name"
                                name="name"
                                value={values.name}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setFieldValue(name, value.trim());
                                  handleChange(e);
                                  setFieldTouched("name", true);
                                }}
                                inputProps={{ maxLength: 15 }}
                                onKeyDown={(e) => {
                                  if (e.key === " " && e.target.value.trim() === "") {
                                    e.preventDefault(); // Prevent space as the first character
                                  } else if (
                                    e.target.value.trim().split(" ").length >= 2 &&
                                    e.key === " "
                                  ) {
                                    e.preventDefault(); // Prevent adding additional spaces between words
                                  } else if (
                                    e.key === " " &&
                                    e.target.value.endsWith(" ")
                                  ) {
                                    e.preventDefault(); // Prevent additional spaces at the end
                                  }
                                  if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                    e.preventDefault(); // Prevent non-numeric characters
                                  }
                                }}
                                onBlur={handleBlur}
                                error={errors.name && touched.name ? true : false}
                                helperText={
                                  errors.name && touched.name ? errors.name : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Email Address
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled
                                error={
                                  errors.email && touched.email ? true : false
                                }
                                helperText={
                                  errors.email && touched.email
                                    ? errors.email
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Phone Number
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                // type="tel"
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter phone number"
                                name="mobileNumber"
                                value={values?.mobileNumber}
                                onChange={(e) => {
                                  handlePhoneChange(e, setFieldValue);
                                }}
                                inputProps={{ maxLength: 10 }}
                                error={
                                  errors.mobileNumber && touched.mobileNumber
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.mobileNumber && touched.mobileNumber
                                    ? errors.mobileNumber
                                    : false
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="modal-user-btn-flex">
                            <Index.Box className="save-btn-main border-btn-main">
                              <Index.PrimaryButton
                                btnLabel="Update"
                                type="submit"
                                className="btn-primary bg-approve"
                                disabled={isLoading}
                              >
                                {isLoading ? <Loader /> : "Click me"}
                              </Index.PrimaryButton>
                            </Index.Box>
                            <Index.Box className="save-btn-main">
                              <Index.PrimaryButton
                                btnLabel="Cancel"
                                className="btn-primary bg-suspend"
                                onClick={() => navigate("/admin/dashboard")}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="change-pass-text-main" justifyContent={"center"}>
                            <Index.Link
                              className="change-pass-link"
                              onClick={addnewhandleOpen}
                            >
                              Want to change password ?
                            </Index.Link>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>

            </Index.Box>
          </form>
        )}
      </PagesIndex.Formik>

      {/* Add New User modal */}

      <AdminPasswordChangeModal
        addnewopen={addnewopen}
        addnewhandleClose={addnewhandleClose}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const ExcelExportHelper = ({ data, sheetName, setExportData }) => {
  useEffect(() => {
    const handleExport = async () => {
      const headers = Object.keys(data[0]);
      // console.log("9",headers)

      let tableData = [headers];
      if (data && data.length > 0) {
        data.forEach((item) => {
          const rowData = headers.map((header) => item[header]);
          tableData.push(rowData);
        });
      }

      const wb = XLSX.utils.book_new();
      const sheet = XLSX.utils.aoa_to_sheet(tableData);
      XLSX.utils.book_append_sheet(wb, sheet, `${sheetName}`);

      const workbookBlob = workbook2blob(wb);
      const url = await addStyle(workbookBlob);

      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", `${sheetName}.xlsx`);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
      setExportData([]);
    };

    if (data.length > 0) {
      handleExport();
    }
  }, [data, sheetName, setExportData]);

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }
    return buf;
  }

  function workbook2blob(workbook) {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };
    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });
    return blob;
  }

  function numberToAlpha(num) {
    let str = "";
    while (num > 0) {
      let remainder = (num - 1) % 26;
      str = String.fromCharCode(65 + remainder) + str;
      num = Math.floor((num - 1) / 26);
    }
    return str;
  }

  function addStyle(workbookBlob) {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        const headers = Object.keys(data[0]);
        const headerRange = sheet.range(
          "A1:" + numberToAlpha(headers.length) + "1"
        );
        headerRange.style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          bold: true,
          fill: "808080",
          fontColor: "ffffff",
        });

        headers.forEach((header, index) => {
          const headerCell = headerRange.cell(1, index + 1);
          const headerText = headerCell.value();
          const headerCellWidth =
            headerText && headerText.toString().length
              ? headerText.toString().length * 1.2
              : 10;
          sheet.column(index + 1).width(headerCellWidth);
        });

        data.forEach((rowData, rowIndex) => {
          headers.forEach((header, index) => {
            const columnIndex = index + 1;
            const cellValue = rowData[header];
            if (cellValue !== undefined && cellValue !== null) {
              const cellWidth = cellValue.toString().length * 1.2;
              const columnWidth = sheet.column(columnIndex).width();
              sheet.column(columnIndex).width(Math.max(cellWidth, columnWidth));
            }
          });
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  }

  return null;
};

export default ExcelExportHelper;


export function convertHoursToHHMM(timeInHours) {
    // Split the time into hours and minutes
    let hours = Math.floor(timeInHours);
    let minutes = (timeInHours - hours) * 60;
    
    // Format the minutes to two digits
    minutes = Math.round(minutes); // Round to the nearest minute
    if (minutes === 60) {
        hours++; // Handle case where rounding minutes results in 60
        minutes = 0;
    }
    
    // Return formatted time as hh:mm
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import AddNewOfficer from "./officerModal/AddNewOfficer";
import { SwipeableDrawer } from "@mui/material";
import { getAgencyOfficerList } from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";
import AgencyOfficerSuspendModal from "./officerModal/AgencyOfficerSuspendModal";
import OfficerDeleteModal from "./officerModal/OfficerDeleteModal";
import Loader from "../../../../common/loader/Loader";
import ExcelExportHelper from "../../../../common/ExcelExportHelper/ExcelExportHelper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OfficerManagement() {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  // const { state } = PagesIndex.useLocation();
  // role permission
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;
  // end permission

  const [tabValue, setTabValue] = React.useState(
    location?.state ? location?.state : "PENDING"
  );

  const [searchValue, setSearchValue] = useState("");
  const handleTabsChange = (event, newValue) => {
    setSearchValue("");
    setFilterList(agencyofficerList);
    setTabValue(newValue);
    getAgencyOfficerListData(newValue);
  };
  const [loading, setLoading] = useState(false);
  const [agencyofficerList, setAgencyOfficerList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [officerData, setOfficerData] = useState({});
  // Delete new
  const [openDeleteNew, setOpenDeleteNew] = useState(false);
  const handleOpenDeleteNew = () => setOpenDeleteNew(true);
  const handleCloseDeleteNew = () => setOpenDeleteNew(false);
  const [deleteOfficerId, setDeleteOfficerId] = useState([]);

  // Suspend & Active modal
  const [suspendopen, setSuspendOpen] = useState(false);
  const suspendhandleOpen = (data) => {
    setOfficerData(data);
    setSuspendOpen(true);
  };
  const suspendhandleClose = () => {
    setOfficerData({});
    setSuspendOpen(false);
  };

  // Pending Job request modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  // get Agency officer list
  const getAgencyOfficerListData = (type = "PENDING") => {
    console.log("106", type)
    setLoading(true);
    dispatch(getAgencyOfficerList(type)).then((res) => {
      if (res?.payload?.status == 200) {
        setAgencyOfficerList(res?.payload?.data);
        setFilterList(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };





  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filteredList = agencyofficerList?.filter((data) =>
      `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()} ${data.mobileNumber
        } ${data.email.toLowerCase()}`.includes(e.target.value.toLowerCase())
    );
    setFilterList(filteredList);
  };
  // console.log("filteredList", filterList);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  useEffect(() => {
    if (location.state) {
      if (location?.state?.tabValueName !== undefined) {
        setTabValue(location?.state?.tabValueName);
        getAgencyOfficerListData(location?.state?.tabValueName || location.state);
      } else {
        getAgencyOfficerListData(location?.state?.tabValue || location.state);
      }
    } else {
      setTabValue("PENDING");
    }
  }, [location]);

  useEffect(() => {
    if (!location?.state) {
      getAgencyOfficerListData("PENDING");
    }
  }, []);


  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          Officer Management
        </Index.Typography>

        {/* Search */}
        <Index.Box className="userlist-btn-flex">



          <Index.Box className="user-search-main">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearch}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.value.trim() === "") {
                      e.preventDefault();
                    }
                  }}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="userlist-inner-btn-flex">
            {/* <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Index.Box className="export-btn-main border-btn-main">
                    <Index.Button
                      className="export-btn border-btn"
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={PagesIndex.Svg.filter}
                        className="down-icon"
                        alt="download icon"
                      />
                      Filter
                    </Index.Button>
                  </Index.Box>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        User Filter
                      </Index.Typography>
                      <img
                        src={PagesIndex.Svg.closeicon}
                        className="filter-close-icon"
                        onClick={toggleDrawer(anchor, false)}
                        alt=""
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.Box className="checkbox-main filter-checkbox-main input-box">
                          <Index.FormControlLabel
                            control={<Index.Checkbox defaultChecked />}
                            label="Active"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Deactivate"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Pending"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button
                          className="border-btn filter-cancel-btn"
                          onClick={toggleDrawer(anchor, false)}
                        >
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box> */}
            <Index.Box className="adduser-btn-main btn-main-primary">
              {roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.Officer?.permission?.add === true ? (
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="down-icon"
                    alt="download icon"
                  />{" "}
                  Add Officer
                </Index.Button>
              ) : null}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {loading ? (
        <Loader />
      ) : (
        <Index.Box className="officer-managment">
          <Index.Box className="cus-tabs-main-box">
            <Index.Box sx={{ width: "100%" }}>
              <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Index.Tabs
                  value={tabValue}
                  onChange={handleTabsChange}
                  aria-label="basic tabs example"
                  className="cus-tabs-main"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Index.Tab
                    label="Pending Profiles Request"
                    value="PENDING"
                    className="cus-tab"
                  />
                  <Index.Tab
                    label="Approved Profiles"
                    value="ACCEPTED"
                    className="cus-tab"
                  />{" "}
                  <Index.Tab
                    label="Rejected Profiles"
                    value="REJECTED"
                    className="cus-tab"
                  />
                  <Index.Tab
                    label="Added By Self"
                    value="AddedByAdmin"
                    className="cus-tab"
                  />
                </Index.Tabs>
              </Index.Box>
              <TabPanel
                value={tabValue}
                index="PENDING"
                className="cus-tabpanel"
              >
                <Index.Box className="tabpanel-main tabpanel-1">
                  {filterList?.length > 0 ? (
                    filterList.map((item) => (
                      <Index.Box className="card-main">
                        <Index.Box className="card-left">
                          <Index.Box className="officer-profile-flex">
                            <img
                              src={
                                item?.image
                                  ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                                  : PagesIndex.Png.usericon
                              }
                              className="prof-img"
                              alt=""
                            />
                            <Index.Box className="officer-detail">
                              <Index.Box className="officer-detail-title">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="job-title-label"
                                >
                                  {item?.firstName + " " + item?.lastName}
                                  {item?.jobName &&
                                    (item.jobName === "Supervisor" ||
                                      item.jobName === "Non-Supervisor") ? (
                                    <Index.Box className="admin-list-pd-btn-main">
                                      <Index.PrimaryButton
                                        btnLabel={item.jobName}
                                        className="admin-list-pd-btn"
                                      />
                                    </Index.Box>
                                  ) : null}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-email"
                              >
                                {item?.email}
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-numebr"
                              >
                                {item?.mobileNumber}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right">
                          {roleName === "Agency" ||
                            roleName === "Department" ||
                            AgencyPermission?.Officer?.permission?.read ===
                            true ? (
                            <Index.PrimaryButton
                              btnLabel="View"
                              className="btn-primary"
                              onClick={() => {
                                navigate(
                                  `/${roleName}/officer-management-detail/${item?._id}`,
                                  {
                                    state: {
                                      officerDetails: item,
                                      tabValue: "PENDING",
                                    },
                                  }
                                );
                              }}
                            />
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </Index.Box>
              </TabPanel>

              <TabPanel
                value={tabValue}
                index="ACCEPTED"
                className="cus-tabpanel"
              >
                <Index.Box className="tabpanel-main tabpanel-2">
                  {filterList?.length > 0 ? (
                    filterList?.map((item) => (
                      <Index.Box className="card-main">
                        <Index.Box className="card-left">
                          <Index.Box className="officer-profile-flex">
                            <img
                              src={
                                item?.image
                                  ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                                  : PagesIndex.Png.usericon
                              }
                              className="prof-img"
                              alt=""
                            />
                            <Index.Box className="officer-detail">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-title"
                              >
                                {item?.firstName + " " + item?.lastName}
                                {item?.jobName == "Supervisor" ? (
                                  <Index.Box className="admin-list-pd-btn-main">
                                    <Index.PrimaryButton
                                      btnLabel="Supervisor "
                                      className="admin-list-pd-btn"
                                    />
                                  </Index.Box>
                                ) : (
                                  <Index.Box className="admin-list-pd-btn-main">
                                    <Index.PrimaryButton
                                      btnLabel="Non-Supervisor"
                                      className="admin-list-pd-btn"
                                    />
                                  </Index.Box>
                                )}
                              </Index.Typography>

                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-email"
                              >
                                {item?.email}
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className=" officer-detail-numebr"
                              >
                                {item?.mobileNumber}
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="suspend_label"
                              >
                                {item.status === "SUSPEND" && "Suspended"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right btn-main-secondary btn-main-red btn-main-orange btn-main-yellow btn-main-green">
                          {roleName === "Agency" ||
                            roleName === "Department" ||
                            AgencyPermission?.Officer?.permission?.read ===
                            true ? (
                            <Index.Tooltip
                              title="View"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <Index.Button
                                btnLabel="View"
                                className="btn-orange"
                                onClick={() => {
                                  navigate(
                                    `/${roleName}/officer-management-detail/${item?._id}`,
                                    {
                                      state: {
                                        officerDetails: item,
                                        tabValue: "ACCEPTED",
                                      },
                                    }
                                  );
                                }}
                              >
                                <img
                                  src={PagesIndex.Svg.eyeIcon}
                                  alt="delete"
                                  className="admin-icons"
                                />
                              </Index.Button>
                            </Index.Tooltip>
                          ) : null}

                          {roleName === "Agency" ||
                            roleName === "Department" ||
                            AgencyPermission?.Officer?.permission?.Suspend ===
                            true ? (
                            <Index.Tooltip
                              title={`${item.status == "ACTIVATE"
                                ? "Suspend"
                                : "Activate"
                                }`}
                              arrow
                              placement="bottom"
                              className={`admin-tooltip ${item.status == "SUSPEND"
                                ? "text-danger"
                                : "text-dark"
                                }`}
                            >
                              <Index.Button
                                btnLabel={
                                  item.status === "ACTIVATE"
                                    ? "Suspend"
                                    : "Activate"
                                }
                                onClick={() => suspendhandleOpen(item)}
                                className={`${item.status === "ACTIVATE"
                                  ? "btn-red bg-suspend"
                                  : "btn-green bg-active"
                                  } btn-red`}
                              >
                                {item.status == "ACTIVATE" ? (
                                  <img
                                    src={PagesIndex.Svg.prohibitionIcon}
                                    alt="delete"
                                    className="admin-icons"
                                  />
                                ) : (
                                  <img
                                    src={PagesIndex.Svg.checkMark}
                                    alt="CheckMark"
                                    className="admin-icons checkmark-icon"
                                  />
                                )}
                              </Index.Button>
                            </Index.Tooltip>
                          ) : null}

                          {roleName === "Agency" ||
                            roleName === "Department" ||
                            AgencyPermission?.Officer?.permission?.edit ===
                            true ? (
                            <Index.Tooltip
                              title="Edit"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <Index.Button
                                btnLabel="Edit"
                                className="btn-secondary"
                                onClick={() => {
                                  if (item?.isBuild) {
                                    navigate(
                                      `/${roleName}/edit-officer-detail/${item?._id}`,
                                      {
                                        state: {
                                          officerDetails: item,
                                          tabValue: "ACCEPTED",
                                        },
                                      }
                                    );
                                  } else {
                                    PagesIndex.toast.info("Build is remaining");
                                  }
                                }}
                              >
                                <img
                                  src={PagesIndex.Svg.editNew}
                                  alt="delete"
                                  className="admin-icons"
                                />
                              </Index.Button>
                            </Index.Tooltip>
                          ) : null}

                          {roleName === "Agency" ||
                            roleName === "Department" ||
                            AgencyPermission?.Officer?.permission?.delete ===
                            true ? (
                            <Index.Tooltip
                              title="Delete"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <Index.Button
                                btnLabel="delete"
                                className=" btn-red"
                                onClick={() => {
                                  setDeleteOfficerId(item);
                                  handleOpenDeleteNew();
                                }}
                              >
                                <img
                                  src={PagesIndex.Svg.deletewhite}
                                  alt="delete"
                                  className="admin-icons"
                                />
                              </Index.Button>
                            </Index.Tooltip>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </Index.Box>
              </TabPanel>

              <TabPanel
                value={tabValue}
                index="REJECTED"
                className="cus-tabpanel"
              >
                <Index.Box className="tabpanel-main tablepanel-3">
                  {filterList?.length > 0 ? (
                    filterList.map((item) => (
                      <Index.Box className="card-main">
                        <Index.Box className="card-left">
                          <Index.Box className="officer-profile-flex">
                            <img
                              src={
                                item?.image
                                  ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                                  : PagesIndex.Png.usericon
                              }
                              className="prof-img"
                              alt=""
                            />
                            <Index.Box className="officer-detail">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-title"
                              >
                                {item?.firstName + " " + item?.lastName}
                                {item?.jobName == "Supervisor" ? (
                                  <Index.Box className="admin-list-pd-btn-main">
                                    <Index.PrimaryButton
                                      btnLabel="Supervisor "
                                      className="admin-list-pd-btn"
                                    />
                                  </Index.Box>
                                ) : (
                                  <Index.Box className="admin-list-pd-btn-main">
                                    <Index.PrimaryButton
                                      btnLabel="Non-Supervisor"
                                      className="admin-list-pd-btn"
                                    />
                                  </Index.Box>
                                )}
                              </Index.Typography>

                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-email"
                              >
                                {item?.email}
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-numebr"
                              >
                                {item?.mobileNumber}
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="suspend_label"
                              >
                                {item.status === "SUSPEND" && "Suspended"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right">
                          <Index.Box className="right-btn-group">
                            {roleName === "Agency" ||
                              roleName === "Department" ||
                              AgencyPermission?.Officer?.permission?.read ===
                              true ? (
                              <Index.PrimaryButton
                                btnLabel="View"
                                className="btn-primary"
                                onClick={() => {
                                  navigate(
                                    `/${roleName}/officer-management-detail/${item?._id}`,
                                    {
                                      state: {
                                        officerDetails: item,
                                        tabValue: "REJECTED",
                                      },
                                    }
                                  );
                                }}
                              />
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </Index.Box>
              </TabPanel>

              <TabPanel
                value={tabValue}
                index="AddedByAdmin"
                className="cus-tabpanel"
              >
                <Index.Box className="tabpanel-main tablepanel-4">
                  {filterList?.length > 0 ? (
                    filterList?.map((item, index) => (
                      <Index.Box className="card-main">
                        <Index.Box className="card-left">
                          <Index.Box className="officer-profile-flex">
                            <img
                              src={
                                item?.image
                                  ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                                  : PagesIndex.Png.usericon
                              }
                              alt=""
                              className="prof-img"
                            />

                            <Index.Box className="officer-detail">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-title"
                              >
                                {item?.firstName + " " + item?.lastName}
                              </Index.Typography>

                              {item?.jobName &&
                                (item.jobName === "Supervisor" ||
                                  item.jobName === "Non-Supervisor") ? (
                                <Index.Box className="admin-list-pd-btn-main">
                                  <Index.PrimaryButton
                                    btnLabel={item.jobName}
                                    className="admin-list-pd-btn"
                                  />
                                </Index.Box>
                              ) : null}

                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-email"
                              >
                                {item?.email}
                              </Index.Typography>

                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-numebr"
                              >
                                {item?.mobileNumber}
                              </Index.Typography>

                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-numebr"
                              >
                                {item?.createdByAgency
                                  ? "Added by self"
                                  : "Added by protekt main admin"}
                              </Index.Typography>

                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-numebr"
                              >
                                {item?.status === "SUSPEND" && "Suspended"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                 {       console.log("853", item.officerStatus == "PENDING")}

                        {
                          item.officerStatus == "PENDING" && (
                            <Index.Box className="card-right btn-main-secondary btn-main-red btn-main-orange btn-main-yellow">
                              {roleName === "Agency" ||
                                roleName === "Department" ||
                                AgencyPermission?.Officer?.permission?.delete ===
                                true ? (
                                <Index.Tooltip
                                  title="Delete"
                                  arrow
                                  placement="bottom"
                                  className="admin-tooltip"
                                >
                                  <Index.Button
                                    btnLabel="delete"
                                    className=" btn-red"
                                    onClick={() => {
                                      setDeleteOfficerId(item);
                                      handleOpenDeleteNew();
                                    }}
                                  >
                                    <img
                                      src={PagesIndex.Svg.deletewhite}
                                      alt="delete"
                                      className="admin-icons"
                                    />
                                  </Index.Button>
                                </Index.Tooltip>
                              ) : null}
                            </Index.Box>
                          )
                        }


                      </Index.Box>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </Index.Box>
              </TabPanel>
            </Index.Box>
          </Index.Box>

          {/* Add New User modal */}
          <AddNewOfficer handleClose={handleClose} open={open} />

          {/* Suspend Officer modal */}
          <AgencyOfficerSuspendModal
            suspendopen={suspendopen}
            suspendhandleClose={suspendhandleClose}
            getAgencyOfficerListData={getAgencyOfficerListData}
            officerData={officerData}
          />

          {/* Delete Officer Modal */}
          <OfficerDeleteModal
            handleCloseDeleteNew={handleCloseDeleteNew}
            openDeleteNew={openDeleteNew}
            getAgencyOfficerListData={getAgencyOfficerListData}
            tabName = {tabValue}
            deleteOfficerId={deleteOfficerId}
          />
        </Index.Box>
      )}
    </>
  );
}

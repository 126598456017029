import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

const AgencyJobManagementDetailActiveJobs = () => {
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const activejobsDetail = state;
  // Agency name
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const AgencyName = agencyLoginData?.agencyName;
  const roleName = agencyLoginData?.roleId?.Permission_name;
  
  return (
    <>

      <Index.Box className="user-list-flex">
        <Index.Box className="admin-page-title user-list-page-title">
          <Index.Box
            onClick={() =>
              navigate(`/${roleName}/agency-job-management`, {
                state: "ActiveJobs",
              })
            }
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            />
            {activejobsDetail?.title}

          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="admin-sub-flex">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Assigned Officer & Associated Agency/Department
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <>
        {activejobsDetail?.officerId.map((item, index) => {
          const officershortDetail = item?.officerId;
          return (
            <Index.Box className="card-main">
              <Index.Box className="card-left">
                <Index.Box className="officer-profile-flex">
                  <img
                    src={PagesIndex.Png.usericon}
                    className="prof-img"
                    alt=""
                  />

                  <Index.Box className="officer-detail">
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        {officershortDetail?.firstName ? (
                          <>
                            {officershortDetail?.firstName +
                              " " +
                              officershortDetail?.lastName}
                          </>
                        ) : null}
                        &nbsp; &nbsp;
                      </Index.Typography>

                      {officershortDetail?.officerType ==
                        "Active Police Officer" ? null : (
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          ({officershortDetail?.officerType})
                        </Index.Typography>
                      )}
                    </Index.Box>
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Officer Assigned on :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {PagesIndex.dayjs(item?.jobAcceptedDate).format(
                          "MM-DD-YYYY"
                        )}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Officer Check-In :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {item?.jobCheckInTime &&
                          PagesIndex.dayjs(item.jobCheckInTime).isValid()
                          ? PagesIndex.dayjs(
                            item.jobCheckInTime
                          ).format("MM-DD-YYYY - HH:mm")
                          : " Not checkin"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Officer Check-Out :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {item?.jobCheckOutTime &&
                          PagesIndex.dayjs(item.jobCheckOutTime).isValid()
                          ? PagesIndex.dayjs(
                            item.jobCheckOutTime
                          ).format("MM-DD-YYYY - HH:mm")
                          : " Not checkout"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          );
        })}
      </>
      <Index.Box className="admin-sub-flex">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            General Information
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="card-main">
        <Index.Box className="card-left">
          <Index.Box className="officer-detail">
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Title :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.title}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Posted By :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.userId?.name}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Posted On :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.dayjs(activejobsDetail?.createdAt).format(
                  "MM-DD-YYYY"
                )}
              </Index.Typography>
            </Index.Box>

            {
              activejobsDetail?.totalAmount !== "" ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Payment :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {activejobsDetail?.totalAmount}
                  </Index.Typography>
                </Index.Box>
              ) : null
            }

            {activejobsDetail?.paymentIntentId !== "" ? (

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Transaction Id  :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {activejobsDetail?.paymentIntentId}
                </Index.Typography>
              </Index.Box>
            ) : null
            }


            {/* <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Total Payment :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                -
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Transaction Id :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                -
              </Index.Typography>
            </Index.Box> */}
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Phone Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.mobileNumber}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Email Address :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.userId?.email}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Point Of Contact Name :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.pointOfContactName}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Point Of Contact Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {
                  activejobsDetail?.pointOfContactNumber || "-"
                }
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Location :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.location}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Service :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.service}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Department :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.department}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Type :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.jobType?.jobType}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Zip Code :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.zipCode}
              </Index.Typography>
            </Index.Box>
            {activejobsDetail?.supervisor ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Supervisor :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {activejobsDetail?.supervisor}
                </Index.Typography>
              </Index.Box>
            ) : null}

            {activejobsDetail?.nonSupervisor ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Non Supervisor :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {activejobsDetail?.nonSupervisor}
                </Index.Typography>
              </Index.Box>
            ) : null}

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Date/Time :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.showDateTime(activejobsDetail)}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Total Hours :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
               {PagesIndex.timeDifferenceCalculation(activejobsDetail)}{" "}
                hour
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Pay Rate :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.rate ? "$" : null}
                {activejobsDetail?.rate}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                No of Officer :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.totalOfficer}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Information :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.information}
              </Index.Typography>
            </Index.Box>
            <Index.Box
              className="work-title-flex"
            >
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Description :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.description}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>


      <Index.Box className="admin-sub-flex">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Organizer (Client)
          </Index.Typography>
        </Index.Box>
      </Index.Box>

      <Index.Box className="card-main">
        <Index.Box className="card-left">
          <Index.Box className="officer-detail">
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Name :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.userId?.name}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Mobile Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.userId?.mobileNumber}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Email Address :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {activejobsDetail?.userId?.email}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>


      <Index.Box className="admin-sub-title-main">
        <Index.Typography
          component="p"
          variant="p"
          className="admin-sub-title"
        >
          Job Log
        </Index.Typography>
      </Index.Box>

      <Index.Box className="card-main">
        <Index.Box className="card-left">
          <Index.Box className="officer-detail">
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Posted On :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.dayjs(activejobsDetail?.createdAt).format(
                  "MM-DD-YYYY"
                )}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Transferred To :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                -
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Transferred On :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                -
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box >
    </>
  );
};

export default AgencyJobManagementDetailActiveJobs;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { AgencyChangePasswordSchema } from "../../../../validation/FormikSchema";
import { agencyLogout } from "../../../../redux-toolkit/slice/agency-slice/AgencySlice";

const AgencyChangePasswordPage = () => {
    const navigate = useNavigate();
    const dispatch = PagesIndex.useDispatch();
    const location = PagesIndex.useLocation();
    const agencyLoginData = PagesIndex.useSelector((state) => state?.AgencySlice?.agencyLoginData);

    // State for password visibility toggling
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);

    // Handlers for toggling password visibility
    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickNewShowPassword = () => setShowNewPassword((show) => !show);
    const handleClickConfirmNewShowPassword = () => setShowConfirmNewPassword((show) => !show);

    // Prevent default behavior for mouse down events on password fields
    const handleMouseDownOldPassword = (event) => event.preventDefault();
    const handleMouseDownNewPassword = (event) => event.preventDefault();
    const handleMouseDownConfirmNewPassword = (event) => event.preventDefault();

    // Initial values for the Formik form
    const initialValues = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    };

    // console.log("email",location)

    // useEffect((location)=>{
    //     console.log("email location",location?.state?.email)
    //     if(!agencyLoginData)
    //     {
    //          navigate('/')
    //     }

    // },[location])

    // console.log("location",location)

    // Form submit handler
    const handleAgencyChangePasswordForm = async (values) => {
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("oldPassword", values?.oldPassword);
        urlEncoded.append("newPassword", values?.newPassword);
        urlEncoded.append("email", location?.state?.email);

        try {
            const response = await DataService.post(Api.Agency.AGENCY_CHANGE_PASSWORD_NOT_AUTH, urlEncoded);
            if (response?.data?.status === 200) {
                PagesIndex.toast.success(response?.data?.message);
                navigate("/");
                dispatch(agencyLogout());
                localStorage.removeItem(agencyLoginData?._id);
            }
        } catch (error) {
            PagesIndex.toast.error(error?.response?.data?.message);
        }
    };

    return (
        <>
            <Index.Box className="admin-login-main-flex">
                <Index.Box className="admin-login-left-main position-relative">
                    {/* <PagesIndex.AuthBackground /> */}
                    <Index.Box className="login-left-bg "></Index.Box>
                    <Index.Box className="login-overlay"></Index.Box>

                </Index.Box>
                <Index.Box className="admin-login-right-main">
                    <Index.Box className="admin-login-box">
                        <Index.Box className="admin-login-main">
                            <PagesIndex.Formik
                                validationSchema={AgencyChangePasswordSchema}
                                initialValues={initialValues}
                                onSubmit={handleAgencyChangePasswordForm}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    touched,
                                    handleSubmit,
                                    setFieldValue,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        {console.log("error", errors)}

                                        <Index.Box className="admin-login-box">
                                            <Index.Box className="admin-login-main">
                                                <Index.Box className="admin-login-inner">
                                                    <Index.Typography
                                                        component="h2"
                                                        variant="h2"
                                                        className="admin-wel-text"
                                                    >
                                                        Change Password
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="admin-sign-para common-para"
                                                    >
                                                        Please reset your password !
                                                    </Index.Typography>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>

                                        <Index.Box className="modal-body" sx={{ padding: "0" }}>
                                            <Index.Box className="input-box modal-input-box ">
                                                <Index.FormHelperText className="form-lable">
                                                    Enter Old Password
                                                </Index.FormHelperText>
                                                <Index.Box className="form-group pwd-icon-btn">
                                                    <Index.OutlinedInput
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control-eye"
                                                        name="oldPassword"
                                                        placeholder="Please enter old password"
                                                        onBlur={handleBlur}
                                                        value={values.oldPassword}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.replace(/\s/g, "");
                                                            setFieldValue("oldPassword", newValue);
                                                        }}
                                                        inputProps={{ maxLength: 16 }}
                                                        helperText={touched.oldPassword && errors.oldPassword}
                                                        error={Boolean(
                                                            errors.oldPassword && touched.oldPassword
                                                        )}
                                                        type={showOldPassword ? "text" : "password"}
                                                        endAdornment={
                                                            <Index.InputAdornment position="end">
                                                                <Index.IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowOldPassword}
                                                                    onMouseDown={handleMouseDownOldPassword}
                                                                    edge="end"
                                                                >
                                                                    {!showOldPassword ? (
                                                                        <Index.VisibilityOff />
                                                                    ) : (
                                                                        <Index.Visibility />
                                                                    )}
                                                                </Index.IconButton>
                                                            </Index.InputAdornment>
                                                        }
                                                    />
                                                </Index.Box>
                                                {touched.oldPassword && errors.oldPassword && (
                                                    <Index.FormHelperText error>
                                                        {errors.oldPassword}
                                                    </Index.FormHelperText>
                                                )}
                                            </Index.Box>
                                            <Index.Box className="input-box modal-input-box modal-password-border">
                                                <Index.FormHelperText className="form-lable">
                                                    Enter New Password
                                                </Index.FormHelperText>
                                                <Index.Box className="form-group pwd-icon-btn">
                                                    <Index.OutlinedInput
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control-eye"
                                                        placeholder="Please enter new password"
                                                        name="newPassword"
                                                        onBlur={handleBlur}
                                                        value={values.newPassword}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.replace(/\s/g, "");
                                                            setFieldValue("newPassword", newValue);
                                                        }}
                                                        inputProps={{ maxLength: 16 }}
                                                        helperText={touched.newPassword && errors.newPassword}
                                                        error={Boolean(errors.newPassword && touched.newPassword)}
                                                        type={showNewPassword ? "text" : "password"}
                                                        endAdornment={
                                                            <Index.InputAdornment position="end">
                                                                <Index.IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickNewShowPassword}
                                                                    onMouseDown={handleMouseDownNewPassword}
                                                                    edge="end"
                                                                >
                                                                    {!showNewPassword ? (
                                                                        <Index.VisibilityOff />
                                                                    ) : (
                                                                        <Index.Visibility />
                                                                    )}
                                                                </Index.IconButton>
                                                            </Index.InputAdornment>
                                                        }
                                                    />
                                                </Index.Box>
                                                {touched.newPassword && errors.newPassword && (
                                                    <Index.FormHelperText error>
                                                        {errors.newPassword}
                                                    </Index.FormHelperText>
                                                )}
                                            </Index.Box>
                                            <Index.Box className="input-box modal-input-box modal-password-border">
                                                <Index.FormHelperText className="form-lable">
                                                    Re-enter New Password
                                                </Index.FormHelperText>
                                                <Index.Box className="form-group pwd-icon-btn">
                                                    <Index.OutlinedInput
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control-eye"
                                                        placeholder="Please re-enter new password"
                                                        name="confirmPassword"
                                                        onBlur={handleBlur}
                                                        value={values.confirmPassword}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.replace(/\s/g, "");
                                                            setFieldValue("confirmNewPassword", newValue);
                                                        }}
                                                        inputProps={{ maxLength: 16 }}
                                                        helperText={
                                                            touched.confirmPassword && errors.confirmPassword
                                                        }
                                                        error={Boolean(
                                                            errors.confirmNewPassword && touched.confirmNewPassword
                                                        )}
                                                        type={showConfirmNewPassword ? "text" : "password"}
                                                        endAdornment={
                                                            <Index.InputAdornment position="end">
                                                                <Index.IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickConfirmNewShowPassword}
                                                                    onMouseDown={handleMouseDownConfirmNewPassword}
                                                                    edge="end"
                                                                >
                                                                    {!showConfirmNewPassword ? (
                                                                        <Index.VisibilityOff />
                                                                    ) : (
                                                                        <Index.Visibility />
                                                                    )}
                                                                </Index.IconButton>
                                                            </Index.InputAdornment>
                                                        }
                                                    />
                                                </Index.Box>
                                                {touched.confirmNewPassword && errors.confirmNewPassword && (
                                                    <Index.FormHelperText error>
                                                        {errors.confirmNewPassword}
                                                    </Index.FormHelperText>
                                                )}
                                            </Index.Box>
                                        </Index.Box>

                                        <Index.Box className="btn-main-primary login-btn-main">
                                            <Index.PrimaryButton
                                                className="btn-primary login-btn"
                                                btnLabel="Update"
                                                type="submit"
                                            />
                                        </Index.Box>
                                    </form>
                                )}
                            </PagesIndex.Formik>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    );
};

export default AgencyChangePasswordPage;

import React, { useState, useEffect } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import {
  AdminAddUserSchema,
  AdminEditUserSchema,
  AgencyEditUserSchema,
} from "../../../../../validation/FormikSchema";
import { getRollList } from "../../../../../redux-toolkit/slice/common-slice/CommonServices";
import { getRoleMasterList } from "../../../../../redux-toolkit/slice/admin-slice/AdminServices";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../../../../common/loader/Loader";
import { getAgencyRoleList, getAgencyRoleMasterList } from "../../../../../redux-toolkit/slice/agency-slice/AgencyServices";
// AdminSlice: AdminSliceReducer,


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const officerType = [
  { title: "Active Police Officer" },
  { title: "Retired Police Officer" },
  { title: "Retired Military Officer" },
  { title: ' Security Officer' }
];
const AddNewUser = (props) => {
  const { state } = useLocation();
  let editData = state?.item;
  const dispatch = PagesIndex.useDispatch();
  const { open, handleClose, getUserListData, editUserData } = props;
  // const [userRoleId, setUserRoleId] = useState();

  const [isLoading, setIsLoading] = useState(false);

  // validation regex
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  //role
  const [roleMasterList, setRoleMasterList] = useState();
  // const { roleMasters } = useSelector((state) => state.AdminSlice);
  console.log(roleMasterList, "roleMasterList")

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const initialValues = {
    name: editUserData ? editUserData?.name : "",
    email: editUserData ? editUserData?.email : "",
    // mobileNumber: editUserData && editUserData.mobileNumber == null ? "" : editUserData.mobileNumber,
    mobileNumber: editUserData ?  editUserData.mobileNumber : "",
    password: "",
    confirmPassword: "",
    // rollName: editUserData ? editUserData?.roleId?.Permission_name : "",
    // Permission_name : ""
    roleName: editUserData ? (editUserData?.roleId?._id ? editUserData?.roleId?._id : editUserData?.roleId) : "",
  };

  // Roll List  Api call
  // useEffect(() => {
  //   dispatch(getRoleMasterList()).then((res) => {
  //     if (res?.payload?.status == 200) {
  //       const rolematch = res?.payload?.data.filter((matchData) => {
  //         return matchData.role?.toLowerCase() === "user";
  //       });
  //       if (rolematch?.length > 0) {
  //         setRoleMasterList(rolematch?.[0]?._id);
  //       }
  //     }
  //   });
  // }, [dispatch]);

  const handleAddNewUserForm = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    if (editUserData === undefined || editUserData) {
      urlEncoded.append("id", editUserData?._id);
    }
    urlEncoded.append("name", values?.name);
    urlEncoded.append("email", values?.email.toLowerCase());
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    // urlEncoded.append("roleId", values?.role);
    urlEncoded.append("roleId", values?.roleName);
    if (editUserData.length === 0) {
      urlEncoded.append("password", values?.password);
      // urlEncoded.append("roleId", userRoleId);

    }
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_ADD_EDIT_USER,
        urlEncoded
      );
      if (response?.data?.status === 201) {
        PagesIndex.toast.success(response?.data?.message, { toastId: "customId" });
        handleClose();
        getUserListData();
        setIsLoading(false);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, { toastId: "customId" });
      setIsLoading(false);
    }
  };


  // role get
  const getRole = () => {
    dispatch(getAgencyRoleMasterList()).then((res) => {
      if (res?.payload?.status == 200) {
        setRoleMasterList(res?.payload?.data);
      }

    });
  };

  useEffect(() => {
    getRole();
  }, []);

  // phone number functionality

  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
   
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");
    
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }
    
    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
};

  useEffect(() => {
    return () => {
      setShowPassword(false);
      setShowConfirmPassword(false);
    };
  }, [handleClose]);

  return (
    <>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          validationSchema={
            editUserData.length === 0 ? AdminAddUserSchema : AgencyEditUserSchema
          }
          initialValues={initialValues}
          onSubmit={handleAddNewUserForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            handleFocus,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {console.log("error", errors)} */}
              {/* {console.log("values>>", values)} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {editUserData ? "Edit Sub Admin" : "Add New Sub Admin"}
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                    alt=""
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter name"
                        name="name"
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldTouched("name", true);
                        }}
                        inputProps={{ maxLength: 20 }}
                        // onBlur={handleBlur}
                        focused={false}
                        error={errors.name && touched.name ? true : false}
                        helperText={
                          errors.name && touched.name ? errors.name : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.value.trim() === "") {
                            e.preventDefault(); // Prevent space as the first character
                          } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                            e.preventDefault(); // Prevent adding additional spaces between words
                          } else if (e.key === " " && e.target.value.endsWith(" ")) {
                            e.preventDefault(); // Prevent additional spaces at the end
                          }
                          if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                            e.preventDefault(); // Prevent non-numeric characters
                          }
                        }}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Email Address
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter email address"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                          // setFieldTouched("email", true);
                        }}
                        disabled={editUserData ? true : false}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 60 }}
                        onBlur={handleBlur}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  {console.log("errors 305",errors)}
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Phone Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter phone number"
                        name="mobileNumber"
                        value={values?.mobileNumber}
                        focused={false}
                        onChange={(e) => { handlePhoneChange(e, setFieldValue) }}
                        inputProps={{ maxLength: 10 }}
                        error={
                           errors.mobileNumber && touched.mobileNumber
                            ? true
                            : false
                        }
                        helperText={
                          errors.mobileNumber && touched.mobileNumber
                            ? errors.mobileNumber
                            : false
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  {editUserData.length === 0 ? (
                    <>
                      <Index.Box className="input-box modal-input-box password-input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            fullWidth
                            id="fullWidth"
                            className="form-control-eye"
                            placeholder="Please enter password"
                            name="password"
                            onBlur={handleBlur}
                            value={values.password}
                            inputProps={{ maxLength: 16 }}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s/g,
                                ""
                              );
                              setFieldValue("password", newValue);
                              setFieldTouched("password", true);
                            }}
                            helperText={touched.password && errors.password}
                            error={Boolean(errors.password && touched.password)}
                            onKeyDown={(event) => {
                              if (event.key == " ") {
                                event.preventDefault();
                              }
                            }}
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        <Index.FormHelperText error className="error-text">
                          {errors.password && touched.password
                            ? errors.password
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="input-box modal-input-box password-input-box">
                        <Index.FormHelperText className="form-lable">
                          Confirm password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            fullWidth
                            id="fullWidth"
                            className="form-control-eye"
                            name="confirmPassword"
                            placeholder="Please enter confirm password"
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            helperText={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            error={Boolean(
                              errors.confirmPassword && touched.confirmPassword
                            )}
                            onKeyDown={(event) => {
                              if (event.key == " ") {
                                event.preventDefault();
                              }
                            }}
                            type={showConfirmPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickConfirmShowPassword}
                                  // onMouseDown={handleMouseDownConfirmPassword}
                                  edge="end"
                                >
                                  {!showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        <Index.FormHelperText error className="error-text">
                          {errors.confirmPassword && touched.confirmPassword
                            ? errors.confirmPassword
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>

                    </>
                  ) : null}
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Role
                    </Index.FormHelperText>
                    <Index.Box className="dropdown-box form-control drop-form-control">
                      <Index.Select
                        className="dropdown-select drop-select"
                        fullWidth
                        name="roleName"
                        value={values?.roleName}
                        renderValue={
                          values?.roleName
                            ? undefined
                            : () => "Select role"
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={handleChange}
                        error={
                          errors.roleName && touched.roleName ? true : false
                        }

                      >
                        {roleMasterList && roleMasterList.length > 0
                          ? roleMasterList.map((data, index) => {
                            return (
                              <Index.MenuItem
                                key={index}
                                value={data?._id}
                                className="drop-menuitem"
                              >
                                {/* {data?.Permission_name === "Department" || data?.Permission_name === "User" ? (   */}
                                <div>{data?.Permission_name}</div>
                                {/* ) : null} */}
                                {/* {data?.Permission_name} */}
                              </Index.MenuItem>
                            );
                          })
                          : null}
                      </Index.Select>
                    </Index.Box>
                    {touched.roleName && errors.roleName && (
                      <Index.FormHelperText error>
                        {errors.roleName}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel={editUserData ? "Update" : "Add"}
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>

                    <Index.PrimaryButton
                      btnLabel="cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => handleClose()}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AddNewUser;

import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const AdminUnservedJobModal = (props) => {
  const { unservedJobopen, handleCloseUnservedJob, unservedJobData } = props;
  
  return (
    <>
      <Index.Modal
        open={unservedJobopen}
        onClose={handleCloseUnservedJob}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Unfilled/Unserved jobs
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={handleCloseUnservedJob}
              alt=""
            />
          </Index.Box>
          <Index.Box className="modal-body">
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Title :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.title}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Posted By :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.userId?.name}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Posted On :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.dayjs(unservedJobData?.createdAt).format(
                  "MM-DD-YYYY"
                )}
              </Index.Typography>
            </Index.Box>

            {unservedJobData?.totalAmount !== "" ? (

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Payment :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                 {unservedJobData?.totalAmount}
                </Index.Typography>
              </Index.Box>

            ) : null}

            {unservedJobData?.paymentIntentId !== "" ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Transaction Id  :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedJobData?.paymentIntentId}
                </Index.Typography>
              </Index.Box>

            ) : null
            }

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Phone Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {/* {unservedJobData?.userId?.mobileNumber} */}
                {unservedJobData?.mobileNumber}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Email Address :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.userId?.email}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Location :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.location}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Service :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.service}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Department :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.department}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Type :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.jobType?.jobType}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Zip Code :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.zipCode}
              </Index.Typography>
            </Index.Box>
            {unservedJobData?.supervisor ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Supervisor :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedJobData?.supervisor}
                </Index.Typography>
              </Index.Box>) : (null)}
            {unservedJobData?.nonSupervisor ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Non Supervisor :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedJobData?.nonSupervisor}
                </Index.Typography>
              </Index.Box>
            ) : (null)}

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Date/Time :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {/* {jobList?.jobSchedule[0]?.jobStartTime +
                        " to " +
                        jobList?.jobSchedule[0]?.jobEndTime} */}
                {unservedJobData?.jobSchedule?.map((item, index) => {
                  return (
                    <>
                      {/* {item.isActive == true ? ( */}
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-flex"
                      >
                        <span className="day-title">
                          {
                            // (item?.jobStartDate)
                            PagesIndex.dayjs(item?.jobStartDate).format(
                              "MM-DD-YYYY"
                            ) +
                            " to " +
                            PagesIndex.dayjs(item?.jobEndDate).format(
                              "MM-DD-YYYY"
                            )
                          }
                        </span>{" "}
                        -{" "}
                        <span>
                          {item?.jobStartTime
                            ? item?.jobStartTime + " to "
                            : null}
                          {item?.jobEndTime}
                        </span>
                      </Index.Typography>
                      {/* ):null} */}
                    </>
                  );
                })}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Pay Rate (Per hour) :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.rate ? "$" : null}
                {unservedJobData?.rate}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Total Hours :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.timeDifferenceCalculation(unservedJobData)} hour
                {/* {PagesIndex.timeDifferenceCalculation(
                      unservedJobData?.jobSchedule[0]?.jobStartTime,
                      unservedJobData?.jobSchedule[0]?.jobEndTime
                    )} */}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                No of Officer :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.totalOfficer}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Information :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.information}
              </Index.Typography>
            </Index.Box>
            <Index.Box
              className="work-title-flex"
            >
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Description :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.description}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Approved By : &nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.approveBy?.agencyName}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Approved on : &nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {/* {unservedJobData?.approveBy?.name} */}
                {PagesIndex.moment(unservedJobData?.acceptedDate).format("MM-DD-YYYY")}
              </Index.Typography>
            </Index.Box>
            {/* <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Accepted By : &nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {unservedJobData?.officerId[0]?.officerId?.firstName ? (
                  <>
                    {unservedJobData?.officerId[0]?.officerId?.firstName +
                      " " +
                      unservedJobData?.officerId[0]?.officerId?.lastName}
                  </>
                ) : null}
              </Index.Typography>
            </Index.Box> */}
            {/* <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Accepted On :-&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.moment(unservedJobData?.officerId[0]?.jobAcceptedDate).format("MM-DD-YYYY")}
              </Index.Typography>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AdminUnservedJobModal;

import React, { useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import { format } from "date-fns";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const RatingAndReviewManagement = () => {
  const dispatch = PagesIndex.useDispatch();
  const [open, setOpen] = React.useState(false);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  // Pagination states and methods
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // End Pagination
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [reviewData, setReviewData] = React.useState("");
  // filter
  console.log(reviewData, "reviewData");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );

  function showjobIdDegits(str) {
    if (str.length <= 6) {
      return str;
    }
    const firstThree = str.slice(0, 3);
    const lastThree = str.slice(-3);
    return `${firstThree}...${lastThree}`;
  }

  const input = "66236f24bcd47a4c67df8533";
  console.log(showjobIdDegits(input));
  // Output: "662...533"


  const getAllList = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Agency.GET_REVIEW_LIST
      );
      setListData(res?.data?.data);
      console.log(res?.data?.data, "list");
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnableDisableData = async (id, isEnabled, event) => {
    const { checked } = event.target;
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    urlEncoded.append("isEnable", checked); // Toggle the enabled state

    try {
      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.Agency.REVIEW_ENABLE_DISABLE,
        urlEncoded
      );
      //  // Update the state for the specific item
      setListData((prevListData) =>
        prevListData.map((item) =>
          item._id === id ? { ...item, isEnable: checked } : item
        )
      );
      getAllList();
      console.log(res.data.data, "list");
    } catch (error) {
      console.error(error);
    }
  };

  PagesIndex.useEffect(() => {
    getAllList();
  }, []);

  const sendRatingReview = async (id, data, status) => {
    handleClose();
    try {
      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.Agency.RATING_REVIEW,
        {
          id: id,
          isApproved: data,
          status: status
        }
      );
      if (res?.data?.status == 200) {
        getAllList();
        PagesIndex.toast.success(res?.data?.message)
        console.log(res?.data?.data, "list");
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message)
      console.error(error);
    }
  };



  return (
    <>

      <Index.Box className="user-list-flex">
        <Index.Typography className='admin-page-title user-list-page-title' component='h2' variant='h2'>
          {/* <Index.Link to="/admin/dashboard" className="filter-back-link">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>{" "} */}
          Rating & Review Management
        </Index.Typography>
        <Index.Box className="userlist-btn-flex">
          <Index.Box className="user-search-main">
            <Index.Box className="user-search-box">

              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>

            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>


      <Index.Box className="common-card">
        <Index.Box className="page-table-main role-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table
              aria-label="simple table"
              className="table"
            >
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                  >
                    Sr. No
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                  >
                    Job Id
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                  >
                    {" "}
                    Job Title
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                  >
                    {" "}
                    Security officer Name
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                  >
                    Review details
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                  >
                    {" "}
                    Review stars
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                  >
                    Time Stamp{" "}
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {loading ? (
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={12}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              ) : (
                <Index.TableBody className="table-body">
                  {listData?.length ? (
                    listData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, index) => (
                        <Index.TableRow
                          key={list?._id}
                          className="nftListing-table-row"
                        >
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="center"
                          >
                            {rowsPerPage * page + (index + 1)}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {showjobIdDegits(list?.jobId?._id)}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {list?.jobId?.title}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {list?.officerId?.firstName} {list?.officerId?.lastName}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {list?.review}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            <Index.Box className="rating-main">
                              <Index.Box
                                sx={{
                                  "& > legend": { mt: 2 },
                                }}
                              >
                                <Index.Rating
                                  name="simple-controlled"
                                  value={list?.rating}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {PagesIndex.moment(list?.createdAt).format(
                              "MM-DD-YYYY"
                            )}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            <Index.Switch
                              checked={list.isEnable}
                              onClick={(e) => handleEnableDisableData(list._id, list.isEnable, e)}
                            >
                            </Index.Switch>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                        colSpan={15}
                        align="center"
                      >
                        <PagesIndex.NoDataFound />
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              )}
            </Index.Table>
          </Index.TableContainer>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.TablePagination
                  className="paginationColor"
                  component="div"
                  page={page}
                  count={listData?.length}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  // rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={(page) =>
                    `Records ${page.from} to ${page.to === -1 ? page.count : page.to
                    } of ${page.count}`
                  }
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
          {/* </Index.Box> */}
        </Index.Box>
      </Index.Box>
      {/* End New Code */}
    </>
  );
};

export default RatingAndReviewManagement;

import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import { getDashboardOfficerCount } from "../../../../redux-toolkit/slice/common-slice/CommonServices";
import SupervisorNonSupervisor from "./SupervisorNonSupervisor";

export default function DetailsOfPendingprofiles() {
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;

  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const officerAcceptDetail = location?.state?.officerDetails;
  console.log(officerAcceptDetail,"officerAcceptDetail")
  const tab = location?.state?.tabValue
  const dispatch = PagesIndex.useDispatch();
  const [dashboardCount, setDashboardCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [officerData, setOfficerData] = useState({});
  // const officerId = new URLSearchParams(location?.search).get("id");
  const { officerId } = PagesIndex.useParams();
  // supervisor and non supervisor request modal
  const [supervisorjobId, setSupervisorJobId] = useState();
  console.log(supervisorjobId, "supervisorjobId");
  const [supervisorRequestOpen, setSupervisorRequestOpen] = useState(false);
  const handleCloseSupervisorRequest = () => setSupervisorRequestOpen(false);
  // Edit button
  const handleSupervisorNonSupervisor = (id) => {
    setSupervisorJobId(id);
    setSupervisorRequestOpen(true);
  };

  // approve Reject funtion
  const approveRejectHandle = (value, status) => {
    const newData = {
      id: officerId,
      isApprove: value,
      officerStatus: status,
    };
    // const urlEncoded = new URLSearchParams();
    // urlEncoded.append("id", officerId);
    // urlEncoded.append("isApprove", true);
    // urlEncoded.append("officerStatus ", status);
    // console.log("newData>>>",newData)
    PagesIndex.DataService.post(
      PagesIndex.Api.Agency.APPROVE_REJECT_OFFICER_APPLICATION,
      newData
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          navigate(`/${roleName}/officer-management`);
          PagesIndex.toast.success(res?.data?.message, { toastId: "customID" });
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.mes);
      });
  };

  // // US Formate
  // const formatPhoneNumber = (input) => {
  //   // Remove all non-digit characters from the input
  //   const cleaned = ("" + input).replace(/\D/g, "");

  //   // Format the cleaned phone number
  //   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return "(" + match[1] + ") " + match[2] + "-" + match[3];
  //   }
  //   return cleaned;
  // };

  // get profile data
  const getPendingProfiles = () => {
    PagesIndex.DataService.get(
      PagesIndex.Api.Agency.GET_PENDING_PROFILE + "/" + officerId
    ).then(({ data }) => {
      setOfficerData(data?.data);
    });
  };

  // get dashboard officer count
  const getDashboardCountData = () => {
    setLoading(true);
    dispatch(getDashboardOfficerCount(officerId)).then((res) => {
      if (res?.payload?.status == 200) {
        setDashboardCount(res?.payload?.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getPendingProfiles();
    getDashboardCountData();
  }, [officerId]);

  return (
    <>
     <Index.Box className="user-list-flex">
        <Index.Box
              onClick={() =>
                navigate(`/${roleName}/officer-management`, { state: location?.state?.tabValue})
              }
            >
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
              />
            </Index.Box>
        </Index.Box>
      {!location?.state?.isPending && (
        <>
          <Index.Box className="card-main">
            <Index.Box className="officer-profile-flex">
                <img
                src={
                  officerData?.image
                    ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${officerData?.image}`
                    : PagesIndex.Png.usericon
                }
                className="prof-img"
                alt=""
              />
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    {officerData?.firstName} {officerData?.lastName}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    {officerData?.email || "-"} /{" "}
                    <span>
                      {officerData?.mobileNumber || "-"}
                    </span>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {officerData?.officerStatus == "ACCEPTED" ? (
              <>
                 <Index.Box className="card-right">
              <Index.PrimaryButton
                btnLabel="View Job History"
                className="btn-primary"
                onClick={() => {
                  navigate(
                    {
                      pathname: `/${roleName}/job-history/${officerId}`,
                      // search: PagesIndex?.createSearchParams({
                      //   id: officerId,
                      // }).toString(),
                    },
                  );
                }}
              />
            </Index.Box>
              </>
            ) : (null)}
         
          </Index.Box>
          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Completed Working Hours of the week
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-digit-bg">
                          <Index.Typography
                            className="admin-dash-price"
                            variant="h6"
                            component="h6"
                          >
                            {dashboardCount?.totalWorkingHours}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Activated jobs
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-digit-bg">
                          <Index.Typography
                            className="admin-dash-price"
                            variant="h6"
                            component="h6"
                          >
                            {dashboardCount?.totalActiveJobs}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Completed jobs
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-digit-bg">
                          <Index.Typography
                            className="admin-dash-price"
                            variant="h6"
                            component="h6"
                          >
                            {dashboardCount?.totalCompletedJobs}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </>
      )
      }
      {/* <Index.Box className="user-list-flex">
        <Index.Link to={`/${roleName}/officer-management`}>
          <img
            src={PagesIndex.Svg.leftArrow}
            className="left-arrow-ic"
            alt="search grey img"
          />
        </Index.Link>
      </Index.Box> */}

      <Index.Box className="common-card">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Work Information
          </Index.Typography>
        </Index.Box>
        <Index.Box className="officer-detail">
          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Name :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text"
            >
              {officerData?.firstName} {officerData?.lastName}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Email Address :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text"
            >
              {officerData?.email || "-"}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Phone Number :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text"
            >
              {officerData?.mobileNumber}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Skill :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text"
            >
              {officerData?.skills?.length
                ? officerData?.skills?.join(", ")
                : "-"}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Rank :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text"
            >
              {officerData?.rank || "-"}
            </Index.Typography>
          </Index.Box>

          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Required Pay Rate (Per hour) :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text"
            >
              {officerData?.rate ? "$" : null}
              {officerData?.rate || "-"}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Total Worked Hours :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text"
            >
              {officerData?.totalWorkHours || "-"} hour
            </Index.Typography>
          </Index.Box>

          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Supervisor/NonSupervisor :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text edit-btn-flex"
            >
              {officerData?.jobName || "-"}
              {tab == "PENDING" ? (
                <Index.Tooltip
                  title="Edit"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Box className="btn-main-secondary">
                    <Index.Button
                      btnLabel="Edit"
                      className="btn-secondary"
                      onClick={() =>
                        handleSupervisorNonSupervisor(officerData?._id)
                      }
                    >
                      <img
                        src={PagesIndex.Svg.editNew}
                        alt="delete"
                        className="admin-icons"
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Tooltip>
              ) : (
                null
              )}

            </Index.Typography>
          </Index.Box>
          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Available For :&nbsp;
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="work-value-text"
            >
              {officerData?.jobType?.jobType || "-"}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="work-title-flex">
            <Index.Typography
              component="p"
              variant="p"
              className="work-title-lable"
            >
              Available on :&nbsp;
            </Index.Typography>
            <Index.Box className="">
              {officerData?.days?.map((item, index) => {
                return (
                  <>
                    {item.isActive == true ? (
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        <span className="day-title">
                          {item?.day.charAt(0).toUpperCase() +
                            item?.day.slice(1)}
                        </span>{" "}
                        -{" "}
                        <span className="day-title">
                          {item?.startTime
                            ? item?.startTime + " to "
                            : null}
                          {item?.endTime}
                        </span>
                      </Index.Typography>
                    ) : null}
                  </>
                );
              })}
            </Index.Box>
          </Index.Box>
          {officerData?.certificates?.length ? (
            <>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Document :&nbsp;
                </Index.Typography>
              </Index.Box>
            </>
          ) : (
            ""
          )}
          <>
            {officerData?.certificates?.map((data, index) => {
              return (
                <Index.Box className="document-main work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    {data}
                  </Index.Typography>
                  <Index.PrimaryButton
                    btnLabel="View Document"
                    className="btn-primary"
                    onClick={() => {
                      window.open(
                        `${PagesIndex.ADMIN_IMAGE_ENDPOINT}${data}`
                      );
                    }}
                  />
                </Index.Box>
              );
            })}
            {officerData?.license?.map((data, index) => {
              return (
                <Index.Box className="document-main work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    {data}
                  </Index.Typography>
                  <Index.PrimaryButton
                    btnLabel="View Document"
                    className="btn-primary"
                    onClick={() => {
                      window.open(
                        `${PagesIndex.ADMIN_IMAGE_ENDPOINT}${data}`
                      );
                    }}
                  />
                </Index.Box>
              );
            })}
            <br />
            {console.log(
              "stas>>",
              officerData?.officerStatus === "PENDING" ||
              officerData?.officerStatus === "REJECTED"
            )}
            <Index.Box className="user-btn-flex">
              {officerData?.officerStatus === "PENDING" && (
                <Index.PrimaryButton
                  btnLabel="Approve"
                  className="btn-primary bg-approve"
                  onClick={() => {
                    approveRejectHandle(true, "ACCEPTED");
                  }}
                />
              )}
              {officerData?.officerStatus === "REJECTED" && (
                <Index.PrimaryButton
                  btnLabel="Approve"
                  className="btn-primary bg-approve"
                  onClick={() => {
                    approveRejectHandle(true, "ACCEPTED");
                  }}
                />
              )}
              {officerData?.officerStatus === "PENDING" && (
                <Index.PrimaryButton
                  btnLabel="Reject"
                  className="btn-primary bg-suspend"
                  onClick={() => {
                    approveRejectHandle(false, "REJECTED");
                  }}
                />
              )}
            </Index.Box>
          </>
        </Index.Box>
      </Index.Box>
      <SupervisorNonSupervisor
        supervisorRequestOpen={supervisorRequestOpen}
        handleCloseSupervisorRequest={handleCloseSupervisorRequest}
        supervisorjobId={supervisorjobId}
        getPendingProfiles={getPendingProfiles}
        officerData={officerData}
      />
    </>
  );
}

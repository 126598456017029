import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { AgencyAddOfficerSchema } from "../../../../../validation/FormikSchema";
import {
  getAgencyList,
  getRollList,
} from "../../../../../redux-toolkit/slice/common-slice/CommonServices";
import Loader from "../../../../../common/loader/Loader";
import { SignalCellularNullRounded } from "@mui/icons-material";

const AddNewOfficer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { open, handleClose } = props;
  const dispatch = PagesIndex.useDispatch();
  const agencyLoginData = PagesIndex.useSelector(
    (state) => state.AgencySlice.agencyLoginData
  );
  const roleName = agencyLoginData?.roleId?.Permission_name;
  console.log(agencyLoginData, "agencyLoginData", agencyLoginData.agencyName)
  console.log(roleName, "roleName222")

  let officerType = [];
  if (roleName === "Department") {
    officerType.push({ title: "Active Police Officer" })
  } else {
    officerType.push(
      { title: "Retired Police Officer" },
      { title: "Retired Military Officer" },
      { title: " Security Officer" }
    )
  }

  // for validation
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  const ONLY_ALPHA_NOT_SPACE_SPACIALCHAR = /[^a-zA-Z]+|\s+/g;
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;

  const [officerRoleId, setOfficerRoleId] = useState();
  console.log(officerRoleId, "officerRoleId")
  const [agencyList, setAgencyList] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [departmentList, setDepartmentList] = useState();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    // zipCode: "",
    agencyId: "",
    officerType: "",
    password: "",
    confirmPassword: "",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  // const initialValues = {
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   mobileNumber: "",
  //   // zipCode: "",
  //   agencyId: "",
  //   officerType: "",
  //   password: "",
  //   confirmPassword: "",
  // };

  useEffect(() => {
    dispatch(getAgencyList()).then((res) => {
      if (res?.payload?.status == 200) {
        setAgencyList(res?.payload?.data);

        let selectedAgency = res?.payload?.data?.find(
          (item) => item?._id === agencyLoginData?._id
        );

        setInitialValues((prev) => ({
          ...prev,
          agencyId: selectedAgency?._id,
        }));
      }
    });
  }, [dispatch]);

  // Roll List  Api call
  useEffect(() => {
    dispatch(getRollList()).then((res) => {
      if (res?.payload?.status == 200) {
        const rolematch = res?.payload?.data.filter((matchData) => {
          return matchData.Permission_name?.toLowerCase() === "officer";
        });
        if (rolematch?.length > 0) {
          setOfficerRoleId(rolematch?.[0]?._id);
        }
      }
    });
  }, [dispatch]);

  // console.log("rollList", rollList);
  const handleAgencyChangePasswordForm = async (values) => {
    setIsLoading(true);
    // console.log("values===", values);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("firstName", values?.firstName);
    urlEncoded.append("lastName", values?.lastName);
    urlEncoded.append("email", values?.email.toLowerCase());
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    // urlEncoded.append("zipCode", values?.zipCode);
    console.log("agencyLoginData?._id", agencyLoginData?._id)
    urlEncoded.append("agencyId", roleName.toLowerCase() == "department" ? agencyLoginData?._id : values?.agencyId);
    urlEncoded.append("officerType", roleName.toLowerCase() == "department" ? 'Active Police Officer' : values?.officerType);
    urlEncoded.append("roleId", officerRoleId);
    urlEncoded.append("password", values?.password);
    console.log("urlEncoded", [...urlEncoded]);
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_ADD_OFFICER,
        urlEncoded
      );
      if (response?.data?.status === 201) {
        PagesIndex.toast.success(response?.data?.message);
        handleClose();
        setIsLoading(false);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  //  phone number functionality

  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;

    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");

    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }

    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
  };
  // get Department by Agency List 
  const getDepartmentByAgencyList = async (data) => {
    try {
    
      const stringWithoutSpaces = data?.replace(/\s/g, '');
      const res = await DataService.get(`${Api.Common.GET_DEPARTMENT_BY_AGENCY_LIST}/${stringWithoutSpaces}`);
      setDepartmentList(res?.data?.data);
      console.log(res?.data?.data, "list");
    } catch (error) {
      console.error(error);
    }
  };

  // PagesIndex.useEffect(() => {
  //   getDepartmentByAgencyList();
  // }, []);

  useEffect(() => {
    return () => {
      setShowPassword(false);
      setShowConfirmPassword(false);
    };
  }, [handleClose]);
  return (
    <>
      <Index.Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          validationSchema={AgencyAddOfficerSchema}
          initialValues={initialValues}
          onSubmit={handleAgencyChangePasswordForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleFocus,
          }) => (
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              {console.log("error", errors)}
              {/* {console.log("values>>", values)} */}
              <Index.Box
                sx={style}
                className="add-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add New Officer
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                    alt=""
                  />
                </Index.Box>
                <Index.Box className="modal-body">
                  <Index.Box sx={{ flexGrow: 1 }}>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={6}>
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            First Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter first name"
                              name="firstName"
                              value={values.firstName}
                              inputProps={{ maxLength: 25 }}
                              onPaste={(e) => {
                                e.preventDefault();
                                setFieldValue("firstName", "")
                              }}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("firstName", e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " " && e.target.value.trim() === "") {
                                  e.preventDefault(); // Prevent space as the first character
                                } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                                  e.preventDefault(); // Prevent adding additional spaces between words
                                } else if (e.key === " " && e.target.value.endsWith(" ")) {
                                  e.preventDefault(); // Prevent additional spaces at the end
                                }
                                if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                  e.preventDefault(); // Prevent non-numeric characters
                                }
                              }}

                              onBlur={handleBlur}
                              error={
                                errors.firstName && touched.firstName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.firstName && touched.firstName
                                  ? errors.firstName
                                  : null
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={6}>
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Last Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter last name"
                              name="lastName"
                              value={values.lastName}
                              inputProps={{ maxLength: 25 }}
                              onPaste={(e) => {
                                e.preventDefault();
                                setFieldValue("lastName", "")
                              }}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("lastName", e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === " " && e.target.value.trim() === "") {
                                  e.preventDefault(); // Prevent space as the first character
                                } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                                  e.preventDefault(); // Prevent adding additional spaces between words
                                } else if (e.key === " " && e.target.value.endsWith(" ")) {
                                  e.preventDefault(); // Prevent additional spaces at the end
                                }
                                if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                  e.preventDefault(); // Prevent non-numeric characters
                                }
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.lastName && touched.lastName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.lastName && touched.lastName
                                  ? errors.lastName
                                  : null
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={6}>
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Email Address
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter email address"
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                handleChange(e);
                                roleName.toLowerCase() == "department" && setFieldValue("officerType", 'Active Police Officer' );
                              }}
                              onKeyDown={(event) => {
                                if (event.key == " ") {
                                  event.preventDefault();
                                }
                              }}
                              inputProps={{ maxLength: 60 }}
                              onBlur={handleBlur}
                              error={
                                errors.email && touched.email ? true : false
                              }
                              helperText={
                                errors.email && touched.email
                                  ? errors.email
                                  : null
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={6}>
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Phone Number
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              // type="tel"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter phone number"
                              name="mobileNumber"
                              value={values?.mobileNumber}
                              onChange={(e) => {
                                handlePhoneChange(e, setFieldValue);
                              }}
                              inputProps={{ maxLength: 10 }}
                              error={
                                errors.mobileNumber && touched.mobileNumber
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.mobileNumber && touched.mobileNumber
                                  ? errors.mobileNumber
                                  : false
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                   { console.log("410",roleName.toLowerCase() !== "department")}
                    {
                      roleName.toLowerCase() !== "department" ? (
                        <Index.Grid container spacing={2}>
                          <Index.Grid item xs={6}>
                            <Index.Box className="input-box modal-input-box">
                              <Index.FormHelperText className="form-lable">
                                {" "}
                                Select Officer Type
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box">
                                <Index.FormControl className="form-control drop-form-control">
                                  <Index.Select
                                    className="dropdown-select drop-select"
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                    name="officerType"
                                    value={values?.officerType}
                                    onChange={handleChange}
                                    renderValue={
                                      values?.officerType
                                        ? undefined
                                        : () => "Select officer type"
                                    }
                                    error={
                                      errors.officerType && touched.officerType
                                        ? true
                                        : false
                                    }
                                  >
                                    {officerType && officerType.length > 0
                                      ? officerType.map((data, index) => {
                                        return (
                                          <Index.MenuItem
                                            key={index}
                                            value={data?.title}
                                            className="drop-menuitem"
                                            onClick={() => getDepartmentByAgencyList(data?.title)}
                                          >
                                            {data?.title}
                                          </Index.MenuItem>
                                        );
                                      })
                                      : null}
                                  </Index.Select>
                                </Index.FormControl>
                              </Index.Box>
                              <Index.FormHelperText error>
                                {errors.officerType && touched.officerType
                                  ? errors.officerType
                                  : null}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={6}>
                            <Index.Box className="input-box modal-input-box">
                              <Index.FormHelperText className="form-lable">
                                {agencyLoginData?.roleId?.Permission_name == "Department" ? "Department" : "Agency"}
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  disabled
                                  placeholder="Please enter first name"
                                  name="agencyId"
                                  value={agencyLoginData.agencyName}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue("agencyId", e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    if (

                                      (e.key === " " &&
                                        e.target.value.trim() === "")
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      ) : null 
                    }

                    
                        <Index.Grid container spacing={2}>
                          <Index.Grid item xs={6}>
                            <Index.Box className="input-box modal-input-box">
                              <Index.FormHelperText className="form-lable">
                                Password
                              </Index.FormHelperText>
                              <Index.Box className="form-group pwd-icon-btn">
                                <Index.OutlinedInput
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control-eye"
                                  name="password"
                                  onBlur={handleBlur}
                                  value={values.password}
                                  // onChange={handleChange}
                                  placeholder="Please enter password"
                                  inputProps={{ maxLength: 16 }}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /\s/g,
                                      ""
                                    );
                                    setFieldValue("password", newValue);
                                    setFieldTouched("password", true);
                                  }}
                                  helperText={touched.password && errors.password}
                                  error={Boolean(
                                    errors.password && touched.password
                                  )}
                                  onKeyDown={(event) => {
                                    if (event.key == " ") {
                                      event.preventDefault();
                                    }
                                  }}
                                  type={showPassword ? "text" : "password"}
                                  endAdornment={
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {!showPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  }
                                />
                              </Index.Box>
                              <Index.FormHelperText error className="error-text">
                                {errors.password && touched.password
                                  ? errors.password
                                  : null}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={6}>
                            <Index.Box className="input-box modal-input-box">
                              <Index.FormHelperText className="form-lable">
                                Confirm password
                              </Index.FormHelperText>
                              <Index.Box className="form-group pwd-icon-btn">
                                <Index.OutlinedInput
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control-eye"
                                  name="confirmPassword"
                                  placeholder="Please enter confirm password"
                                  onBlur={handleBlur}
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  inputProps={{ maxLength: 16 }}
                                  helperText={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                  error={Boolean(
                                    errors.confirmPassword &&
                                    touched.confirmPassword
                                  )}
                                  onKeyDown={(event) => {
                                    if (event.key == " ") {
                                      event.preventDefault();
                                    }
                                  }}
                                  type={showConfirmPassword ? "text" : "password"}
                                  endAdornment={
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickConfirmShowPassword}
                                        // onMouseDown={handleMouseDownConfirmPassword}
                                        edge="end"
                                      >
                                        {!showConfirmPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  }
                                />
                              </Index.Box>
                              <Index.FormHelperText error className="error-text">
                                {errors.confirmPassword && touched.confirmPassword
                                  ? errors.confirmPassword
                                  : null}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                    
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="modal-user-btn-flex document-btn-grp">
                    <Index.PrimaryButton
                      className="btn-primary bg-approve appr-rej-btn"
                      type="submit"
                      btnLabel="Add"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>
                    <Index.PrimaryButton
                      className="btn-primary bg-suspend appr-rej-btn"
                      onClick={handleClose}
                      btnLabel="Cancel"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AddNewOfficer;

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
// import { persistor, store } from './redux/store';
// import { store, persistor } from "./redux-toolkit/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux-toolkit/store";

const root = ReactDOM.createRoot(document.getElementById("root"));

{
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  
);

{/* </React.StrictMode> */}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { skillsAddSchema } from "../../../../../validation/FormikSchema";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const AddSkillModal = (props) => {
  const { addSkillsopen, handleAddSkillsClose, getSkillList, editSkillsData } =
    props;
  // validation regex
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const initialValues = {
    skill: editSkillsData ? editSkillsData?.skill : "",
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleAddSkillForm = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    if (editSkillsData) {
      urlEncoded.append("id", editSkillsData?._id);
    }
    urlEncoded.append("skill", values?.skill);
    try {
      const response = await DataService.post(
        Api.Agency.ADD_EDIT_SKILL,
        urlEncoded
      );
      if (response?.data?.status === 200 || 201) {
        PagesIndex.toast.success(response?.data?.message, { toastId: "customId" });
        handleAddSkillsClose();
        getSkillList();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, { toastId: "customId" });
      setIsLoading(false);
    }
  };
  return (
    <>
      <Index.Modal
        open={addSkillsopen}
        onClose={handleAddSkillsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          validationSchema={skillsAddSchema}
          initialValues={initialValues}
          onSubmit={handleAddSkillForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            handleFocus,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log("error", errors)}
              {/* {console.log("values>>",values)} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {!editSkillsData ? "Add New skill" : "Edit skill"}
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleAddSkillsClose}
                    alt=""
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Box className="admin-modal-hgt-scroll">
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Skill
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Please enter skill"
                          name="skill"
                          value={values.skill}
                          // onChange={han
                          onPaste={(e)=>{
                            e.preventDefault();
                            setFieldValue("skill", "")
                          }}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("skill", e.target.value);
                          }}
                          // onBlur={handleBlur}
                          focused={false}
                          error={errors.skill && touched.skill ? true : false}
                          helperText={
                            errors.skill && touched.skill ? errors.skill : null
                          }
                          inputProps={{maxLength: 30}}
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault(); // Prevent space as the first character
                            } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                              e.preventDefault(); // Prevent adding additional spaces between words
                            } else if (e.key === " " && e.target.value.endsWith(" ")) {
                              e.preventDefault(); // Prevent additional spaces at the end
                            }
                            if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                              e.preventDefault(); // Prevent non-numeric characters
                            }
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel={!editSkillsData ? "Add" : "update"}
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : 'Click me'}
                    </Index.PrimaryButton>

                    <Index.PrimaryButton
                      btnLabel="cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => handleAddSkillsClose()}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AddSkillModal;

import React, { useEffect, useRef, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import { SwipeableDrawer } from "@mui/material";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
// import AddWorkPermit from "./AddWorkPolicy";
import Loader from "../../../../common/loader/Loader";
// import ExtraPendingModal from "./workPolicy/RejectModal";
import AddTransferJob from "./AddTransferJob";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

export default function TransferJob() {
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();
  const [tabValue, setTabValue] = useState(state ? state : "Pending");
  // reject modal
  const [openReject, setOpenReject] = useState(false);
  const [userListData, setUserListData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [extraWorkList, setExtraWorkList] = useState([]);
  console.log(extraWorkList, "extraWorkList");
  const handleOpenReject = () => setOpenReject(true);
  const handleCloseReject = () => setOpenReject(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editUserData, setEditUserData] = useState("");
  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // Add user Modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditUserData("");
  };

  // Pending Job request modal
  const [pendingjobId, setPendingJobId] = useState();
  const [pandingRequestopen, setPandingRequestOpen] = useState(false);
  const handleOpenPandingRequest = (id) => {
    setPendingJobId(id);
    setPandingRequestOpen(true);
  };
  const handleClosePandingRequest = () => setPandingRequestOpen(false);

  // filter
  const [extraFilter, setExtraFilter] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setExtraFilter({ ...extraFilter, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );

  // Get job hours limit
  const getJobHoursLimitList = async () => {
    try {
      const res = await DataService.get(Api.Agency.TRANSFER_JOB_HOURS_LIMIT);
      setFilteredData(res?.data?.data);
      console.log(res?.data?.data, "list");
    } catch (error) {
      console.error(error);
    }
  };

  PagesIndex.useEffect(() => {
    getJobHoursLimitList();
  }, []);

  return (
    <>
      <Index.Box className="extra-work-permit-request-wrape">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            {/* <Index.Link to="/agency/dashboard" className="filter-back-link">
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
              ></img>
            </Index.Link>{" "} */}
            Transfer Job
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                {filteredData.length > 0 ? (null) : (
                  <Index.Button
                    className="adduser-btn btn-primary"
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="plus-icon"
                      alt="plus icon"
                    />
                    Add Hour Limit
                  </Index.Button>
                )}

              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="mob-inner-scroll-area">
          <Index.Box className="dashboard-content extra-work-main bg-none p-0">
            <Index.Box className="officer-detail-wrapper">
              {filteredData.length > 0 ? (
                filteredData.map((item) => {
                  return (
                    <>
                      <Index.Box className="common-card job-mamangment-card">
                        <Index.Box className="admin-sub-flex">
                          <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                            <Index.Typography component="p" variant="p" className="admin-sub-title">
                              Job Transfer Hours :&nbsp;
                            </Index.Typography>
                            <Index.Typography component="p" variant="p" className="admin-sub-title-value-text">
                              {item?.jobTransferHours}
                            </Index.Typography>
                          </Index.Box>
                          <Index.PrimaryButton onClick={() => {
                            handleOpen();
                            setEditUserData(item);
                          }}
                            btnLabel="Edit"
                            className="btn-primary"
                          />
                        </Index.Box>
                      </Index.Box>
                    </>
                  );
                })
              ) : (
                <PagesIndex.NoDataFound />
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <AddTransferJob
          getJobHoursLimitList={getJobHoursLimitList}
          handleClose={handleClose}
          open={open}
          editUserData={editUserData}
        />
      </Index.Box>
    </>
  );
}

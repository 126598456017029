import React, { useState } from "react";
import { getDashboardCount } from "../../../../redux-toolkit/slice/common-slice/CommonServices";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import Loader from "../../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = PagesIndex.useDispatch();
  const navigate = useNavigate();
  const [dashboardCount, setDashboardCount] = useState(false);
  const [loading, setLoading] = useState(false);

  // get dashboard count
  const getDashboardCountData = () => {
    setLoading(true);
    dispatch(getDashboardCount()).then((res) => {
      if (res?.payload?.status == 200) {
        setDashboardCount(res?.payload?.data);
        setLoading(false);
      }
    });
  };

  PagesIndex.useEffect(() => {
    getDashboardCountData();
  }, []);
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title admin-res-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          Dashboard
        </Index.Typography>
      </Index.Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Index.Box className="dashboard-page">
            <Index.Box className="admin-dashboad-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row res-flx"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  {/* <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Posted Jobs
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h6"
                          component="h6"
                        >
                          {dashboardCount?.TotalPostedJob}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box> */}
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={() => navigate('/admin/vendormanage')}
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Users
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalUsers}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={() => navigate('/admin/officer-management')}
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Officers
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalOfficers}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={() => navigate('/admin/agentmanagement', {state:{agencyType:'agency'}})}
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Agencies
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalAgencies}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6", 
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={()=> navigate('/admin/agentmanagement', {state:{agencyType:'department'}})}
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Departments
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalDepartments}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={() => navigate('/admin/adminjobmanagement', { state: { status: 'PendingUser' } })}

                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total pending job requests
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalPendingJob}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={() => navigate('/admin/adminjobmanagement', { state: { status: 'CompletedJobs' } })}
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total completed jobs
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalCompletedJobs}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={() => navigate('/admin/adminjobmanagement', { state: { status: 'ActiveJobs' } })}
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total active jobs
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalActiveJob}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={() => navigate('/admin/adminjobmanagement', { state: { status: 'UnservedJobs' } })}
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Unserved Jobs
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalUnservedJobs}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                    onClick={() => navigate('/admin/officer-management',{state:{isOfficerActive:'activate'}})}

                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total active officers
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalActiveOfficer}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {/* <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Revenue generated
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              $100M
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box> */}
{/* 
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Help & Support Requests
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              25
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box> */}

                  {/* <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Recently created accounts
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h6"
                          component="h6"
                        >
                          500
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box> */}

                  {/* <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Recently done payment
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h6"
                          component="h6"
                        >
                          $1M
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box> */}



                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="res-con report-section">
            <Index.Box
              className="card-wrape grid-column box-shadow"
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 3",
              }}
            >
              {/* <Index.Box className="card-header res-card-header">
              <Index.Typography
                className="admin-sub-title"
                component="h3"
                variant="h3"
              >
                Reports & Analysis
              </Index.Typography>
            </Index.Box>

            <Index.Box className="card-body res-card-body">
              {[1, 2, 3].map((item, index) => (
                <Index.Box className="list-content-action">
                  <Index.Box className="list-content">
                    <Index.Typography
                      className="list-title"
                      component="h6"
                      variant="h6"
                    >
                      Job Report {index + 1}
                    </Index.Typography>
                    <Index.Typography
                      className="pragraph"
                      component="p"
                      variant="p"
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="button-wrape">
                    <Index.Link to="/agency/agency-view-report">
                      <Index.PrimaryButton
                        btnLabel="View"
                        className="btn-primary"
                      />
                    </Index.Link>
                    <Index.PrimaryButton
                      btnLabel="Download"
                      className="btn-primary"
                    />
                  </Index.Box>
                </Index.Box>
              ))}
            </Index.Box> */}
            </Index.Box>
          </Index.Box>
        </>
      )}

    </>
  );
};
export default Dashboard;

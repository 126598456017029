import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import { adminAddOfficerSchema } from "../../../../../validation/FormikSchema";
import {
  getAgencyList,
  getDepartmentByAgencyList,
  getRollList,
} from "../../../../../redux-toolkit/slice/common-slice/CommonServices";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Loader from "../../../../../common/loader/Loader";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";
// import PhoneInput from "react-phone-number-input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export const AddNewOfficerModal = ({
  open,
  setOpen,
  handleClose,
  getOfficerListData,
}) => {
  const dispatch = PagesIndex.useDispatch();
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const [isLoading, setIsLoading] = useState(false);
  const officerType = [
    { title: "Active Police Officer" },
    { title: "Retired Police Officer" },
    { title: "Retired Military Officer" },
    { title: " Security Officer" },
  ];

  const [officerRoleId, setOfficerRoleId] = useState();
  console.log(officerRoleId, "officerRoleId");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [departmentList, setDepartmentList] = useState(false);

  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    // zipCode: "",
    agencyId: "",
    officerType: "",
    password: "",
    confirmPassword: "",
  };
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Roll List  Api call
  useEffect(() => {
    dispatch(getRollList()).then((res) => {
      if (res?.payload?.status == 200) {
        const rolematch = res?.payload?.data?.filter((matchData) => {
          return matchData?.Permission_name?.toLowerCase() === "officer";
        });
        if (rolematch?.length > 0) {
          setOfficerRoleId(rolematch?.[0]?._id);
        }
      }
    });
  }, [dispatch]);

  const handleAddNewOfficer = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("firstName", values.firstName);
    urlEncoded.append("lastName", values.lastName);
    urlEncoded.append("email", values.email.toLowerCase());
    urlEncoded.append("password", values.password);
    urlEncoded.append("mobileNumber", values.mobileNumber);
    // urlEncoded.append("zipCode", values.zipCode);
    urlEncoded.append("agencyId", values.agencyId);
    urlEncoded.append("officerType", values?.officerType);
    urlEncoded.append("roleId", officerRoleId);
    try {
      const response = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.ADD_NEWOFFICER,
        urlEncoded
      );
      if (response?.data?.status == 201) {
        PagesIndex.toast.success(response?.data?.message);
        getOfficerListData();
        handleClose();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  PagesIndex.useEffect(() => {
    dispatch(getAgencyList());
  }, []);

  const handleOfficerCancel = () => {
    handleClose();
  };

  // get Department by Agency List 
  const getDepartmentByAgencyList = async (data) => {
    try {
      const stringWithoutSpaces = data?.replace(/\s/g, '');
      const res = await DataService.get(`${Api.Common.GET_DEPARTMENT_BY_AGENCY_LIST}/${stringWithoutSpaces}`);
      setDepartmentList(res?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  // PagesIndex.useEffect(() => {
  //   getDepartmentByAgencyList();
  // }, []);

  
  //  phone number functionality

  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
    
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");
    
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }
    
    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
};

useEffect(() => {
    return () => {
      setShowPassword(false);
      setShowConfirmPassword(false);
    };
  }, [handleClose]);
  return (
    <div>
      <Index.Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          initialValues={initialValues}
          onSubmit={handleAddNewOfficer}
          validationSchema={adminAddOfficerSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {console.log(errors, "error")} */}
              {console.log(values, "values>>")}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add New Officer
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                    alt=""
                  />
                </Index.Box>
                <Index.Box className="modal-body">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            First Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter first name"
                              name="firstName"
                              onPaste={(e)=>{
                                e.preventDefault();
                                setFieldValue("firstName", "");
                              }}
                              value={values.firstName}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("firstName", e.target.value);
                              }}
                              inputProps={{ maxLength: 25 }}
                              onKeyDown={(e) => {
                                if (e.key === " " && e.target.value.trim() === "") {
                                  e.preventDefault(); // Prevent space as the first character
                                } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                                  e.preventDefault(); // Prevent adding additional spaces between words
                                } else if (e.key === " " && e.target.value.endsWith(" ")) {
                                  e.preventDefault(); // Prevent additional spaces at the end
                                }
                                if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                  e.preventDefault(); // Prevent non-numeric characters
                                }
                              }}
                              // onKeyDown={(e) => {
                              //   if (

                              //     (e.key === " " &&
                              //       e.target.value.trim() === "")
                              //   ) {
                              //     e.preventDefault();
                              //   }
                              // }}
                              error={
                                errors.firstName && touched.firstName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.firstName && touched.firstName
                                  ? errors.firstName
                                  : false
                              }
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Last Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter last name"
                              name="lastName"
                              value={values.lastName}
                              onPaste={(e)=>{
                                e.preventDefault();
                                setFieldValue("lastName", "")
                              }}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("lastName", e.target.value);
                              }}
                              inputProps={{ maxLength: 25 }}
                              // onKeyDown={(e) => {
                              //   if (

                              //     (e.key === " " &&
                              //       e.target.value.trim() === "")
                              //   ) {
                              //     e.preventDefault();
                              //   }
                              // }}
                              onKeyDown={(e) => {
                                if (e.key === " " && e.target.value.trim() === "") {
                                  e.preventDefault(); // Prevent space as the first character
                                } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                                  e.preventDefault(); // Prevent adding additional spaces between words
                                } else if (e.key === " " && e.target.value.endsWith(" ")) {
                                  e.preventDefault(); // Prevent additional spaces at the end
                                }
                                if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                  e.preventDefault(); // Prevent non-numeric characters
                                }
                              }}
                              error={
                                errors.lastName && touched.lastName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.lastName && touched.lastName
                                  ? errors.lastName
                                  : false
                              }
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Email Address
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter email address"
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("email", newValue);
                                // setFieldTouched("email", true);
                              }}
                              inputProps={{ maxLength: 60 }}
                              error={
                                errors.email && touched.email ? true : false
                              }
                              helperText={
                                errors.email && touched.email
                                  ? errors.email
                                  : false
                              }
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Phone Number
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              // type="tel"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter phone number"
                              name="mobileNumber"
                              onBlur={handleBlur}
                              value={values?.mobileNumber}
                              onChange={(e) => {
                                handlePhoneChange(e, setFieldValue);
                              }}
                              inputProps={{ maxLength: 10 }}
                              error={
                                errors.mobileNumber && touched.mobileNumber
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.mobileNumber && touched.mobileNumber
                                  ? errors.mobileNumber
                                  : null
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>


                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            {" "}
                            Select Officer Type
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box">
                            <Index.FormControl className="form-control drop-form-control">
                              <Index.Select
                                className="dropdown-select drop-select"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                name="officerType"
                                value={values?.officerType}
                                onBlur={handleBlur}
                                onFocus={() =>
                                  setFieldTouched("officerType", true)
                                }
                                onChange={handleChange}
                                renderValue={
                                  values?.officerType
                                    ? undefined
                                    : () => "Select officer type"
                                }
                                error={
                                  errors.officerType && touched.officerType
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.officerType && touched.officerType
                                    ? errors.officerType
                                    : null
                                }
                              >
                                {officerType && officerType.length > 0
                                  ? officerType.map((data, index) => {
                                    return (
                                      <Index.MenuItem
                                        key={index}
                                        value={data?.title}
                                        className="drop-menuitem"
                                        // onClick={handleSelectOfficer()}
                                        onClick={() => getDepartmentByAgencyList(data?.title)}
                                      >
                                        {data?.title}
                                      </Index.MenuItem>
                                    );
                                  })
                                  : null}
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                          <Index.FormHelperText error>
                            {errors.officerType && touched.officerType
                              ? errors.officerType
                              : null}
                          </Index.FormHelperText>
                        </Index.Box>

                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            {" "}
                            Select Agency
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box">
                            <Index.FormControl className="form-control drop-form-control">
                              <Index.Select
                                className="dropdown-select drop-select"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                name="agencyId"
                                value={values?.agencyId}
                                onBlur={handleBlur}
                                onFocus={() =>
                                  setFieldTouched("agencyId", true)
                                }
                                onChange={handleChange}
                                renderValue={
                                  values?.agencyId
                                    ? undefined
                                    : () => "Select agency"
                                }
                                error={
                                  errors.agencyId && touched.agencyId
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.agencyId && touched.agencyId
                                    ? errors.agencyId
                                    : null
                                }
                              >
                                {departmentList && departmentList.length > 0
                                  ? departmentList.map((data, index) => {
                                    return (
                                      <Index.MenuItem
                                        key={index}
                                        // key={data?._id}
                                        value={data?._id}
                                        className="drop-menuitem"
                                      >
                                        {data?.agencyName}
                                      </Index.MenuItem>
                                    );
                                  })
                                  : null}
                              </Index.Select>

                            </Index.FormControl>
                          </Index.Box>
                          <Index.FormHelperText error>
                            {errors.agencyId && touched.agencyId
                              ? errors.agencyId
                              : null}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group pwd-icon-btn">
                            <Index.OutlinedInput
                              fullWidth
                              id="fullWidth"
                              className="form-control-eye"
                              name="password"
                              placeholder="Please enter password"
                              onBlur={handleBlur}
                              value={values.password}
                              inputProps={{ maxLength: 16 }}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("password", newValue);
                                setFieldTouched("password", true);
                              }}
                              helperText={touched.password && errors.password}
                              error={Boolean(
                                errors.password && touched.password
                              )}
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {!showPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />
                          </Index.Box>
                          <Index.FormHelperText error className="error-text">
                            {errors.password && touched.password
                              ? errors.password
                              : null}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Confirm password
                          </Index.FormHelperText>
                          <Index.Box className="form-group pwd-icon-btn">
                            <Index.OutlinedInput
                              fullWidth
                              id="fullWidth"
                              className="form-control-eye"
                              name="confirmPassword"
                              placeholder="Please enter confirm password"
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("confirmPassword", newValue);
                                // setFieldTouched("confirmPassword", true);
                              }}
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              error={Boolean(
                                errors.confirmPassword &&
                                touched.confirmPassword
                              )}
                              type={showConfirmPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {!showConfirmPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />
                            <Index.FormHelperText error className="error-text">
                              {errors.confirmPassword && touched.confirmPassword
                                ? errors.confirmPassword
                                : null}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      className="btn-primary bg-approve "
                      type="submit"
                      btnLabel="Add"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>
                    <Index.PrimaryButton
                      className="btn-primary bg-suspend "
                      onClick={handleOfficerCancel}
                      btnLabel="Cancel"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";
import PagesIndex from "../../../PageIndex";
import Index from "../../..";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { editOfficerDetailSchema } from "../../../../validation/FormikSchema";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import Loader from "../../../../common/loader/Loader";
import {
  getAgencySkillsData,
  getJobTypeAgency,
} from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";
// import { getZipCodeData } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import { useParams } from "react-router-dom";

const demo = [
  { title: "one", id: "1" },
  { title: "two", id: "2" },
  { title: "three", id: "3" },
];
const skills = [
  { title: "skill one", id: "1" },
  { title: "skill two", id: "2" },
  { title: "skill three", id: "3" },
];

export const officerList = [
  {
    id: 1,
    name: "Active Police Officer",
  },
  {
    id: 2,
    name: "Retired Police Officer",
  },
  {
    id: 3,
    name: " Retired Military Officer",
  },
  {
    id: 4,
    name: " Security Officer",
  },
];

const AgencyEditOfficerDetail = () => {
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;

  const formik = useRef();
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  const [updateData, setUpdateData] = useState({});
  const [updateDataLoading, setUpdateDataLoading] = useState(false);
  const location = PagesIndex.useLocation();
  //   const officerId = new URLSearchParams(location?.search).get("id");
  const { officerId } = useParams();
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const [todyear, setTodyear] = React.useState("");
  const currentTime = new Date();
  // job type
  const [jobTypeList, setJobTypeList] = useState();
  const [isDayCheck, setDayCheckAll] = useState(false);
  const [selectProducts, setSelectProducts] = useState([]);
  console.log(jobTypeList, "jobTypeList")
  const handleChangetodyear = (event) => {
    setTodyear(event.target.value);
  };

  const [isLoading, setIsLoading] = useState(false);
  const[isAllDays,setAllDays] = useState(null);

  const [todmonth, setTodmonth] = React.useState("");
  const handleChangetodmonth = (event) => {
    setTodmonth(event.target.value);
  };

  console.log("isAllDays",isAllDays)
  //  phone number functionality

  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;

    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");

    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }

    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
  };
  // zip code
  const [zipCodeList, setZipCodeList] = useState();

  // get zip code
  //   const getZipCode = () => {
  //     dispatch(getZipCodeData()).then((res) => {
  //       if (res?.payload?.status == 200) {
  //         setZipCodeList(res?.payload?.data);
  //       }
  //     });
  //   };
  // Skill list
  const [skillList, setSkillList] = useState();
  console.log(skillList, "skill114")

  //getSkillsData
  const getSkillList = () => {
    dispatch(getAgencySkillsData()).then((res) => {
      if (res?.payload?.status == 200) {
        setSkillList(res?.payload?.data);
      }
    });
  };

  // get job type list
  const getJobTypeList = () => {
    dispatch(getJobTypeAgency()).then((res) => {
      if (res?.payload?.status == 200) {
        setJobTypeList(res?.payload?.data);
      }
    });
  };

  // get single Officer Detail for update
  const getSingleOfficerDeatil = () => {
    setUpdateDataLoading(true);
    DataService.get(Api.Agency.GET_SINGLE_OFFICER + "/" + officerId)
      .then((response) => {
        if (response?.data?.status === 200) {
          setUpdateData(response?.data?.data);
          setTimeout(() => {
            setUpdateDataLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getSingleOfficerDeatil();
    getJobTypeList();
    // getZipCode();
    getSkillList();
  }, []);

  const initialValues = {
    firstName: updateData ? updateData?.firstName : "",
    lastName: updateData ? updateData?.lastName : "",
    email: updateData ? updateData?.email : "",
    mobileNumber: updateData ? updateData?.mobileNumber : "",
    category: updateData ? updateData?.category : "",
    // skills: updateData ? updateData?.skills : "",
    skills: updateData ? updateData?.skills : [],
    rank: updateData ? updateData?.rank : "",
    // badgeNumber: updateData ? updateData?.badgeNumber : "",
    rate: updateData ? updateData?.rate : "",
    // zipCode: updateData ? updateData?.zipCode : "",
    zipCode: agencyLoginData ? agencyLoginData?.zipCode : "",
    yearsOnDuty: updateData ? updateData?.yearsOnDuty : "",
    monthsOnDuty: updateData ? updateData?.monthsOnDuty : "",
    jobType: updateData ? updateData?.jobType : [],
    days: updateData
      ? updateData?.days
      : [
        { isActive: false, day: "All", startTime: "", endTime: "" },
        { isActive: false, day: "Monday", startTime: "", endTime: "" },
        { isActive: false, day: "Tuesday", startTime: "", endTime: "" },
        { isActive: false, day: "Wednesday", startTime: "", endTime: "" },
        { isActive: false, day: "Thursday", startTime: "", endTime: "" },
        { isActive: false, day: "Friday", startTime: "", endTime: "" },
        { isActive: false, day: "Saturday", startTime: "", endTime: "" },
        { isActive: false, day: "Sunday", startTime: "", endTime: "" },
      ],
  };

  const handleSubmitEdit = (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    if (officerId) {
      urlEncoded.append("id", officerId);
    }
    urlEncoded.append("firstName", values?.firstName);
    urlEncoded.append("lastName", values?.lastName);
    urlEncoded.append("email", values?.email.toLowerCase());
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    urlEncoded.append("rate", values?.rate);
    urlEncoded.append("rank", values?.rank);
    // urlEncoded.append("jobType", values?.jobType);
    urlEncoded.append("zipCode", values?.zipCode);
    // urlEncoded.append("badgeNumber", values?.badgeNumber);
    urlEncoded.append("yearsOnDuty", values?.yearsOnDuty ?? 0);
    urlEncoded.append("monthsOnDuty", values?.monthsOnDuty ?? 0);
    // urlEncoded.append("skills", JSON.stringify(values?.skills));
    for (let i = 0; i < values.skills.length; i++) {
      urlEncoded.append(`skills[${i}]`, values.skills[i]);
    }
    for (let i = 0; i < values.jobType.length; i++) {
      urlEncoded.append(`jobType[${i}]`, values.jobType[i]._id);
    }
    urlEncoded.append("days", JSON.stringify(values?.days));

    PagesIndex.DataService.post(
      PagesIndex.Api.Admin.UPDATE_OFFICER,
      urlEncoded
    ).then(({ data }) => {
      PagesIndex.toast.success(data.message);
      setIsLoading(false);
      navigate(`/${roleName}/officer-management`, { state: "ACCEPTED" });
    }).catch((err) => {
      PagesIndex.toast.error(err.response.data.message);
    });
  };

  const handleSelectCheckAll = (e, setFieldValue, setTouched) => {
    const isChecked = e.target.checked;
    setDayCheckAll(isChecked);
    updateData.days.forEach((_, index) => {
      setFieldValue(`days[${index}].isActive`, isChecked, false);
      if (!isChecked) {
        setFieldValue(`days[${index}].startTime`, "", false);
        setFieldValue(`days[${index}].endTime`, "", false);
        setTouched({
          [`days[${index}].startTime`]: false,
          [`days[${index}].endTime`]: false,
        });
      } else {
        setTouched({
          [`days[${index}].startTime`]: true,
          [`days[${index}].endTime`]: true,
        });
      }
    });

    const allSelectData = updateData?.days?.map((c) => c?._id);
    setSelectProducts(isChecked ? allSelectData : []);
  };



  return (
    <>
      {!updateDataLoading ? (
        <>
          <Index.Box className="user-list-flex"
          // onClick={() =>
          //   //  navigate(-1)
          //    navigate(`/${roleName}/officer-management`, { state: location?.state?.tabValue})
          //   }
          >
            <Index.Typography
              className="admin-page-title user-list-page-title"
              component="h3"
              variant="h3"
            >
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
                onClick={() =>
                  //  navigate(-1)
                  navigate(`/${roleName}/officer-management`, { state: location?.state?.tabValue })
                }
              ></img>
              Edit Details
            </Index.Typography>
          </Index.Box>

          <PagesIndex.Formik
            enableReinitialize={true}
            validationSchema={editOfficerDetailSchema}
            initialValues={initialValues}
            onSubmit={handleSubmitEdit}
            innerRef={formik}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              setTouched
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log("error", errors)}
                {console.log("value", values)}
           
                                       
                <Index.Box className="common-card">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              First Name
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter first name"
                                name="firstName"
                                value={values.firstName}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("firstName", true);
                                }}
                                inputProps={{ maxLength: 15 }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.value.trim() === ""
                                  ) {
                                    e.preventDefault(); // Prevent space as the first character
                                  } else if (
                                    e.target.value.trim().split(" ").length >=
                                    2 &&
                                    e.key === " "
                                  ) {
                                    e.preventDefault(); // Prevent adding additional spaces between words
                                  } else if (
                                    e.key === " " &&
                                    e.target.value.endsWith(" ")
                                  ) {
                                    e.preventDefault(); // Prevent additional spaces at the end
                                  }
                                  if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                    e.preventDefault(); // Prevent non-numeric characters
                                  }
                                }}
                                // onBlur={handleBlur}
                                focused={false}
                                error={
                                  errors.firstName && touched.firstName
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.firstName && touched.firstName
                                    ? errors.firstName
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Last Name
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="lastname"
                                placeholder="Please enter last name"
                                className="form-control"
                                name="lastName"
                                value={values.lastName}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("lastName", true);
                                }}
                                inputProps={{ maxLength: 15 }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.value.trim() === ""
                                  ) {
                                    e.preventDefault(); // Prevent space as the first character
                                  } else if (
                                    e.target.value.trim().split(" ").length >=
                                    2 &&
                                    e.key === " "
                                  ) {
                                    e.preventDefault(); // Prevent adding additional spaces between words
                                  } else if (
                                    e.key === " " &&
                                    e.target.value.endsWith(" ")
                                  ) {
                                    e.preventDefault(); // Prevent additional spaces at the end
                                  }
                                  if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                    e.preventDefault(); // Prevent non-numeric characters
                                  }
                                }}
                                // onBlur={handleBlur}
                                focused={false}
                                error={
                                  errors.lastName && touched.lastName
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.lastName && touched.lastName
                                    ? errors.lastName
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Email Address
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="email"
                                className="form-control"
                                placeholder="Please enter email address"
                                name="email"
                                value={values.email}
                                onChange={(e) => {
                                  handleChange(e);
                                  // setFieldTouched("email", true);
                                }}
                                onKeyDown={(event) => {
                                  if (event.key == " ") {
                                    event.preventDefault();
                                  }
                                }}
                                inputProps={{ maxLength: 60 }}
                                onBlur={handleBlur}
                                error={
                                  errors.email && touched.email ? true : false
                                }
                                helperText={
                                  errors.email && touched.email
                                    ? errors.email
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Phone Number
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                // type="tel"
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter phone number"
                                name="mobileNumber"
                                value={values?.mobileNumber}
                                focused={false}
                                onChange={(e) => {
                                  handlePhoneChange(e, setFieldValue);
                                }}
                                inputProps={{ maxLength: 10 }}
                                error={
                                  errors.mobileNumber && touched.mobileNumber
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.mobileNumber && touched.mobileNumber
                                    ? errors.mobileNumber
                                    : false
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Add Zip Code
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter your first name"
                                name="zipCode"
                                disabled
                                value={values.zipCode}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("zipCode", true);
                                }}
                                onBlur={handleBlur}
                                error={
                                  errors.zipCode && touched.zipCode
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.zipCode && touched.zipCode
                                    ? errors.zipCode
                                    : null
                                }
                              />
                            </Index.Box>
                            {touched.zipCode && errors.zipCode && (
                              <Index.FormHelperText error>
                                {errors.zipCode}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Select Skill
                            </Index.FormHelperText>
                            <Index.Box className="dropdown-box">
                              <Index.FormControl className="form-control drop-form-control">
                                <Index.Autocomplete
                                  className="dropdown-select drop-select"
                                  multiple
                                  // options={["Skill1", "Skill2", "Skill3"]}
                                  options={
                                    skillList
                                      ? skillList.map((item) => item.skill)
                                      : []
                                  }
                                  getOptionLabel={(option) => option}
                                  value={values?.skills}
                                  disableCloseOnSelect
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => {
                                    return (
                                      <Index.MenuItem
                                        key={option}
                                        value={option}
                                        sx={{ justifyContent: "space-between" }}
                                        {...props}
                                      >
                                        <Index.ListItemText>
                                          {option}
                                        </Index.ListItemText>
                                        {selected ? <Index.Check /> : null}
                                      </Index.MenuItem>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      {...params}
                                      error={
                                        errors.skills && touched.skills
                                          ? true
                                          : false
                                      }
                                      placeholder={
                                        values?.skills?.length === 0
                                          ? "Please select skill"
                                          : ""
                                      }
                                    />
                                  )}
                                  onChange={(e, val) => {
                                    let idArray = [];
                                    val?.map((item) => {
                                      idArray.push(item);
                                    });
                                    setFieldValue("skills", idArray);
                                  }}
                                />
                              </Index.FormControl>
                              {touched.skills && errors.skills && (
                                <Index.FormHelperText error>
                                  {errors.skills}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              {" "}
                              Rank
                            </Index.FormHelperText>
                            <Index.Box className="dropdown-box">
                              <Index.FormControl className="form-control drop-form-control">
                                <Index.Select
                                  className="dropdown-select drop-select"
                                  displayEmpty
                                  name="rank"
                                  disabled
                                  value={values?.rank}
                                  onChange={(e) => {
                                    setFieldValue("rank", e?.target?.value);
                                  }}
                                  error={
                                    errors.rank && touched.rank ? true : false
                                  }
                                  renderValue={
                                    values?.rank
                                      ? undefined
                                      : () => "Select Rank"
                                  }
                                >
                                  {officerList.length > 0
                                    ? officerList.map((data, index) => {
                                      return (
                                        <Index.MenuItem
                                          key={index}
                                          value={data?.name}
                                          className="drop-menuitem"
                                        >
                                          {data?.name}
                                        </Index.MenuItem>
                                      );
                                    })
                                    : null}
                                </Index.Select>
                              </Index.FormControl>
                            </Index.Box>
                            {touched.rank && errors.rank && (
                              <Index.FormHelperText error>
                                {errors.rank}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Required Pay Rate
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter required pay rate"
                                name="rate"
                                inputProps={{ maxLength: 5 }}
                                value={values?.rate}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    ONLY_NUMBER_NOT_SPACE_SPACIALCHAR,
                                    ""
                                  );
                                  setFieldValue("rate", newValue);
                                  setFieldTouched("rate", true);
                                }}
                                // onBlur={handleBlur}
                                focused={false}
                                error={
                                  errors.rate && touched.rate ? true : false
                                }
                                helperText={
                                  errors.rate && touched.rate
                                    ? errors.rate
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              TOD (Time on Duty) :
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.Box className="double-dd-wrape">
                                <Index.Box className="dd-section">
                                  <Index.Select
                                    className="dropdown-select drop-select"
                                    // value={todyear}
                                    name="yearsOnDuty"
                                    value={values.yearsOnDuty}
                                    // onChange={handleChangetodyear}
                                    onChange={handleChange}
                                    placeholder="Please enter years"
                                  >
                                    <Index.MenuItem
                                      value={1}
                                      className="drop-menuitem"
                                    >
                                      1
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={2}
                                      className="drop-menuitem"
                                    >
                                      2
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={3}
                                      className="drop-menuitem"
                                    >
                                      3
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={4}
                                      className="drop-menuitem"
                                    >
                                      4
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={5}
                                      className="drop-menuitem"
                                    >
                                      5
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={6}
                                      className="drop-menuitem"
                                    >
                                      6
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={7}
                                      className="drop-menuitem"
                                    >
                                      7
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={8}
                                      className="drop-menuitem"
                                    >
                                      8
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={9}
                                      className="drop-menuitem"
                                    >
                                      9
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={10}
                                      className="drop-menuitem"
                                    >
                                      10
                                    </Index.MenuItem>
                                  </Index.Select>
                                  <Index.Typography
                                    component="span"
                                    className="form-lable"
                                  >
                                    Years
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="dd-section">
                                  <Index.Select
                                    className="dropdown-select drop-select"
                                    // value={todmonth}
                                    name="monthsOnDuty"
                                    value={values.monthsOnDuty}
                                    // onChange={handleChangetodmonth}
                                    onChange={handleChange}
                                    placeholder="Please enter months"
                                  >
                                    <Index.MenuItem value={1}>1</Index.MenuItem>
                                    <Index.MenuItem value={2}>2</Index.MenuItem>
                                    <Index.MenuItem value={3}>3</Index.MenuItem>
                                    <Index.MenuItem value={4}>4</Index.MenuItem>
                                    <Index.MenuItem value={5}>5</Index.MenuItem>
                                    <Index.MenuItem value={6}>6</Index.MenuItem>
                                    <Index.MenuItem value={7}>7</Index.MenuItem>
                                    <Index.MenuItem value={8}>8</Index.MenuItem>
                                    <Index.MenuItem value={9}>9</Index.MenuItem>
                                    <Index.MenuItem value={10}>
                                      10
                                    </Index.MenuItem>
                                    <Index.MenuItem value={11}>
                                      11
                                    </Index.MenuItem>
                                    <Index.MenuItem value={12}>
                                      12
                                    </Index.MenuItem>
                                  </Index.Select>
                                  <Index.Typography
                                    component="span"
                                    className="form-lable"
                                  >
                                    Months
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              {touched.monthsOnDuty && errors.monthsOnDuty && (
                                <Index.FormHelperText error>
                                  {errors.monthsOnDuty}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              {" "}
                              Job Type{" "}
                            </Index.FormHelperText>
                            <Index.Box className="dropdown-box">
                              <Index.FormControl className="form-control drop-form-control">
                                {/* <Index.Select
                                  className="dropdown-select drop-select"
                                  displayEmpty
                                  name="jobType"
                                  value={values?.jobType}
                                  onChange={(e) => {
                                    setFieldValue("jobType", e?.target?.value);
                                  }}
                                  error={
                                    errors.jobType && touched.jobType
                                      ? true
                                      : false
                                  }
                                >
                                  {jobTypeList?.map((data, index) => {
                                    return (
                                      <Index.MenuItem
                                        key={index}
                                        value={data?._id}
                                        className="drop-menuitem"
                                      >
                                        {data?.jobType}
                                      </Index.MenuItem>
                                    );
                                  })}
                                </Index.Select> */}
                                <Index.Autocomplete
                                  className="dropdown-select drop-select"
                                  multiple
                                  options={
                                    jobTypeList
                                      ? jobTypeList.map((item) => ({ _id: item?._id, jobType: item?.jobType }))
                                      : []
                                  }
                                  getOptionLabel={(option) => option?.jobType}
                                  value={values?.jobType}
                                  disableCloseOnSelect
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => {
                                    return (
                                      <Index.MenuItem
                                        key={option?._id}
                                        value={option?._id}
                                        sx={{
                                          justifyContent: "space-between",
                                          fontSize: "12px",
                                        }}
                                        {...props}
                                      >
                                        <Index.ListItemText>
                                          {option?.jobType}
                                        </Index.ListItemText>
                                        {values?.jobType?.find((item) => item._id == option?._id) ? <Index.Check /> : null}
                                        {/* {selected ? <Index.Check /> : null} */}
                                      </Index.MenuItem>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      {...params}
                                      error={
                                        errors.jobType && touched.jobType
                                          ? true
                                          : false
                                      }
                                      placeholder={
                                        values?.jobType?.length === 0
                                          ? "Please select job type"
                                          : ""
                                      }
                                    />
                                  )}
                                  onChange={(e, val, reason, details) => {
                                    if (reason === 'selectOption' || reason === 'removeOption') {
                                      const clickedOption = details.option;
                                      const newValue = values.jobType.some(item => item._id === clickedOption._id)
                                        ? values.jobType.filter(item => item._id !== clickedOption._id)
                                        : [...values.jobType, clickedOption];
                                      setFieldValue("jobType", newValue);
                                    } else {
                                      setFieldValue("jobType", val);
                                    }
                                  }}
                                />

                              </Index.FormControl>
                            </Index.Box>
                            {touched.jobType && errors.jobType && (
                              <Index.FormHelperText error>
                                {errors.jobType}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="admin-sub-title-main available-title-flex">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      Available
                    </Index.Typography>
                    <Index.FormControlLabel
                      className="admin-officer-checkbox"
                      control={
                        <Index.Checkbox
                          checked={
                            isDayCheck
                          }
                          onChange={(e) => {
                            handleSelectCheckAll(e, setFieldValue, setTouched, touched);
                          }}
                        />
                      }
                      label="All"
                    />
                  </Index.Box>
                  <Index.Box className="common-card">
                    <Index.Box className="card-body">
                      {values?.days?.map((row, index) => (
                        <>
                          <Index.Box className="grid-row">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                key={index}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 4",
                                    lg: "span 2",
                                  }}
                                  className="grid-column"
                                >
                                  {/* { console.log("112",row)} */}
                                  {console.log(values, row?.isActive, isDayCheck, "values1")}
                                  {/* {console.log(touched, "touched")}
                                  {console.log(values, "values1")}
                                  {console.log(errors, "errors")} */}


                                  {/* {row.day === "all"
                                    ? (() => {
                                      // When "all" day is active, set all days to active
                                      if (row.isActive) {
                                        values.days.forEach((_, i) => {
                                          setFieldValue(`days[${i}].isActive`, true, false);
                                        });
                                      }
                                    })()
                                    : setDayCheckAll(values.days.every((day) => day.isActive))} */}

                                  {setDayCheckAll(values.days.every((day, i) => day.isActive))}
                                  {/* 
                                  {
                                    row.day === "all" ? values.days.forEach((_, i) => {
                                      setFieldValue(`days[${index}].isActive`, true, false);
                                    }) :
                                      setDayCheckAll(values.days.every((day, i) => day.isActive))
                                  } */}
                                  <div className="input-box">
                                    <Index.FormHelperText className="form-lable"></Index.FormHelperText>
                                    <Index.Box className="input-switch-flex">
                                      <Index.FormHelperText className="form-lable input-switch-lable">
                                        {row?.day}
                                      </Index.FormHelperText>
                                      <Index.Switch
                                        checked={row.day === "all" ? values.days.every(day => day.isActive) : row.isActive}
                                        onChange={(e) => {
                                          // const isActive = e.target.checked;
                                          console.log("1170", row.day === "all", row)
                                          if (row.day === "all") {
                                            handleSelectCheckAll(e, setFieldValue, setTouched, touched);
                                          } else {
                                            // console.log("1174",days[${index}])
                                            setFieldValue(`days[${index}].isActive`, e.target.checked, false);
                                            if (!e.target.checked) {
                                              setFieldValue(`days[${0}].startTime`, "", false);
                                              setFieldValue(`days[${0}].endTime`, "", false);

                                              setFieldValue(`days[${index}].startTime`, "", false);
                                              setFieldValue(`days[${index}].endTime`, "", false);
                                              setTouched({
                                                [`days[${index}].startTime`]: false,
                                                [`days[${index}].endTime`]: false,
                                              });
                                            } else {
                                              setTouched({
                                                [`days[${index}].startTime`]: true,
                                                [`days[${index}].endTime`]: true,
                                              });
                                            }
                                          }
                                          // console.log("11933", values)
                                          // setFieldValue(`days[${index}].isActive`, e.target.checked, false);
                                          // if (!e.target.checked) {
                                          //   setFieldValue(`days[${index}].startTime`, "", false);
                                          //   setFieldValue(`days[${index}].endTime`, "", false);
                                          //   setTouched({
                                          //     [`days[${index}].startTime`]: false,
                                          //     [`days[${index}].endTime`]: false,
                                          //   });
                                          // } else {
                                          //   setTouched({
                                          //     [`days[${index}].startTime`]: true,
                                          //     [`days[${index}].endTime`]: true,
                                          //   });
                                          // }
                                        }}
                                      ></Index.Switch>
                                    </Index.Box>
                                  </div>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 4",
                                    lg: "span 2",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="form-lable ">
                                      From
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pwd-icon-btn time-form-group">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          className="text-center"
                                          components={[
                                            "MobileTimePicker",
                                            "MobileTimePicker",
                                            "MobileTimePicker",
                                          ]}
                                        >
                                          <TimePicker
                                            className="form-control"
                                            disabled={
                                              !values.days[index]?.isActive
                                            }
                                            maxTime={
                                              values.days[index].endTime
                                                ? dayjs(
                                                  `2023-12-25T${values.days[index].endTime}`
                                                )
                                                : null
                                            }
                                            ampm={false}
                                            value={
                                              values.days[index].startTime
                                                ? dayjs(
                                                  `2023-12-25T${values.days[index].startTime}`
                                                )
                                                : null
                                            }
                                            views={["hours", "minutes"]}
                                            name={`days[${index}].startTime`}
                                            defaultValue={dayjs(
                                              `2023-12-25T${row?.startTime}`
                                            )}
                                            onChange={(e) => {
                                              const formatedTime = moment(
                                                e?.$d
                                              ).format("HH:mm");

                                              if (row.day === "all") {
                                                values.days.forEach((_, i) => {
                                                  setFieldValue(`days[${i}].startTime`, formatedTime, true);
                                                });
                                              } else {
                                                setFieldValue(
                                                  `days[${index}].startTime`,
                                                  formatedTime,
                                                  true
                                                );

                                              }


                                            }}
                                            slotProps={{
                                              textField: { readOnly: true },
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                      {errors.days && errors.days[index]?.startTime && touched.days && touched.days[index]?.startTime && (
                                        <Index.FormHelperText error className="error-text">
                                          {errors.days[index].startTime}
                                        </Index.FormHelperText>
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 4",
                                    lg: "span 2",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="form-lable">
                                      To
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pwd-icon-btn time-form-group">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={[
                                            "MobileTimePicker",
                                            "MobileTimePicker",
                                            "MobileTimePicker",
                                          ]}
                                        >
                                          <TimePicker
                                            className="form-control"
                                            disabled={
                                              !values.days[index]?.isActive
                                            }
                                            minTime={dayjs(
                                              `2023-12-25T${values.days[index].startTime}`
                                            )}
                                            ampm={false}
                                            views={["hours", "minutes"]}
                                            name={`days[${index}].endTime`}
                                            defaultValue={dayjs(
                                              `2023-12-25T${row.endTime}`
                                            )}
                                            value={
                                              values.days[index].endTime
                                                ? dayjs(
                                                  `2023-12-25T${values.days[index].endTime}`
                                                )
                                                : null
                                            }
                                            onChange={(e) => {
                                              const formatedTime = moment(
                                                e?.$d
                                              ).format("HH:mm");

                                              if (row.day === "all") {
                                                values.days.forEach((_, i) => {
                                                  setFieldValue(`days[${i}].endTime`, formatedTime, true);
                                                });
                                              } else {
                                                setFieldValue(
                                                  `days[${index}].endTime`,
                                                  formatedTime,
                                                  true
                                                );

                                              }


                                            }}
                                            slotProps={{
                                              textField: { readOnly: true },
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>

                                      {errors.days && errors.days[index]?.endTime && touched.days && touched.days[index]?.endTime && (
                                        <Index.FormHelperText error className="error-text">
                                          {errors.days[index].endTime}
                                        </Index.FormHelperText>
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </>
                      ))}
                    </Index.Box>
                  </Index.Box>

                <Index.Box
                  className="buttom-btn-wrape"
                  sx={{ marginTop: "15px" }}
                >
                  <Index.PrimaryButton
                    btnLabel="Update"
                    type="submit"
                    title="Update"
                    className="btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? <Loader /> : "Click Me"}
                  </Index.PrimaryButton>
                  <Index.PrimaryButton
                    btnLabel="Cancel"
                    title="Cancel"
                    className="btn-primary bg-suspend"
                    onClick={() => navigate(`/${roleName}/officer-management`, { state: "ACCEPTED" })}

                  />
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </>
      ) : null}
    </>
  );
};
export default AgencyEditOfficerDetail;

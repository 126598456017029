import React, { useState } from "react";
import Index from "../../../index";
import PagesIndex from "../../../PageIndex";
import { SwipeableDrawer } from "@mui/material";
import AddAdminAgentModal from "./admin-agent-modal/AddAdminAgentModal";
import EditAdminAgentModal from "./admin-agent-modal/EditAdminAgentModal";
import AdminAgentDeleteModal from "./admin-agent-modal/AdminAgentDeleteModal";
import AdminAgentRestrict from "./admin-agent-modal/AdminAgentRestrict";
import { getAgencyList } from "../../../../redux-toolkit/slice/common-slice/CommonServices";
import Loader from "../../../../common/loader/Loader";
import ExcelExportHelper from "../../../../common/ExcelExportHelper/ExcelExportHelper";

export default function AgentManagement() {
  const [isActive, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleMenuClass = () => {
    setActive(!isActive);
  };

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const agencyName = PagesIndex.useLocation();

  const [agentListData, setAgentListData] = useState(false);
  const [editAgentData, setEditAgentData] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  //Getting data from redux
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const roleName = adminLoginData?.roleId?.Permission_name;

  // add user modal
  const [addAgentModalopen, setAddAgentModalOpen] = React.useState(false);
  const addAgentModalhandleOpen = () => setAddAgentModalOpen(true);
  const addAgentModalhandleClose = () => setAddAgentModalOpen(false);

  // edit modal
  // const [activeCity, setActiveCity] = useState(false);
  const [EditAgentModalopen, setEditAgentModalOpen] = React.useState(false);
  const handleEditAgentModalOpenEdit = (item) => {
    setEditAgentData(item);
    setEditAgentModalOpen(true);
  };
  const handleEditAgentModalCloseEdit = () => setEditAgentModalOpen(false);

  // delete modal
  const [deleteAgentId, setDeleteAgentId] = useState("");
  const [timeAgentData, setTimeAgentData] = useState("");
  const [deleteAgentModalopen, setDeleteAgentModalopen] = React.useState(false);
  const deleteAgenthandleOpen = (id) => {
    setDeleteAgentId(id);
    setDeleteAgentModalopen(true);

  };
  const someOtherFunction = (item) => {
    setTimeAgentData(item);
    setDeleteAgentModalopen(true);

  };
  const deleteAgenthandleClose = () => setDeleteAgentModalopen(false);

  // Restrict modal
  const [restrictAgentStatus, setRestrictAgentStatus] = useState(false);
  const [restrictAgentModalopen, setRestrictAgentModalOpen] = useState(false);
  const [itmeData, setItemData] = useState([])
  const restrictAgenthandleOpen = (item, agentId, status) => {
    const data = {
      agentId: agentId,
      status: !status,
    };
    setItemData(item);
    setRestrictAgentModalOpen(true);
    setRestrictAgentStatus(data);
  };
  const restrictAgenthandleClose = () => {
    setRestrictAgentModalOpen(false);
  };

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [showExport, setShowExport] = useState(false);
  const [exportData, setExportData] = useState([]);


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // get Agnecy List
  const getAgentListData = (isdepartmentFilter = null) => {
    console.log("api calling", isdepartmentFilter)
    setLoading(true);
    dispatch(getAgencyList()).then((res) => {
      if (res?.payload?.status == 200) {
        setAgentListData(res?.payload?.data);
        setFilterList(res?.payload?.data);
        if (isdepartmentFilter) {
          // console.log("isdepartmentFilter", isdepartmentFilter)
          let _filterData = res?.payload?.data.filter((data) => data.departmentName.toLowerCase() == isdepartmentFilter)
          setAgentListData(_filterData)
          setFilterList(_filterData);
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  console.log("123", agencyName);

  PagesIndex.useEffect(() => {
    getAgentListData(agencyName?.state?.agencyType !== undefined ? agencyName?.state?.agencyType : null);
  }, [agencyName]);

  const handleExportClick = () => {
    const filteredListOfUser = filterList.map(user => ({
      // console.log("user",user.zipCode),
      agencyName: user?.agencyName,
      departmentName: user?.departmentName,
      email: user?.email,
      mobileNumber: user?.mobileNumber,
      state: user?.state,
      city: user?.city,
      address: user?.address,
      zipCode: user?.zipCode[0].split(',')[0],
      isPaymentCompleted: user?.isPaymentCompleted,
      status: user?.status
    }));
    // console.log("filteredListOfUser",filteredListOfUser)
    setExportData(filteredListOfUser);
    setShowExport(true);
  };



  PagesIndex.useEffect(() => {
    if (exportData.length > 0) {
      setShowExport(false);
    }
  }, [exportData, showExport]);


  // Agent  search
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filteredList = agentListData.filter((data) =>
      `${data.agencyName.toLowerCase()} 
      ${data.mobileNumber} 
      ${data.zipCode}
      ${data.email.toLowerCase()}`.includes(e.target.value.toLowerCase())
    );
    setFilterList(filteredList);
  };
  // // US Formate
  // const formatPhoneNumber = (input) => {
  //   // Remove all non-digit characters from the input
  //   const cleaned = ("" + input).replace(/\D/g, "");

  //   // Format the cleaned phone number
  //   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return "(" + match[1] + ") " + match[2] + "-" + match[3];
  //   }
  //   return cleaned;
  // };
  return (
    <>
      <div className="agent-management-page-wrape">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Index.Box className="user-list-flex">
              <Index.Typography
                className="admin-page-title user-list-page-title"
                component="h2"
                variant="h2"
              >
                <Index.Link to="/admin/dashboard" className="filter-back-link">
                  <img
                    src={PagesIndex.Svg.leftArrow}
                    className="left-arrow-ic"
                    alt="search grey img"
                  ></img>
                </Index.Link>{" "}
                Agency/Department Management
              </Index.Typography>

              {/* top Search */}
              <Index.Box className="userlist-btn-flex btn-main-primary">

                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => {
                    handleExportClick();
                  }}
                >
                  <img
                    src={PagesIndex.Svg.exports}
                    alt=""
                    className="down-icon"
                  />{" "}
                  Exports
                </Index.Button>

                {!showExport && exportData?.length > 0 ? (
                  <>
                    <Index.Box className="common-button grey-button">
                      <ExcelExportHelper
                        data={exportData}
                        // loading={loading}
                        sheetName={"agencyDepartment_management"}
                        setExportData={setExportData}
                      />
                    </Index.Box>
                  </>
                ) : (
                  <></>
                )}

                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={handleSearch}
                        onKeyDown={(e) => {
                          if (
                            e.key === " " &&
                            e.target.value.trim() === ""
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                {/* top filter */}
                {/* <Index.Box className="filter-main">
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Index.Box className="export-btn-main border-btn-main">
                        <Index.Button
                          className="export-btn border-btn"
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <img
                            src={PagesIndex.Svg.filter}
                            className="down-icon"
                            alt="download icon"
                          />
                          Filter
                        </Index.Button>
                      </Index.Box>
                      <SwipeableDrawer
                        className="filter-main"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        <Index.Box className="filter-header">
                          <Index.Typography className="filter-title">
                            User Filter
                          </Index.Typography>
                          <img
                            src={PagesIndex.Svg.closeicon}
                            className="filter-close-icon"
                            onClick={toggleDrawer(anchor, false)}
                            alt=""
                          />
                        </Index.Box>
                        <Index.Box className="filter-inner-main">
                          <Index.Box className="input-box filter-input-box">
                            <Index.FormHelperText className="form-lable">
                              Name
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder=""
                              />
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="input-box filter-input-box">
                            <Index.FormHelperText className="form-lable">
                              City
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder=""
                              />
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="input-box filter-input-box">
                            <Index.FormHelperText className="form-lable">
                              Status
                            </Index.FormHelperText>
                            <Index.Box className="checkbox-main filter-checkbox-main input-box">
                              <Index.FormControlLabel
                                control={<Index.Checkbox defaultChecked />}
                                label="Active"
                                className="checkbox-lable"
                              />
                              <Index.FormControlLabel
                                control={<Index.Checkbox />}
                                label="Deactivate"
                                className="checkbox-lable"
                              />
                              <Index.FormControlLabel
                                control={<Index.Checkbox />}
                                label="Pending"
                                className="checkbox-lable"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-footer">
                          <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                            <Index.Button className="border-btn filter-cancel-btn">
                              Cancel
                            </Index.Button>
                            <Index.Button className="btn-primary filter-btn">
                              Filter
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </SwipeableDrawer>
                    </React.Fragment>
                  ))}
                </Index.Box> */}
                <Index.Box className="userlist-inner-btn-flex">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    {roleName === "Admin" ||
                      SubAdminPermission?.Agency?.permission?.add == true ? (
                      <Index.Button
                        className="adduser-btn btn-primary"
                        onClick={addAgentModalhandleOpen}
                      >
                        <img
                          src={PagesIndex.Svg.plus}
                          className="plus-icon"
                          alt="plus icon"
                        />
                        Add Agency
                      </Index.Button>
                    ) : null}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* Agency List */}

            <>
              {filterList.length > 0 ? (
                filterList.map((item, index) => (
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="officer-profile-flex">
                        <img
                          // src={PagesIndex.Png.usericon}
                          src={
                            item?.image
                              ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                              : PagesIndex.Png.usericon
                          }
                          className="prof-img"
                          alt=""
                        />
                        <Index.Box className="officer-detail">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="officer-detail-title"
                          >
                            {item?.agencyName}
                            {item?.departmentName == "Agency" ? (
                              <Index.Box className="admin-list-pd-btn-main">
                                <Index.PrimaryButton
                                  btnLabel="SA"
                                  className="admin-list-pd-btn"
                                />
                              </Index.Box>
                            ) : (
                              <Index.Box className="admin-list-pd-btn-main">
                                <Index.PrimaryButton
                                  btnLabel="PD"
                                  className="admin-list-pd-btn"
                                />
                              </Index.Box>
                            )}
                          </Index.Typography>

                          <Index.Typography
                            component="p"
                            variant="p"
                            className="officer-detail-email"
                          >
                            {item?.email}
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="officer-detail-numebr"
                          >
                            {item?.mobileNumber}
                          </Index.Typography>

                          <Index.Typography
                            component="p"
                            variant="p"
                            className="officer-detail-numebr"
                          >
                            {item?.roleId?.role}
                          </Index.Typography>
                          {item?.isApprove == true ? (
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="officer-detail-numebr danger-text"
                              sx={{ fontWeight: "bold" }}
                            >
                              Restricted
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right btn-main-secondary btn-main-red btn-main-orange btn-main-yellow btn-main-green">


                      <Index.Box className="mobile-more-btn">

                        <Index.Box
                          id="btn-toggle-popup"
                          className={
                            isActive ? "toggle-pop-show" : null
                          }
                        >
                          {roleName === "Admin" ||
                            SubAdminPermission?.Agency?.permission?.read ==
                            true ? (
                            <Index.Tooltip
                              title="View"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <Index.Button
                                btnLabel="View"
                                className="btn-orange"
                                onClick={() =>
                                  navigate(`/admin/agentmanagement/adminagentdetails/${item?._id}`,
                                    {
                                      state: {
                                        agentDetails: item,
                                        tabValue: "TotalOfficer"
                                      }
                                    }
                                  )
                                }
                              >
                                <img src={PagesIndex.Svg.eyeIcon} alt="delete" className="admin-icons" />
                              </Index.Button>
                            </Index.Tooltip>
                          ) : null}

                          {roleName === "Admin" ||
                            SubAdminPermission?.Agency?.permission
                              ?.Suspend == true ? (
                            <>
                              {item?.isApprove == true ? (
                                <>
                                  <Index.Tooltip
                                    title="Active"
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                  >
                                    <Index.Button
                                      onClick={() =>
                                        restrictAgenthandleOpen(
                                          item,
                                          item?._id,
                                          item?.isApprove
                                        )
                                      }
                                      btnLabel={
                                        item?.isApprove
                                          ? "Activate"
                                          : "Restrict"
                                      }
                                      // className={
                                      //   item?.isApprove
                                      //     ? "btn-red bg-active"
                                      //     : "btn-green bg-suspend"
                                      // }
                                      className={`${item.isApprove === "ACTIVATE"
                                        ? "btn-red bg-suspend"
                                        : "btn-green bg-active"
                                        } btn-red`}
                                    >
                                      {/* <img src={PagesIndex.Svg.prohibitionIcon} alt="delete" className="admin-icons" /> */}
                                      {
                                        <img src={PagesIndex.Svg.checkMark} alt="CheckMark" className="admin-icons checkmark-icon" />
                                      }
                                    </Index.Button>
                                  </Index.Tooltip>
                                </>
                              ) : (
                                <>
                                  <Index.Tooltip
                                    title="Restrict"
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                  >
                                    <Index.Button
                                      onClick={() =>
                                        restrictAgenthandleOpen(
                                          item,
                                          item?._id,
                                          item?.isApprove
                                        )
                                      }
                                      btnLabel={
                                        item?.isApprove
                                          ? "Activate"
                                          : "Restrict"
                                      }
                                      className={
                                        item?.isApprove
                                          ? "btn-green bg-active"
                                          : "btn-red bg-suspend"
                                      }
                                    >
                                      {
                                        <img src={PagesIndex.Svg.prohibitionIcon} alt="Prohibition" className="admin-icons" />
                                      }

                                    </Index.Button>
                                  </Index.Tooltip>
                                </>
                              )}
                            </>
                          ) : null}



                          {roleName === "Admin" ||
                            SubAdminPermission?.Agency?.permission
                              ?.edit == true ? (
                            <Index.Tooltip
                              title="Edit"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <Index.Button
                                btnLabel="Edit"
                                className="btn-secondary"
                                onClick={() =>
                                  handleEditAgentModalOpenEdit(item)
                                }
                              >
                                <img src={PagesIndex.Svg.editNew} alt="delete" className="admin-icons" />
                              </Index.Button>
                            </Index.Tooltip>
                          ) : null}

                          {roleName === "Admin" ||
                            SubAdminPermission?.Agency?.permission
                              ?.delete == true ? (
                            <Index.Tooltip
                              title="Delete"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <Index.Button
                                onClick={() => {
                                  deleteAgenthandleOpen(item?._id);
                                  // Add any other function calls or state updates here
                                  someOtherFunction({ state: item });
                                }}


                                btnLabel="delete"
                                className="btn-red"
                              >
                                <img src={PagesIndex.Svg.deletewhite} alt="delete" className="admin-icons" />
                              </Index.Button>
                            </Index.Tooltip>
                          ) : null}

                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                ))
              ) : (
                <PagesIndex.NoDataFound />
              )}
            </>
          </>
        )}

        {/* Add New Agent modal */}
        <AddAdminAgentModal
          getAgentListData={getAgentListData}
          addAgentModalopen={addAgentModalopen}
          addAgentModalhandleClose={addAgentModalhandleClose}
        />

        {/* EditDetails start */}
        <EditAdminAgentModal
          editAgentData={editAgentData}
          getAgentListData={getAgentListData}
          EditAgentModalopen={EditAgentModalopen}
          handleEditAgentModalCloseEdit={handleEditAgentModalCloseEdit}
        />

        {/* Restrict modal start */}
        <AdminAgentRestrict
          restrictAgentStatus={restrictAgentStatus}
          restrictAgentModalopen={restrictAgentModalopen}
          restrictAgenthandleClose={restrictAgenthandleClose}
          getAgentListData={getAgentListData}
          filterList={filterList}
          itmeData={itmeData}
        />

        {/* Delete modal start */}
        <AdminAgentDeleteModal
          deleteAgentId={deleteAgentId}
          deleteAgentModalopen={deleteAgentModalopen}
          deleteAgenthandleClose={deleteAgenthandleClose}
          getAgentListData={getAgentListData}
          timeAgentData={timeAgentData}
        />
      </div>
    </>
  );
}

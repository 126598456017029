import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

const AgencyJobHistoryView = () => {
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const roleName = agencyLoginData?.roleId?.Permission_name;
  const { state } = PagesIndex.useLocation();
  const location = PagesIndex.useLocation();
  const { officerId } = PagesIndex.useParams();
  const tab = location?.state?.tabValue
  const navigate = PagesIndex.useNavigate();
  const adminPendingjobsDetail = state.item;

  return (
    <>

      {/* New Code Design */}
      <Index.Box className="user-list-flex">
        <Index.Box className="title-header-flex"
          onClick={() =>
            navigate(`/${roleName}/job-history/${officerId}`, 
              { state:tab})
          } 
        >
          <img src={PagesIndex.Svg.leftArrow} 
            className="left-arrow-ic"
            alt="search grey img"
          />
          <Index.Box>
            <Index.Typography component="p" variant="p" className="admin-page-title user-list-page-title">
              {adminPendingjobsDetail?.title}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="admin-client-history-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            General Information
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Title :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.title}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted By :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex ">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(adminPendingjobsDetail?.createdAt).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Payment :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Transaction Id :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.mobileNumber}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Point Of Contact Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.pointOfContactName}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Point Of Contact Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {
                    adminPendingjobsDetail?.pointOfContactNumber || "-"
                  }
                </Index.Typography>
              </Index.Box>

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Location :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.location}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Service :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.service}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Department :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.department}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Type :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.jobType?.jobType}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Zip Code :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.zipCode}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Date/Time :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.jobSchedule?.map((item, index) => {
                    return (
                      <>
                        {/* {item.isActive == true ? ( */}
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          <span className="day-title">
                            {
                              // (item?.jobStartDate)
                              PagesIndex.dayjs(item?.jobStartDate).format(
                                "MM-DD-YYYY"
                              ) +
                              " to " +
                              PagesIndex.dayjs(item?.jobEndDate).format(
                                "MM-DD-YYYY"
                              )
                            }
                          </span>{" "}
                          -{" "}
                          <span>
                            {item?.jobStartTime
                              ? item?.jobStartTime + " to "
                              : null}
                            {item?.jobEndTime}
                          </span>
                        </Index.Typography>
                        {/* ):null} */}
                      </>
                    );
                  })}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Hours :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.timeDifferenceCalculation(adminPendingjobsDetail)} hour
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Pay Rate :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.rate ? "$" : null}
                  {adminPendingjobsDetail?.rate}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  No of Officer :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.totalOfficer}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Description :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.description}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="admin-client-history-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Organizer (Client)
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Mobile Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.userId?.mobileNumber}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="admin-client-history-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography component="p" variant="p" className="admin-sub-title">
            Job Log
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography component="p" variant="p" className="work-title-lable">
                  Job Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography component="p" variant="p" className="work-value-text">
                  {PagesIndex.dayjs(
                    adminPendingjobsDetail?.createdAt
                  ).format("MM-DD-YYYY")}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography component="p" variant="p" className="work-title-lable">
                  Pay Per hour:&nbsp;
                </Index.Typography>
                <Index.Typography component="p" variant="p" className="work-value-text">
                  ${adminPendingjobsDetail?.rate}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography component="p" variant="p" className="work-title-lable">
                  Total Hours :&nbsp;
                </Index.Typography>
                <Index.Typography component="p" variant="p" className="work-value-text">
                  {PagesIndex.timeDifferenceCalculation(adminPendingjobsDetail)} hour
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography component="p" variant="p" className="work-title-lable">
                  Total :&nbsp;
                </Index.Typography>
                <Index.Typography component="p" variant="p" className="work-value-text">
                  ${PagesIndex.TotalCalculateByTime(adminPendingjobsDetail, adminPendingjobsDetail.rate)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography component="p" variant="p" className="work-title-lable">
                  Check-in :&nbsp;
                </Index.Typography>
                <Index.Typography component="p" variant="p" className="work-value-text">
                  {adminPendingjobsDetail?.officerId[0]?.jobCheckInTime[0] && PagesIndex?.dayjs(adminPendingjobsDetail?.officerId[0]?.jobCheckInTime[0])?.isValid() ? PagesIndex?.dayjs(adminPendingjobsDetail?.officerId[0]?.jobCheckInTime[0])?.format("MM-DD-YYYY - HH:mm") : " Not checkin"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography component="p" variant="p" className="work-title-lable">
                  Check-out :&nbsp;
                </Index.Typography>
                <Index.Typography component="p" variant="p" className="work-value-text">
                  {adminPendingjobsDetail?.officerId[0]?.jobCheckOutTime[0] && PagesIndex?.dayjs(adminPendingjobsDetail?.officerId[0]?.jobCheckOutTime[0])?.isValid() ? PagesIndex?.dayjs(adminPendingjobsDetail?.officerId[0]?.jobCheckOutTime[0]).format("MM-DD-YYYY - HH:mm") : " Not checkout"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

    </>
  );
};

export default AgencyJobHistoryView;

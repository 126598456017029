import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import { Formik } from "formik";
import {
  officerManagement,
  jobManagement,
  extraWorkPermit,
  PaymentDetails,
  clientManagement,
  skillManagement,
  RatingAndReviewManagement,
  TransferJobManagement,
} from "../../../../../src/config/CommonRoleMaster";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addAgencyRoleMaster } from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";

const displayValue = (value) => {
  if (value === undefined || value === "") {
    return false;
  } else {
    return value;
  }
};
function EditAgency() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  // edit data
  let editData = state?.row;
  console.log(state, "state");
  const { Permission_name, AgencyPermission } = editData;
   //Getting data from redux
   const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const roleName = agencyLoginData?.roleId?.Permission_name;
  

  // Check All
  const [jobCheckAll, setJobCheckAll] = useState(false);
  const [extraWorkPermitCheckAll, setExtraWorkPermitCheckAll] = useState(false);
  const [paymentCheckAll, setPaymentCheckAll] = useState(false);
  const [officerCheckAll, setOfficerCheckAll] = useState(false);
  const [reviewCheckAll, setReviewCheckAll] = useState(false);
  const [clientCheckAll, setClientCheckAll] = useState(false);
  const [transferCheckAll, setTransferCheckAll] = useState(false);
  const [skillCheckAll, setSkillCheckAll] = useState(false);

  useEffect(() => {
    if (AgencyPermission?.Jobs?.permission?.All) {
      setJobCheckAll(true);
    }
    if (AgencyPermission?.Extra_Work_Premit?.permission?.All) {
      setExtraWorkPermitCheckAll(true);
    }
    if (AgencyPermission?.Client?.permission?.All) {
      setClientCheckAll(true);
    }
    if (AgencyPermission?.Officer?.permission?.All) {
      setOfficerCheckAll(true);
    }
    if (AgencyPermission?.rating?.permission?.All) {
      setReviewCheckAll(true);
    }
    if (AgencyPermission?.job_Transfer?.permission?.All) {
      setTransferCheckAll(true);
    }
  }, [AgencyPermission]);

  if (editData) {
    editData.id = editData?._id;
  }
  let initialValues = {};
  initialValues = {
    roleName: Permission_name || "",
    jobManagement: {
      value: AgencyPermission?.Jobs?.value || false,
      All: AgencyPermission?.Jobs?.permission?.All || false,
      add: AgencyPermission?.Jobs?.permission?.add || false,
      Suspend: AgencyPermission?.Jobs?.permission?.Suspend || false,
      read: AgencyPermission?.Jobs?.permission?.read || false,
      edit: AgencyPermission?.Jobs?.permission?.edit || false,
      delete: AgencyPermission?.Jobs?.permission?.delete || false,
    },
    officerManagement: {
      value: AgencyPermission?.Officer?.value || false,
      All: AgencyPermission?.Officer?.permission?.All || false,
      add: AgencyPermission?.Officer?.permission?.add || false,
      Suspend: AgencyPermission?.Officer?.permission?.Suspend || false,
      read: AgencyPermission?.Officer?.permission?.read || false,
      edit: AgencyPermission?.Officer?.permission?.edit || false,
      delete: AgencyPermission?.Officer?.permission?.delete || false,
    },
    skillManagement: {
      value: AgencyPermission?.Skills?.value || false,
      All: AgencyPermission?.Skills?.permission?.All || false,
      add: AgencyPermission?.Skills?.permission?.add || false,
      Suspend: AgencyPermission?.Skills?.permission?.Suspend || false,
      read: AgencyPermission?.Skills?.permission?.read || false,
      edit: AgencyPermission?.Skills?.permission?.edit || false,
      delete: AgencyPermission?.Skills?.permission?.delete || false,
    },
    extraWorkPermit: {
      value: AgencyPermission?.Extra_Work_Premit?.value || false,
      All: AgencyPermission?.Extra_Work_Premit?.permission?.All || false,
      add: AgencyPermission?.Extra_Work_Premit?.permission?.add || false,
      Suspend:
        AgencyPermission?.Extra_Work_Premit?.permission?.Suspend || false,
      read: AgencyPermission?.Extra_Work_Premit?.permission?.read || false,
      edit: AgencyPermission?.Extra_Work_Premit?.permission?.edit || false,
      delete: AgencyPermission?.Extra_Work_Premit?.permission?.delete || false,
    },
    clientManagement: {
      value: AgencyPermission?.Client?.value || false,
      All: AgencyPermission?.Client?.permission?.All || false,
      add: AgencyPermission?.Client?.permission?.add || false,
      Suspend: AgencyPermission?.Client?.permission?.Suspend || false,
      read: AgencyPermission?.Client?.permission?.read || false,
      edit: AgencyPermission?.Client?.permission?.edit || false,
      delete: AgencyPermission?.Client?.permission?.delete || false,
    },
    RatingAndReviewManagement: {
      value: AgencyPermission?.rating?.value || false,
      All: AgencyPermission?.rating?.permission?.All || false,
      add: AgencyPermission?.rating?.permission?.add || false,
      Suspend: AgencyPermission?.rating?.permission?.Suspend || false,
      read: AgencyPermission?.rating?.permission?.read || false,
      edit: AgencyPermission?.rating?.permission?.edit || false,
      delete: AgencyPermission?.rating?.permission?.delete || false,
    },
    TransferJobManagement: {
      value: AgencyPermission?.job_Transfer?.value || false,
      All: AgencyPermission?.job_Transfer?.permission?.All || false,
      add: AgencyPermission?.job_Transfer?.permission?.add || false,
      Suspend: AgencyPermission?.job_Transfer?.permission?.Suspend || false,
      read: AgencyPermission?.job_Transfer?.permission?.read || false,
      edit: AgencyPermission?.job_Transfer?.permission?.edit || false,
      delete: AgencyPermission?.job_Transfer?.permission?.delete || false,
    },
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    let urlencoded = {
      id: editData?._id,
      Permission_name: values?.roleName,
      AgencyPermission: {
        Jobs: {
          value: values.jobManagement.value,
          permission: {
            All: values.jobManagement.All,
            add: values.jobManagement.add,
            Suspend: values.jobManagement.Suspend,
            read: values.jobManagement.read,
            edit: values.jobManagement.edit,
            delete: values.jobManagement.delete,
          },
        },
        Officer: {
          value: values.officerManagement.value,
          permission: {
            All: values.officerManagement.All,
            add: values.officerManagement.add,
            Suspend: values.officerManagement.Suspend,
            read: values.officerManagement.read,
            edit: values.officerManagement.edit,
            delete: values.officerManagement.delete,
          },
        },
        Skills: {
          value: values.skillManagement.value,
          permission: {
            All: values.skillManagement.All,
            add: values.skillManagement.add,
            read: values.skillManagement.read,
            edit: values.skillManagement.edit,
            delete: values.skillManagement.delete,
          },
        },
        Client: {
          value: values.clientManagement.value,
          permission: {
            All: values.clientManagement.All,
            add: values.clientManagement.add,
            Suspend: values.clientManagement.Suspend,
            read: values.clientManagement.read,
            edit: values.clientManagement.edit,
            delete: values.clientManagement.delete,
          },
        },
        Extra_Work_Premit: {
          value: values?.extraWorkPermit?.value,
          permission: {
            All: values?.extraWorkPermit?.All,
            add: values?.extraWorkPermit?.add,
            Suspend: values?.extraWorkPermit?.Suspend,
            read: values?.extraWorkPermit?.read,
            edit: values?.extraWorkPermit?.edit,
            delete: values?.extraWorkPermit?.delete,
          },
        },
        rating: {
          value: values.RatingAndReviewManagement.value,
          permission: {
            All: values.RatingAndReviewManagement.All,
            add: values.RatingAndReviewManagement.add,
            Suspend: values.RatingAndReviewManagement.Suspend,
            read: values.RatingAndReviewManagement.read,
            edit: values.RatingAndReviewManagement.edit,
            delete: values.RatingAndReviewManagement.delete,
          },
        },
        job_Transfer: {
          value: values.TransferJobManagement.value,
          permission: {
            All: values.TransferJobManagement.All,
            add: values.TransferJobManagement.add,
            Suspend: values.TransferJobManagement.Suspend,
            read: values.TransferJobManagement.read,
            edit: values.TransferJobManagement.edit,
            delete: values.TransferJobManagement.delete,
          },
        },
      },
    };
    dispatch(addAgencyRoleMaster({ urlencoded, navigate, setLoading }));
  };
  const BackBtn = () => {
    navigate("/agency/role");
  };
  // Extra work permit Permission functions
  const handleCheckExtraWorkPermitPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setExtraWorkPermitCheckAll(isChecked);

    const updatedExtraWorkPermission = { ...values.extraWorkPermit };
    extraWorkPermit.forEach((item) => {
      updatedExtraWorkPermission[item.tag] = isChecked;
    });

    setFieldValue("extraWorkPermit", updatedExtraWorkPermission);
  };
  // Extra work permit permission single checkbox checked then function call
  const handleExtraWorkPermitPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedExtraWorkPermission = {
      ...values.extraWorkPermit,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = extraWorkPermit.every(
      (item) => updatedExtraWorkPermission[item.tag]
    );

    setExtraWorkPermitCheckAll(allChecked);
    setFieldValue("extraWorkPermit", updatedExtraWorkPermission);
  };
  // Client Permission functions
  const handleCheckUserPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setClientCheckAll(isChecked);

    const updatedUserPermission = { ...values.clientManagement };
    clientManagement.forEach((item) => {
      updatedUserPermission[item.tag] = isChecked;
    });

    setFieldValue("clientManagement", updatedUserPermission);
  };

  // Client permission single checkbox checked then function call
  const handleUserPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedUserPermission = {
      ...values.clientManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = clientManagement.every(
      (item) => updatedUserPermission[item?.tag]
    );

    setClientCheckAll(allChecked);
    setFieldValue("clientManagement", updatedUserPermission);
  };
  
   // Skills  Permission functions
   const handleCheckSkillPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setSkillCheckAll(isChecked);
    if (values !== undefined) {
      const updatedSkillPermission = { ...values.skillManagement };
      skillManagement && skillManagement.forEach((item) => {
        updatedSkillPermission[item.tag] = isChecked;
      });
      setFieldValue("skillManagement", updatedSkillPermission);
    }
  };

  const handleSkillPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedSkillPermission = {
      ...values.skillManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = skillManagement.every(
      (item) => updatedSkillPermission[item.tag]
    );

    setSkillCheckAll(allChecked);
    setFieldValue("skillManagement", updatedSkillPermission);
  };


  // Transfer job Permission functions
  const handleCheckTransferJobPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setTransferCheckAll(isChecked);

    const updatedTransferJobPermission = { ...values.TransferJobManagement };
    TransferJobManagement.forEach((item) => {
      updatedTransferJobPermission[item.tag] = isChecked;
    });
    setFieldValue("TransferJobManagement", updatedTransferJobPermission);
  };
  // Transfer Job permission single checkbox checked then function call
  const handleTransferJobPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedTransferJobPermission = {
      ...values.TransferJobManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = TransferJobManagement.every(
      (item) => updatedTransferJobPermission[item?.tag]
    );

    setTransferCheckAll(allChecked);
    setFieldValue("TransferJobManagement", updatedTransferJobPermission);
  };

  // Rating and Review Permission functions
  const handleCheckRatingAndReviewPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setReviewCheckAll(isChecked);

    const updatedRatingPermission = { ...values.RatingAndReviewManagement };
    RatingAndReviewManagement.forEach((item) => {
      updatedRatingPermission[item.tag] = isChecked;
    });

    setFieldValue("RatingAndReviewManagement", updatedRatingPermission);
  };
  // Rating and Review permission single checkbox checked then function call
  const handleRatingAndReviewPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedRatingPermission = {
      ...values.RatingAndReviewManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = RatingAndReviewManagement.every(
      (item) => updatedRatingPermission[item.tag]
    );

    setReviewCheckAll(allChecked);
    setFieldValue("RatingAndReviewManagement", updatedRatingPermission);
  };
  // Officer Permission functions
  const handleCheckOfficerPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setOfficerCheckAll(isChecked);

    const updatedOfficerPermission = { ...values.officerManagement };
    officerManagement.forEach((item) => {
      updatedOfficerPermission[item.tag] = isChecked;
    });
    setFieldValue("officerManagement", updatedOfficerPermission);
  };
  // Officer permission single checkbox checked then function call
  const handleOfficerPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedOfficerPermission = {
      ...values.officerManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = officerManagement.every(
      (item) => updatedOfficerPermission[item?.tag]
    );

    setOfficerCheckAll(allChecked);
    setFieldValue("officerManagement", updatedOfficerPermission);
  };

  // Job Permission functions
  const handleCheckJobPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setJobCheckAll(isChecked);

    const updatedJobPermission = { ...values.jobManagement };
    jobManagement.forEach((item) => {
      updatedJobPermission[item.tag] = isChecked;
    });

    setFieldValue("jobManagement", updatedJobPermission);
  };

  // Job permission single checkbox checked then function call
  const handleJobPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedJobPermission = { ...values.jobManagement, [tag]: isChecked };

    // Check if all individual checkboxes are checked
    const allChecked = jobManagement.every(
      (item) => updatedJobPermission[item.tag]
    );

    setJobCheckAll(allChecked);
    setFieldValue("jobManagement", updatedJobPermission);
  };

  return (
    <>
      {/* New Design Code Changes */}
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
              onClick={()=>{
                navigate(`/${roleName}/role`)
              }}
            ></img>
          {editData ? "Edit" : "Add"} Role
        </Index.Typography>
      </Index.Box>
      {/* End New Design Code Changes */}

      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={PagesIndex.roleMasterValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Box className="input-box">
                        <Index.FormHelperText
                          variant="label"
                          component="label"
                          className="form-lable "
                        >
                          Role Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Role Name"
                            variant="filled"
                            className="admin-input-bottom-border-field"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="roleName"
                            value={values?.roleName}
                          />
                        </Index.Box>
                        <Index.FormHelperText error>
                          {errors.roleName && touched.roleName
                            ? errors.roleName
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>

                  <Index.Box className="main-permission-box common-card">
                    <Index.Box className="permission-left-main">
                      <Index.Typography className="permission-label">
                        Job Management
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="permission-right-main">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="role-check-box"
                            checked={jobCheckAll}
                            onChange={(e) =>
                              handleCheckJobPermission(e, setFieldValue, values)
                            }
                          />
                        }
                        label="Check All"
                      />
                      <>
                        {jobManagement?.length &&
                          jobManagement?.map((item, index) => (
                            <Index.Box
                              key={index}
                              className="check-permission-box"
                            >
                              <FormControlLabel
                                name={item?.tag}
                                control={
                                  <Checkbox
                                    className="role-check-box"
                                    onChange={(e) => {
                                      handleJobPermissionChecked(
                                        e.target.name,
                                        e.target.checked,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    checked={values?.jobManagement[item?.tag]}
                                  />
                                }
                                label={item?.name}
                              />
                            </Index.Box>
                          ))}
                      </>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="main-permission-box common-card">
                    <Index.Box className="permission-left-main">
                      <Index.Typography className="permission-label">
                        Vendor Management
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="permission-right-main">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="role-check-box"
                            checked={clientCheckAll}
                            onChange={(e) =>
                              handleCheckUserPermission(
                                e,
                                setFieldValue,
                                values
                              )
                            }
                          />
                        }
                        label="Check All"
                      />
                      <>
                        {clientManagement?.length &&
                          clientManagement?.map((item, index) => (
                            <Index.Box
                              key={index}
                              className="check-permission-box"
                            >
                              <FormControlLabel
                                name={item?.tag}
                                control={
                                  <Checkbox
                                    className="role-check-box"
                                    onChange={(e) => {
                                      handleUserPermissionChecked(
                                        e.target.name,
                                        e.target.checked,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    checked={
                                      values?.clientManagement[item?.tag]
                                    }
                                  />
                                }
                                label={item?.name}
                              />
                            </Index.Box>
                          ))}
                      </>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="main-permission-box common-card">
                    <Index.Box className="permission-left-main">
                      <Index.Typography className="permission-label">
                        Officer Management
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="permission-right-main">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="role-check-box"
                            checked={officerCheckAll}
                            onChange={(e) =>
                              handleCheckOfficerPermission(
                                e,
                                setFieldValue,
                                values
                              )
                            }
                          />
                        }
                        label="Check All"
                      />
                      <>
                        {officerManagement?.length &&
                          officerManagement?.map((item, index) => (
                            <Index.Box
                              key={index}
                              className="check-permission-box"
                            >
                              <FormControlLabel
                                name={item?.tag}
                                control={
                                  <Checkbox
                                    className="role-check-box"
                                    onChange={(e) => {
                                      handleOfficerPermissionChecked(
                                        e.target.name,
                                        e.target.checked,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    checked={
                                      values?.officerManagement[item?.tag]
                                    }
                                  />
                                }
                                label={item?.name}
                              />
                            </Index.Box>
                          ))}
                      </>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Skill Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={skillCheckAll}
                        onChange={(e) =>
                          handleCheckSkillPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {skillManagement?.length &&
                      skillManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleSkillPermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.skillManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

                  <Index.Box className="main-permission-box common-card">
                    <Index.Box className="permission-left-main">
                      <Index.Typography className="permission-label">
                        Work policy
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="permission-right-main">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="role-check-box"
                            checked={extraWorkPermitCheckAll}
                            onChange={(e) =>
                              handleCheckExtraWorkPermitPermission(
                                e,
                                setFieldValue,
                                values
                              )
                            }
                          />
                        }
                        label="Check All"
                      />
                      <>
                        {extraWorkPermit?.length &&
                          extraWorkPermit?.map((item, index) => (
                            <Index.Box
                              key={index}
                              className="check-permission-box"
                            >
                              <FormControlLabel
                                name={item?.tag}
                                control={
                                  <Checkbox
                                    className="role-check-box"
                                    onChange={(e) => {
                                      handleExtraWorkPermitPermissionChecked(
                                        e.target.name,
                                        e.target.checked,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    checked={values?.extraWorkPermit[item?.tag]}
                                  />
                                }
                                label={item?.name}
                              />
                            </Index.Box>
                          ))}
                      </>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="main-permission-box common-card">
                    <Index.Box className="permission-left-main">
                      <Index.Typography className="permission-label">
                        Transfer Job
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="permission-right-main">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="role-check-box"
                            checked={transferCheckAll}
                            onChange={(e) =>
                              handleCheckTransferJobPermission(
                                e,
                                setFieldValue,
                                values
                              )
                            }
                          />
                        }
                        label="Check All"
                      />
                      <Index.Box className="check-permission-flex">
                        <>
                          {TransferJobManagement?.length &&
                            TransferJobManagement?.map((item, index) => (
                              <Index.Box
                                key={index}
                                className="check-permission-box"
                              >
                                <FormControlLabel
                                  name={item?.tag}
                                  control={
                                    <Checkbox
                                      className="role-check-box"
                                      onChange={(e) => {
                                        handleTransferJobPermissionChecked(
                                          e.target.name,
                                          e.target.checked,
                                          setFieldValue,
                                          values
                                        );
                                      }}
                                      checked={
                                        values?.TransferJobManagement[item?.tag]
                                      }
                                    />
                                  }
                                  label={item?.name}
                                />
                              </Index.Box>
                            ))}
                        </>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                 {roleName == "Agency" ? (
                  <>
                  <Index.Box className="main-permission-box common-card">
                    <Index.Box className="permission-left-main">
                      <Index.Typography className="permission-label">
                        Rating & Review Management
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="permission-right-main">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="role-check-box"
                            checked={reviewCheckAll}
                            onChange={(e) =>
                              handleCheckRatingAndReviewPermission(
                                e,
                                setFieldValue,
                                values
                              )
                            }
                          />
                        }
                        label="Check All"
                      />
                      <>
                        {RatingAndReviewManagement?.length &&
                          RatingAndReviewManagement?.map((item, index) => (
                            <Index.Box
                              key={index}
                              className="check-permission-box"
                            >
                              <FormControlLabel
                                name={item?.tag}
                                control={
                                  <Checkbox
                                    className="role-check-box"
                                    onChange={(e) => {
                                      handleRatingAndReviewPermissionChecked(
                                        e.target.name,
                                        e.target.checked,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    checked={
                                      values?.RatingAndReviewManagement[
                                        item?.tag
                                      ]
                                    }
                                  />
                                }
                                label={item?.name}
                              />
                            </Index.Box>
                          ))}
                      </>
                    </Index.Box>
                  </Index.Box>
                  </>
                 ) : (
                  null
                 )}
                  
                </Index.Box>

                <Index.Box className="btn-main-primary user-btn-flex">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    className="btn-primary"
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}
export default EditAgency;

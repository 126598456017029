import React, {useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import AddWorkPermit from "./AddWorkPolicy";
import Loader from "../../../../common/loader/Loader";
import ExtraPendingModal from "./workPolicy/RejectModal";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

export default function WorkPolicy() {
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;
  // end permission
  const { state } = PagesIndex.useLocation();
  const [tabValue, setTabValue] = useState(state ? state : "workpolicy");
  // reject modal
  const [filteredData, setFilteredData] = useState([]);
  const [extraWorkList, setExtraWorkList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editUserData, setEditUserData] = useState("");
  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // Add user Modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditUserData("");
  };

  // Pending Job request modal
  const [pendingjobId, setPendingJobId] = useState();
  const [pandingRequestopen, setPandingRequestOpen] = useState(false);
  const handleOpenPandingRequest = (id) => {
    setPendingJobId(id);
    setPandingRequestOpen(true);
  };
  const handleClosePandingRequest = () => setPandingRequestOpen(false);

  // Add Edit Extra Work Permit
  const getExtraWorkPermitList = async () => {
    try {
      setLoading(true);
      const res = await DataService.get(Api.Agency.AGENCY_EXTRA_WORK_LIMIT);
      setFilteredData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      PagesIndex?.toast?.error(error?.response?.data?.message);
    }
  };
  // Get Extra Work Permit List
  const getAgencyExtraWorkPermitList = async () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("type", tabValue);
    try {
      const res = await DataService.post(
        Api.Agency.GET_AGENCY_EXTRA_WORK_LIST,
        urlEncoded
      );
      setExtraWorkList(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      PagesIndex?.toast?.error(error?.response?.data?.message);
    }
  };

  PagesIndex.useEffect(() => {
    getExtraWorkPermitList();
    getAgencyExtraWorkPermitList();
  }, [tabValue]);

  // job approverd / Rejected
  const handleApproveRequest = (officerWorkPermitStatus, _id) => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("_id", _id);
    urlEncoded.append("officerWorkPermitStatus", officerWorkPermitStatus);
    PagesIndex?.DataService.post(
      PagesIndex?.Api?.Agency.GET_AGENCY_EXTRA_WORK_LIST,
      urlEncoded
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          handleClosePandingRequest();
          getAgencyExtraWorkPermitList();
          setLoading(false);
          PagesIndex?.toast?.success(res?.data?.message);
        }
      })
      .catch((err) => {
        PagesIndex?.toast?.error(err?.response?.data?.message);
        setLoading(true);
      });
  };

  return (
    <>
      <Index.Box className="extra-work-permit-request-wrape">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Work Policy
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                {filteredData.length > 0 ? null : (
                  <>
                    {roleName === "Agency" ||
                      roleName === "Department" ||
                      AgencyPermission?.Extra_Work_Premit?.permission?.add === true ? (
                      <Index.Button
                        className="adduser-btn btn-primary"
                        onClick={() => {
                          handleOpen();
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.plus}
                          className="plus-icon"
                          alt="plus icon"
                        />
                        Add Work
                      </Index.Button>
                    ) : null}
                  </>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <AddWorkPermit
          getExtraWorkPermitList={getExtraWorkPermitList}
          handleClose={handleClose}
          open={open}
          editUserData={editUserData}
        />
      </Index.Box>

      <Index.Box className="cus-tabs-main-box res-hob-manag">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={tabValue}
              onChange={handleTabsChange}
              variant="scrollable"
              allowScrollButtonsMobile
              aria-label="basic tabs example"
              className="cus-tabs-main"
            >
              <Index.Tab
                label="Work Policy "
                value="workpolicy"
                className="cus-tab"
              />
              <Index.Tab
                label="Pending Work Permit "
                value="Pending"
                className="cus-tab"
              />
              <Index.Tab
                label="Accept Work Permit"
                value="Approve"
                className="cus-tab"
              />
            </Index.Tabs>
          </Index.Box>
          <TabPanel
            value={tabValue}
            index="workpolicy"
            className="cus-tabpanel res-cus-tabpanel m-t-15"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="mob-inner-scroll-area">
                <Index.Box className="dashboard-content extra-work-main bg-none p-0">
                  <Index.Box className="officer-detail-wrapper">
                    {filteredData.length > 0 ? (
                      filteredData.map((item) => {
                        return (
                          <>
                            <Index.Box className="common-card job-mamangment-card">
                              <Index.Box className="admin-sub-flex">
                                <Index.Box>
                                  <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                    <Index.Typography component="p" variant="p" className="admin-sub-title">
                                      Day Work Limit :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography component="p" variant="p" className="admin-sub-title-value-text">
                                      {item?.dayWorkLimit}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box>
                                    <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                      <Index.Typography component="p" variant="p" className="admin-sub-title">
                                        Week Work Limit :&nbsp;
                                      </Index.Typography>
                                      <Index.Typography component="p" variant="p" className="admin-sub-title-value-text">
                                        {item?.weekWorkLimit}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="card-right">
                                  <Index.Box className="right-btn-group">
                                    {roleName === "Agency" ||
                                      roleName === "Department" ||
                                      AgencyPermission?.Extra_Work_Premit?.permission
                                        ?.edit === true ? (
                                      <Index.PrimaryButton
                                        btnLabel="Edit"
                                        className="btn-primary"
                                        onClick={() => {
                                          handleOpen();
                                          setEditUserData(item);
                                        }}
                                      />
                                    ) : null}
                                  </Index.Box>
                                </Index.Box>

                              </Index.Box>
                            </Index.Box>
                          </>
                        );
                      })
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            )}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="Pending"
            className="cus-tabpanel res-cus-tabpanel m-t-15"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="tabpanel-main officer-detail-wrapper ">
                {extraWorkList?.length ? (
                  extraWorkList?.map((item, index) => (
                    <Index.Box className="card-main">
                      <Index.Box className="card-left">
                        <Index.Box className="job-title-text">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="job-title-label"
                          >
                            Officer Name :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="job-title-text-label"
                          >
                            {item?.officerId?.firstName}{" "}
                            {item?.officerId?.lastName}
                          </Index.Typography>
                        </Index.Box>
                        {item?.dayWorkLimit ? (
                          <Index.Box className="job-title-text">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-label"
                            >
                              Day Work Limit :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-text-label"
                            >
                              {item?.dayWorkLimit} hr
                            </Index.Typography>
                          </Index.Box>
                        ) : null}
                        {item?.weekWorkLimit ? (
                          <Index.Box className="job-title-text">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-label"
                            >
                              Week Work Limit :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-text-label"
                            >
                              {item?.weekWorkLimit} hr
                            </Index.Typography>
                          </Index.Box>
                        ) : null}

                        {item?.weekDaysDate ? (
                          <>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Week Start Date:&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {/* {item?.weekWorkLimit?.startDate} */}
                                {PagesIndex.dayjs(
                                  item?.weekDaysDate?.startDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Week End Date:&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {PagesIndex.dayjs(
                                  item?.weekDaysDate?.endDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        ) : null}

                        {item?.dayDate ? (
                          <>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Start Date:&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {PagesIndex.dayjs(
                                  item?.dayDate?.startDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                End Date:&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {PagesIndex.dayjs(
                                  item?.dayDate?.endDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        ) : null}
                      </Index.Box>

                      <Index.Box className="card-right job_title_status">
                        {" "}
                        <Index.Box className="document-btn-grp">
                          <>
                            <Index.PrimaryButton
                              btnLabel={
                                item?.officerWorkPermitStatus === "APPROVED"
                                  ? "Approved"
                                  : "Approve"
                              }
                              disabled={
                                item?.officerWorkPermitStatus === "REJECTED"
                                  ? true
                                  : false
                              }
                              className="btn-primary bg-approve appr-rej-btn"
                              onClick={() =>
                                handleApproveRequest("APPROVED", item?._id)
                              }
                            />

                            <Index.PrimaryButton
                              btnLabel={
                                item?.officerWorkPermitStatus === "REJECTED"
                                  ? "Rejected"
                                  : "Reject"
                              }
                              disabled={
                                item?.officerWorkPermitStatus === "APPROVED"
                                  ? true
                                  : false
                              }
                              className="btn-primary bg-suspend appr-rej-btn"
                              onClick={() => handleOpenPandingRequest(item)}
                            />
                          </>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ))
                ) : (
                  <PagesIndex.NoDataFound />
                )}
              </Index.Box>
            )}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="Approve"
            className="cus-tabpanel res-cus-tabpanel m-t-15"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="tabpanel-main officer-detail-wrapper">
                {extraWorkList?.length ? (
                  extraWorkList?.map((item, index) => (
                    <Index.Box className="card-main">
                      <Index.Box className="card-left">
                        <Index.Box className="job-title-text">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="job-title-label"
                          >
                            Officer Name :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="job-title-text-label"
                          >
                            {item?.officerId?.firstName}{" "}
                            {item?.officerId?.lastName}
                            {console.log(item, "item777")}
                          </Index.Typography>
                        </Index.Box>
                        {item?.dayWorkLimit ? (
                          <Index.Box className="job-title-text">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-label"
                            >
                              Day Work Limit :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-text-label"
                            >
                              {item?.dayWorkLimit} hr
                            </Index.Typography>
                          </Index.Box>
                        ) : null}
                        {item?.weekWorkLimit ? (
                          <Index.Box className="job-title-text">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-label"
                            >
                              Week Work Limit :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-text-label"
                            >
                              {item?.weekWorkLimit} hr
                            </Index.Typography>
                          </Index.Box>
                        ) : null}

                        {item?.dayDate ? (
                          <>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Start Date:&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {PagesIndex.dayjs(
                                  item?.dayDate?.startDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                End Date:&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {PagesIndex.dayjs(
                                  item?.dayDate?.endDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        ) : null}

                        {item?.weekDaysDate ? (
                          <>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Week Start Date:&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {PagesIndex.dayjs(
                                  item?.weekDaysDate?.startDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Week End Date:&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {PagesIndex.dayjs(
                                  item?.weekDaysDate?.endDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        ) : null}
                      </Index.Box>

                      <Index.Box className="card-right job_title_status">
                        {" "}
                        <Index.Box className="document-btn-grp">
                          <>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className={
                                (item?.officerWorkPermitStatus ||
                                  item?.status) === "APPROVED"
                                  ? "status_tag"
                                  : (item?.officerWorkPermitStatus ||
                                    item?.status) === "REJECTED"
                                    ? "status_red"
                                    : "status_blue"
                              }
                            >
                              {(item?.officerWorkPermitStatus ||
                                item?.status) === "APPROVED"
                                ? "Approved"
                                : (item?.officerWorkPermitStatus ||
                                  item?.status) === "REJECTED"
                                  ? "Rejected"
                                  : "In progress"}
                            </Index.Typography>
                          </>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ))
                ) : (
                  <PagesIndex.NoDataFound />
                )}
              </Index.Box>
            )}
          </TabPanel>
        </Index.Box>
      </Index.Box>

      <ExtraPendingModal
        pandingRequestopen={pandingRequestopen}
        handleClosePandingRequest={handleClosePandingRequest}
        pendingjobId={pendingjobId}
        getAgencyExtraWorkPermitList={getAgencyExtraWorkPermitList}
      />
    </>
  );
}

import React from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const UserDeleteModal = (props) => {
  const { handleCloseDeleteNew, openDeleteNew, getUserListData, deleteUserId } =
    props;

  const handleUserDelete = async () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", deleteUserId);
    try {
      const response = await DataService.post(
        PagesIndex.Api.Admin.DELETE_USER,
        urlEncoded
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customId",
        });
        handleCloseDeleteNew();
        getUserListData();
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, { toastId: "customId" });
    }
  };

  const handleOfficerDeleteConfirm = () => {
    handleUserDelete();
  };
  return (
    <>
      <Index.Modal
        open={openDeleteNew}
        onClose={handleCloseDeleteNew}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >



        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner extra-delete"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >

            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={handleCloseDeleteNew}
              alt=""
            />
          </Index.Box>
          <Index.Box className="modal-body">
            <Index.Box className="admin-modal-hgt-scroll">
              <Index.Box className="delete-modal-body">
                <img
                  src={PagesIndex.Svg.deletecross}
                  alt="Delete"
                  className="delete-icon"
                />
                <Index.Typography component="h6" className="delete-title">Are you sure?</Index.Typography>
                <Index.Typography component="p" className="
                delete-message">
                  Do you really want to delete this sub admin ?
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-footer">
            <Index.Box className="modal-footer-btn-flex">
              <Index.PrimaryButton
                btnLabel="Delete"
                className="btn-primary bg-approve"
                onClick={() => handleOfficerDeleteConfirm()}
              />
              <Index.PrimaryButton
                btnLabel="Cancel"
                onClick={handleCloseDeleteNew}
                className="btn-primary bg-suspend"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default UserDeleteModal;

import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import PropTypes from "prop-types";
import DynamicTabPanel from './DynamicTabPanel';
import SearchMaster from "../../../../common/search/SearchMaster"
import DateRangePickerMaster from "../../../../common/dateRangePicker/DateRangePickerMaster"
import CommonPagination from "../../../../common/commanPagination/CommanPagination";
import ExcelExportHelper from "../../../../common/ExcelExportHelper/ExcelExportHelper";
import { Api } from "../../../../config/Api";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AdminPaymentConfigManagement = () => {
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("enduserpayment");
  const [listData, setListData] = useState([]);
  const [exportData, setExportData] = useState([]);
  // State for Search and date filter
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [searchValue, setSearchValue] = useState("");
  const [enableSheet, setEnableSheet] = useState(false);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [pageDefault, setPageDefault] = useState(null);

  //Getting data from redux
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  const roleName = adminLoginData?.name;
  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getPaymentHistory = () => {
    setLoading(true);
    try {
      let endpoint = tabValue === "enduserpayment"
        ? PagesIndex.Api.Admin.GET_PAYMENT_CLIENT_HISTORY
        : tabValue === "paymenttodepartment" ? PagesIndex.Api.Admin.GET_PAYMENT_DEPARTMENT_HISTORY : tabValue === "paymenttoagency" ? PagesIndex.Api.Admin.GET_PAYMENT_AGENCY_HISTORY : tabValue === "paymenttoofficers" ? Api.Admin.GET_PAYMENT_OFFICER_HISTORY : null
      const newParams = new URLSearchParams();
      newParams.append(
        "page",
        pageDefault !== null ? pageDefault : currentPage
      );
      newParams.append("size", paginationPerPage);

      if (searchValue) {
        newParams.append("search", searchValue);
      }
      if (
        selectedDate?.length &&
        selectedDate[0] !== null &&
        selectedDate[1] !== null
      ) {
        newParams.append("from", selectedDate[0].format("YYYY-MM-DD") ?? "");
        newParams.append("to", selectedDate[1].format("YYYY-MM-DD") ?? "");
      }

      // Append query string only if searchData or filterDate is provided
      if (
        searchValue ||
        (selectedDate[0] !== null && selectedDate[1] !== null) ||
        currentPage ||
        paginationPerPage
      ) {
        endpoint += `?${newParams.toString()}`;
      }
      PagesIndex.DataService.get(`${endpoint}`).then((res) => {
        if (res?.status === 200) {
          setCurrentPage(res?.data?.data.currentPage);
          setTotalRecordCount(res?.data?.data.totalCount);
          setPageDefault(null);
          setListData(res.data.data.paginatedResults);
          // setEnableSheet(true);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPaymentHistory();
  }, [tabValue, selectedDate, searchValue, paginationPerPage, currentPage]);

  const tabNumber = {
    'enduserpayment': 1,
    'paymenttodepartment': 2,
    'paymenttoagency': 3,
    'paymenttoofficers': 4,
  }

  const downloadPaymentHistoryCsv = async () => {
    setEnableSheet(true);
    try {
      let endpoint =
        tabValue === "enduserpayment"
          ? PagesIndex.Api.Admin.GET_PAYMENT_CLIENT_EXPORT_CSV
          : tabValue === "paymenttodepartment"
            ? PagesIndex.Api.Admin.GET_PAYMENT_DEPARTMENT_EXPORT_CSV
            : tabValue === "paymenttoagency"
              ? PagesIndex.Api.Admin.GET_PAYMENT_AGENCY_EXPORT_CSV
              : tabValue === "paymenttoofficers"
                ? Api.Admin.GET_PAYMENT_OFFICER_EXPORT_CSV
                : null;

      const newParams = new URLSearchParams();
      if (searchValue) {
        newParams.append("search", searchValue);
      }
      if (selectedDate?.length && selectedDate[0] !== null && selectedDate[1] !== null) {
        newParams.append("from", selectedDate[0].format("YYYY-MM-DD") ?? "");
        newParams.append("to", selectedDate[1].format("YYYY-MM-DD") ?? "");
      }

      if (searchValue || (selectedDate[0] !== null && selectedDate[1] !== null) || currentPage || paginationPerPage) {
        endpoint += `?${newParams.toString()}`;
      }

      const res = await PagesIndex.DataService.get(`${endpoint}`);
      if (res?.status === 200) {
        let filterData = res?.data?.data.map(({ _id, jobInfoId, officerFirstName, officerLastName, ...items }) => {
          let formattedItem = {
            ...items,
            createdAt: PagesIndex.moment(items.createdAt).format("MM-DD-YYYY"),
          };
          if (tabValue === "paymenttoofficers") {
            formattedItem.officer_name = `${officerFirstName} ${officerLastName}`;
          }
          return formattedItem;
        });
        setExportData(filterData);
      } else {
        setEnableSheet(false);
      }
    } catch (error) {
      console.log(error);
      setEnableSheet(false);
    }
  };

  useEffect(() => {
    if (exportData.length > 0) {
      setEnableSheet(false);
    }
  }, [exportData, enableSheet]);

  const shortcutsItems = [{ label: "Reset", getValue: () => [null, null] }];
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          <Index.Link to="/admin/dashboard" className="filter-back-link">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>{" "}
          Payment Configuration Management
        </Index.Typography>
        <Index.Box className="userlist-btn-flex">

          {/* //Search components */}
          <SearchMaster setSearchValue={setSearchValue} />

          <DateRangePickerMaster
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            shortcutsItems={shortcutsItems}
          />

          {listData?.length ? (
            <>
              <Index.Box className="adduser-btn-main btn-main-primary">
                {/* <Index.Button
                  variant="contained"
                  onClick={() => {
                    downloadPaymentHistoryCsv();
                  }}
                >
                  Export
                </Index.Button> */}

                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => {
                    downloadPaymentHistoryCsv();
                  }}
                >
                  <img
                    src={PagesIndex.Svg.exports}
                    alt=""
                    className="down-icon"
                  />{" "}
                  Exports
                </Index.Button>

              </Index.Box>

            </>
          ) : (
            <></>
          )}

          {!enableSheet && exportData?.length > 0 ? (
            <>
              <Index.Box className="common-button grey-button">
                <ExcelExportHelper
                  data={exportData}
                  // loading={loading}
                  sheetName={"Payment_History"}
                  setExportData={setExportData}
                />
              </Index.Box>
            </>
          ) : (
            <></>
          )}

          {/* Top filter */}
        </Index.Box>
      </Index.Box>
      <>
        <Index.Box className="common-card">
          <Index.Box className="cus-tabs-main-box">
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={tabValue}
                onChange={handleTabsChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="basic tabs example"
                className="cus-tabs-main"
              >
                <Index.Tab
                  label="End User's payment's"
                  value="enduserpayment"
                  className="cus-tab"
                />
                <Index.Tab
                  label="Payment to Department"
                  value="paymenttodepartment"
                  className="cus-tab"
                />
                <Index.Tab
                  label="Payment to Agency"
                  value="paymenttoagency"
                  className="cus-tab"
                />
                <Index.Tab
                  label="Payment to Officers"
                  value="paymenttoofficers"
                  className="cus-tab"
                />
              </Index.Tabs>
            </Index.Box>
            <DynamicTabPanel
              value={tabValue}
              index={tabValue}
              loading={loading}
              data={listData}
              tabNumber={tabNumber[tabValue]}
              className="cus-tabpanel"

            />

            <CommonPagination
              paginationPerPage={paginationPerPage}
              setPaginationPerPage={setPaginationPerPage}
              setPageDefault={setPageDefault}
              totalRecordCount={totalRecordCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Index.Box>
        </Index.Box>
      </>
    </>
  );
};

export default AdminPaymentConfigManagement;

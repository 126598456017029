import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import PropTypes from "prop-types";
import { SwipeableDrawer } from "@mui/material";
import Rating from "@mui/material/Rating";
import { getUserJobList } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import { timeDifferenceCalculation } from "../../../../common/timeCalculation/CalculateTimeDifference";
import { CostCalculateByTime } from "../../../../common/timeCalculation/CostCalculateByTime";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const UserHistory = () => {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [userjobData, setUserJobData] = useState([]);
  const [filteredUserJobData, setFilteredUserJobData] = useState([]);
  const [tabValue, setTabValue] = React.useState("ActiveJobs");

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [ratingvalue, setRatingValue] = React.useState(2);

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const userJobList = () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("type", tabValue);
    dispatch(getUserJobList(urlEncoded)).then((res) => {
      if (res?.payload?.status == 200) {
        setUserJobData(res?.payload?.data);
        setFilteredUserJobData(res?.payload?.data);
      }
    });
  };
  useEffect(() => {
    userJobList();
  }, [tabValue]);

  // Search on list
  const requestSearch = (searched) => {
    let filteredData = userjobData.filter((data) => {
      return !searched
        ? data
        : data?.title?.toLowerCase().includes(searched?.toLowerCase());
    });
    setFilteredUserJobData(filteredData);
  };
  
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          <Index.Link to="/admin/usermanage">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            />
          </Index.Link>
          Employee Details
        </Index.Typography>
        <Index.Box className="userlist-btn-flex">
          <Index.Box className="user-search-main">
            {/* Top search */}
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => requestSearch(e.target.value)}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* Top filter */}
          <Index.Box className="filter-main">
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Index.Box className="export-btn-main border-btn-main">
                  <Index.Button
                    className="export-btn border-btn"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <img
                      src={PagesIndex.Svg.filter}
                      className="down-icon"
                      alt="download icon"
                    />
                    Filter
                  </Index.Button>
                </Index.Box>
                <SwipeableDrawer
                  className="filter-main"
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  <Index.Box className="filter-header">
                    <Index.Typography className="filter-title">
                      User Filter
                    </Index.Typography>
                    <img
                      src={PagesIndex.Svg.closeicon}
                      className="filter-close-icon"
                      onClick={toggleDrawer(anchor, false)}
                      alt=""
                    />
                  </Index.Box>
                  <Index.Box className="filter-inner-main">
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder=""
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        City
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder=""
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box filter-input-box">
                      <Index.FormHelperText className="form-lable">
                        Status
                      </Index.FormHelperText>
                      <Index.Box className="checkbox-main filter-checkbox-main input-box">
                        <Index.FormControlLabel
                          control={<Index.Checkbox defaultChecked />}
                          label="Active"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Deactivate"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Pending"
                          className="checkbox-lable"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="filter-footer">
                    <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                      <Index.Button className="border-btn filter-cancel-btn">
                        Cancel
                      </Index.Button>
                      <Index.Button className="btn-primary filter-btn">
                        Filter
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </Index.Box>
          <Index.Box className="userlist-inner-btn-flex">
            <Index.Box className="adduser-btn-main btn-main-primary">
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="dashboard-content home-page-content res-home-page-content p-t-0">
        <Index.Box className="cus-tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={tabValue}
                onChange={handleTabsChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="basic tabs example"
                className="cus-tabs-main"
              >
                <Index.Tab
                  label="Active Jobs"
                  value="ActiveJobs"
                  className="cus-tab"
                />
                <Index.Tab
                  label="Completed jobs"
                  value="CompletedJobs"
                  className="cus-tab"
                />
              </Index.Tabs>
            </Index.Box>

            <TabPanel
              value={tabValue}
              index="ActiveJobs"
              className="cus-tabpanel res-cus-tabpanel m-t-15"
            >
              <Index.Box className="tabpanel-main res-tabpanel-main">
                <Index.Box className="active-job-main brdr-total">
                  {filteredUserJobData.length ? (
                    filteredUserJobData.map((item, index) => (
                      <Index.Box className="officer_accept_job_card user_job_history_card">
                        <Index.Box className="card-main">
                          <Index.Box className="card-left">
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Job Title : {item?.title}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Location :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {item?.location}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Agency :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                Agency Name
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Total Hours :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                              {PagesIndex.timeDifferenceCalculation(item)} hour

                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Pay Rate :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                ${item?.rate}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="card-right">
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Total :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                ${PagesIndex.TotalCalculateByTime(item, item.rate)}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {item?.officerId.map((row) => {
                          const shortOfficerDetail = row?.officerId;
                          // const particularOfficer = {
                          //   officerId: row?.officerId?._id,
                          //   jobId: item?._id,
                          // };
                          return (
                            <Index.Box className="panding_officer_job_detail_card">
                              <Index.Box className="panding_officer_job_detail_wrap">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="pending_officer_heading"
                                >
                                  Officer Detail :
                                </Index.Typography>

                                <Index.Box className="job-title-text">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                  >
                                    Name :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                  >
                                    {shortOfficerDetail
                                      ? shortOfficerDetail?.firstName
                                      : "" + " " + shortOfficerDetail?.lastName}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                  >
                                    Email Address :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                  >
                                    {shortOfficerDetail?.email}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                  >
                                    Type :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                  >
                                    {shortOfficerDetail?.officerType}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          );
                        })}
                        <Index.Box className="user_job_history_card_btn">
                          <Index.PrimaryButton
                            onClick={() =>
                              navigate(
                                "/admin/usermanage/adminUserhistorydetailsofactivejob",
                                { state: item }
                              )
                            }
                            btnLabel="View"
                            className="btn-primary"
                          />
                        </Index.Box>
                      </Index.Box>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel
              value={tabValue}
              index="CompletedJobs"
              className="cus-tabpanel res-cus-tabpanel m-t-15"
            >
              <Index.Box className="tabpanel-main">
                <Index.Box className="complete-job-main brdr-total">
                  {filteredUserJobData.length ? (
                    filteredUserJobData.map((item, index) => (
                      <Index.Box className="officer_accept_job_card user_job_history_card">
                        <Index.Box className="card-main">
                          <Index.Box className="card-left">
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Job Title : {item?.title}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Location :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {item?.location}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Agency :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                Agency Name
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Total Hours :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                             {PagesIndex.timeDifferenceCalculation(item)} hour

                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Pay Rate :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                ${item?.rate}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="card-right">
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Total :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                ${PagesIndex.TotalCalculateByTime(item, item.rate)}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="rating-main">
                              <Index.Box
                                sx={{
                                  "& > legend": { mt: 2 },
                                }}
                              >
                                <Rating
                                  name="simple-controlled"
                                  value={ratingvalue}
                                  onChange={(event, newValue) => {
                                    setRatingValue(newValue);
                                  }}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {item?.officerId.map((row) => {
                          const shortOfficerDetail = row?.officerId;
                          // const particularOfficer = {
                          //   officerId: row?.officerId?._id,
                          //   jobId: item?._id,
                          // };
                          return (
                            <Index.Box className="panding_officer_job_detail_card">
                              <Index.Box className="panding_officer_job_detail_wrap">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="pending_officer_heading"
                                >
                                  Officer Detail :
                                </Index.Typography>

                                <Index.Box className="job-title-text">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                  >
                                    Name :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                  >
                                    {shortOfficerDetail
                                      ? shortOfficerDetail?.firstName
                                      : "" + " " + shortOfficerDetail?.lastName}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                  >
                                    Email Address :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                  >
                                    {shortOfficerDetail?.email}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                  >
                                    Type :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                  >
                                    {shortOfficerDetail?.officerType}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          );
                        })}
                        <Index.Box className="user_job_history_card_btn">
                          <Index.PrimaryButton
                            onClick={() =>
                              navigate(
                                "/admin/usermanage/adminUserhistorydetailsofcompletedjobs",
                                { state: item }
                              )
                            }
                            btnLabel="View"
                            className="btn-primary"
                          />
                        </Index.Box>
                      </Index.Box>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}             
                </Index.Box>
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};
export default UserHistory;

import React, { useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { AdminEditUserSchema } from "../../../../../validation/FormikSchema";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const EditNewClient = (props) => {
  const { openEditModal, handleCloseEdit, editUserData, getUserListData } =
    props;
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;

  //  phone number functionality
  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
   
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");
    
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }
    
    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
};
  const initialValues = {
    name: editUserData ? editUserData?.name : "",
    email: editUserData ? editUserData?.email : "",
    mobileNumber: editUserData ? editUserData?.mobileNumber : "",
  };

  const handleAddNewUserForm = async (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", editUserData?._id);
    urlEncoded.append("name", values?.name);
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    try {
      const response = await DataService.post(
        Api.Admin.ADD_EDIT_CLIENT,
        urlEncoded
      );
      if (response?.data?.status === 201) {
        PagesIndex.toast.success(response?.data?.message);
        handleCloseEdit();
        getUserListData();
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Index.Modal
        open={openEditModal}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box>
          <PagesIndex.Formik
            validationSchema={AdminEditUserSchema}
            initialValues={initialValues}
            onSubmit={handleAddNewUserForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              handleFocus,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* {console.log("error", errors)} */}
                {/* {console.log("values>>",values)} */}
                <Index.Box
                  sx={style}
                  className="add-user-modal-inner-main modal-inner"
                >
                  <Index.Box className="modal-header">
                    <Index.Typography
                      id="modal-modal-title"
                      className="modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Edit Details
                    </Index.Typography>
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="modal-close-icon"
                      onClick={handleCloseEdit}
                      alt=""
                    />
                  </Index.Box>

                  <Index.Box className="modal-body">
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Please enter first name"
                          name="name"
                          value={values.name}
                          // onChange={han
                          onChange={(e) => {
                            handleChange(e);
                            setFieldTouched("name", true);
                          }}
                          inputProps={{ maxLength: 25 }}
                          onBlur={handleBlur}
                          error={errors.name && touched.name ? true : false}
                          helperText={
                            errors.name && touched.name ? errors.name : null
                          }
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault();
                            }
                            if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => e.preventDefault()}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Email Address
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          disabled
                          className="form-control"
                          placeholder="Please enter email address"
                          name="email"
                          value={values.email}
                          onChange={(e) => {
                            handleChange(e);
                            // setFieldTouched("email", true);
                          }}
                          onKeyDown={(event) => {
                            if (event.key == " ") {
                              event.preventDefault();
                            }
                          }}
                          onBlur={handleBlur}
                          error={errors.email && touched.email ? true : false}
                          helperText={
                            errors.email && touched.email ? errors.email : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Phone Number
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Please enter phone number"
                          name="mobileNumber"
                          value={values?.mobileNumber}
                          onChange={(e) => {
                            handlePhoneChange(e, setFieldValue);
                          }}
                          inputProps={{ maxLength: 10}}
                          helperText={touched.mobileNumber && errors.mobileNumber}
                          error={Boolean(errors.mobileNumber && touched.mobileNumber)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="modal-footer">
                    <Index.Box className="document-btn-grp">
                      <Index.PrimaryButton
                        btnLabel="Update"
                        className="btn-primary bg-approve appr-rej-btn"
                        type="submit"
                      />

                      <Index.PrimaryButton
                        btnLabel="cancel"
                        type="button" 
                        className="btn-primary bg-suspend appr-rej-btn"
                        onClick={handleCloseEdit}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default EditNewClient;

import React, { useEffect, useState } from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";

import {
  getJobTypeData,
  getUserList,
} from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import AddJobTypeModal from "./jobtype-modal/AddJobTypeModal";
import JobTypeDeleteModal from "./jobtype-modal/JobTypeDeleteModal";
import Loader from "../../../../common/loader/Loader";
import ExcelExportHelper from "../../../../common/ExcelExportHelper/ExcelExportHelper";
// import AddZipCodeModal from "./zipcode-modal/AddZipCodeModal";
// import ZipCodeDeleteModal from "./zipcode-modal/ZipCodeDeleteModal";

const JobTypeManagement = (props) => {
  const [isActive, setActive] = useState(Array(0).fill(false));
  const dispatch = PagesIndex.useDispatch();
  const [loading, setLoading] = useState(false);
  const [jobTypeData, setJobTypeData] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  //Getting data from redux

  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const roleName = adminLoginData?.roleId?.Permission_name;

  // Add user modal
  const [addJobTypeopen, setAddJobTypeopen] = useState(false);
  const [editJobTypeData, setEditJobTypeData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const handleAddJobTypeOpen = () => setAddJobTypeopen(true);
  const handleAddJobTypeClose = () => {
    setAddJobTypeopen(false);
    setEditJobTypeData();
  };

  // Delete functionality
  const [deleteJobTypeId, setDeleteJobTypeId] = useState([]);
  const [jobTypeOpenDelete, setJobTypeOpenDelete] = useState(false);
  const handleOpenJobTypeDelete = () => setJobTypeOpenDelete(true);
  const handleCloseJobTypeDelete = () => setJobTypeOpenDelete(false);

  const [showExport, setShowExport] = useState(false); 
  const [exportData, setExportData] = useState([]);

  const handleExportClick = () => {
    let __filteredData = filteredData.map(({jobType})=> ({jobType}))
    setExportData(__filteredData);
    setShowExport(true); 
  };

  // get job type list
  const getJobTypeList = () => {
    setLoading(true);
    dispatch(getJobTypeData()).then((res) => {
      if (res?.payload?.status == 200) {
        setJobTypeData(res?.payload?.data);
        setFilteredData(res?.payload?.data);
        // setExportData(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getJobTypeList();
  }, []);


  useEffect(() => {
    if (exportData.length > 0) {
      setShowExport(false);
    }
  }, [exportData, showExport]);

  // Search on table
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filteredList = jobTypeData?.filter((data) =>
      `${data.jobType.toLowerCase()}`.includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredList);
  };



  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Index.Box>
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title"
              co mponent="h2"
              variant="h2"
            >
              {/* <Index.Link to="/admin/dashboard" className="filter-back-link">
                <img
                  src={PagesIndex.Svg.leftArrow}
                  className="left-arrow-ic"
                  alt="search grey img"
                ></img>
              </Index.Link>{" "} */}
              Job Type Management
            </Index.Typography>

            <Index.Box className="userlist-btn-flex btn-main-primary">
              <Index.Button
                className="adduser-btn btn-primary"
                onClick={() => {
                  handleExportClick();
                }}
              >
                <img
                  src={PagesIndex.Svg.exports}
                  alt=""
                  className="down-icon"
                />{" "}
                Exports
              </Index.Button>

              {!showExport && exportData?.length > 0  ? (
                  <>
                    <Index.Box className="common-button grey-button">
                      <ExcelExportHelper
                        data={exportData}
                        // loading={loading}
                        sheetName={"Job_type"}
                        setExportData={setExportData}
                      />
                    </Index.Box>
                  </>
                ) : (
                  <></>
                )}

              {/* Top search */}
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      value={searchValue}
                      onChange={handleSearch}
                      onKeyDown={(e) => {
                        if (
                          e.key === " " &&
                          e.target.value.trim() === ""
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <img
                      src={PagesIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {/* Add user Button */}
              <Index.Box className="userlist-inner-btn-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {roleName === "Admin" || SubAdminPermission?.Job_Type?.permission?.add == true ? (
                    <Index.Button
                      className="adduser-btn btn-primary"
                      onClick={() => handleAddJobTypeOpen()}
                    >
                      <img
                        src={PagesIndex.Svg.plus}
                        className="plus-icon"

                        alt="plus icon"
                      />
                      Add job Type
                    </Index.Button>
                  ) : null}

                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Zip code list */}
          <Index.Box className="job-type-mamangement-sec">
            <Index.Box>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        {item?.jobType}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="card-right btn-main-secondary btn-main-red">
                      <Index.Box
                        id="btn-toggle-popup"
                        className={
                          isActive[index] ? "toggle-pop-show" : null
                        }
                      >

                        {roleName === "Admin" ||
                          SubAdminPermission?.Job_Type?.permission?.edit == true ? (
                          <Index.Tooltip
                            title="Edit"
                            arrow
                            placement="bottom"
                            className="admin-tooltip"
                          >
                            <Index.Button
                              btnLabel="Edit"
                              className="btn-secondary"
                              onClick={() => {
                                handleAddJobTypeOpen();
                                setEditJobTypeData(item);
                              }}
                            >
                              <img src={PagesIndex.Svg.editNew} alt="delete" className="admin-icons" />
                            </Index.Button>
                          </Index.Tooltip>
                        ) : null}

                        {roleName === "Admin" ||
                          SubAdminPermission?.Job_Type?.permission?.delete == true ? (
                          <Index.Tooltip
                            title="Delete"
                            arrow
                            placement="bottom"
                            className="admin-tooltip"
                          >
                            <Index.Button
                              btnLabel="delete"
                              className="btn-red"
                              onClick={() => {
                                handleOpenJobTypeDelete();
                                setDeleteJobTypeId(item?._id);
                              }}
                            >
                              <img src={PagesIndex.Svg.deletewhite} alt="delete" className="admin-icons" />
                            </Index.Button>
                          </Index.Tooltip>
                        ) : null}



                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                ))
              ) : (
                <PagesIndex.NoDataFound />
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}


      {/* Add job type modal */}

      <AddJobTypeModal
        getJobTypeList={getJobTypeList}
        handleAddJobTypeClose={handleAddJobTypeClose}
        addJobTypeopen={addJobTypeopen}
        editJobTypeData={editJobTypeData}
      />

      {/* delete job type modal */}
      <JobTypeDeleteModal
        handleCloseJobTypeDelete={handleCloseJobTypeDelete}
        jobTypeOpenDelete={jobTypeOpenDelete}
        getJobTypeList={getJobTypeList}
        deleteJobTypeId={deleteJobTypeId}
      />
    </div>
  );
};

export default JobTypeManagement;

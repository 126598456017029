import React, { useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import Loader from "../../../../../common/loader/Loader";
import DataService from "../../../../../config/DataService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Supervisor = (props) => {
  const {
    supervisorRequestopen,
    handleCloseSupervisor,
    supervisorjobId,
    getJobListData,
    agencyJobData,
    nonSupervisorjobId,
    filterAgencyjobsList
  } = props;

  const initialValues = {
    supervisor: "",
    nonSupervisor: "",
  };
console.log(filterAgencyjobsList,"31111", supervisorjobId)
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const officerCount = supervisorjobId?.totalOfficer;
  const sanitizeInput = (value) => {
    return value.replace(/[^0-9.]/g, ''); // Allows only digits and decimal points
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let numericValue;

    if (/[0-9]/.test(value) || value == "") {
      const cleanedValue = sanitizeInput(value);
      numericValue = cleanedValue;
      setValues((prev) => ({
        ...prev,
        [name]: numericValue,
      }));

      if (name === "supervisor") {
        setErrors({
          ...errors,
          supervisor: "Please enter valid required supervisor for job",
        });
      }

      if (
        name === "supervisor" &&
        numericValue > officerCount - values.nonSupervisor
      ) {
        setErrors({
          ...errors,
          supervisor: `Please enter valid required supervisor for job`
        });
      } else if (name === "supervisor") {
        setErrors("");
      }

      if (
        name === "nonSupervisor" &&
        numericValue > officerCount - values.supervisor
      ) {
        setErrors({
          ...errors,
          nonSupervisor: `Please enter valid required non supervisor for job`,
        });
      } else if (name === "nonSupervisor") {
        setErrors("");
      }

    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (values.supervisor === "" && values.nonSupervisor === "") {
      newErrors.nonSupervisor = "Please enter supervisor or non-supervisor";
      setErrors(newErrors);
      return;
    } else if (Object.keys(errors).length > 0) {
      return;
    }

    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("jobId", supervisorjobId?._id);
    urlEncoded.append("supervisor", values.supervisor);
    urlEncoded.append("nonSupervisor", values.nonSupervisor);

    try {
      const response = await DataService.post(
        PagesIndex.Api.Agency.ADD_EDIT_SUPERVISOR,
        urlEncoded
      );
      if (response?.data?.status === 200) {
        PagesIndex.toast.success(response?.data?.message);
        handleCloseSupervisor();
        getJobListData();
        setIsLoading(false);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Index.Modal
        open={supervisorRequestopen}
        onClose={() => {
          handleCloseSupervisor();
          setValues(initialValues);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <form onSubmit={handleSubmit}>
          <Index.Box
            sx={style}
            className="add-user-modal-inner-main modal-inner"
          >
            <Index.Box className="modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="modal-title"
                variant="h6"
                component="h2"
              >
                Supervisor
              </Index.Typography>
              <Index.Typography
                id="modal-modal-title"
                className="modal-title"
                variant="h6"
                component="h2"
              >
              {/* {filterAgencyjobsList[0]?.totalOfficer} */}
              {/* {supervisorjobId && supervisorjobId.totalOfficer } */}
               Total Officer's : &nbsp; {supervisorjobId && supervisorjobId.totalOfficer }
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeblack}
                className="modal-close-icon"
                onClick={() => {
                  handleCloseSupervisor();
                  setValues(initialValues);
                  setErrors("");
                }}
                alt=""
              />
            </Index.Box>
            <Index.Box className="modal-body">
              <Index.Box className="input-box modal-input-box">
                <Index.FormHelperText className="form-lable">
                  Add Supervisor
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Please enter supervisor"
                    name="supervisor"
                    onBlur={handleInputChange}
                    value={values.supervisor}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 3 }}
                    error={Boolean(errors.supervisor)}
                    helperText={errors.supervisor}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box modal-input-box">
                <Index.FormHelperText className="form-lable">
                  Add Non-Supervisor
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Please enter non-supervisor"
                    name="nonSupervisor"
                    onBlur={handleInputChange}
                    value={values.nonSupervisor}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 3 }}
                    error={Boolean(errors.nonSupervisor)}
                    helperText={errors.nonSupervisor}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="modal-footer">
              <Index.Box className="modal-footer-btn-flex">
                <Index.PrimaryButton
                  btnLabel="Add"
                  className="btn-primary bg-approve"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Click Me"}
                </Index.PrimaryButton>
                <Index.PrimaryButton
                  btnLabel="Cancel"
                  className="btn-primary bg-suspend"
                  // onClick={handleCloseSupervisor}
                  onClick={() => {
                    handleCloseSupervisor();
                    setValues(initialValues);
                    setErrors("");
                  }}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
      </Index.Modal>
    </>
  );
};

export default Supervisor;

import React from "react";
import Index from "../../container";
import "../../assets/css/style.css";
import PagesIndex from "../../components/PagesIndex";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function Restricted() {
  const navigate = useNavigate();
  const supportEmail = 'support@protektsecuroty.com';
  const supportNumber = '8001234567';

  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const handleNavigate = () => {
    localStorage.removeItem(agencyLoginData?._id)
    navigate("/")
  }

  return (
    <>
      <Index.Box className="modal-body">

        <Index.Box className="admin-modal-hgt-scroll">
          <Index.Box className="delete-modal-body">
            <img
              src={require("../../assets/images/jpg/restricteImage.jpg")}
              alt="restrict"
              className="delete-icon"
            />
            <Index.Typography component="h6" className="delete-title">Account restricted</Index.Typography>
            <Index.Typography component="p" className="delete-message">
              Your account has been restricted due to violation of our terms and
              conditions. If you believe this is an error, please contact us via
              given details.
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="modal-footer">
          <Index.Box className="modal-footer-btn-flex">
            <Index.PrimaryButton
              className="btn-primary bg-approve"
              btnLabel="OK"
              onClick={handleNavigate}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export const Api = {
  Admin: {
    // Dashboard
    DASHBOARD_COUNT: "dashboard-count",
    // Auth
    ADMIN_LOGIN: "admin/login",
    FORGOT_PASSWORD: "admin/forgot-password",
    RESET_PASSWORD: "admin/reset-password",
    OTP_VERIFY: "admin/verify-otp",
    PROFILE_UPDATE: "admin/update-adminprofile",
    ADMIN_CHANGE_PASSWORD: "admin/change-password",
    GET_PROFILE_DATA: "admin/profile-details",
    // Zip code
    ADD_EDIT_ZIPCODE: "admin/add-edit-zip-code",
    GET_ZIPCODE_LIST: "admin/all-zip-code",
    SINGLE_ZIPCODE: "admin/single-zip-code",
    DELETE_ZIPCODE: "admin/delete-zip-code",
    // Job type code
    ADD_EDIT_JOBTYPE: "admin/add-edit-job-type",
    GET_JOBTYPE_LIST: "admin/all-job-type",
    SINGLE_JOBTYPE: "admin/single-job-type",
    DELETE_JOBTYPE: "admin/delete-job-type",
    // Job type code
    ADD_EDIT_SKILLS: "admin/add-edit-skill",
    GET_SKILLS_LIST: "admin/get-all-skill",
    GET_SINGLE_SKILL: "admin/get-all-skill",
    DELETE_SKILLS: "admin/delete-skill",

    // officer
    GET_OFFICER_LIST: "admin/list-all-officer",
    ADD_NEWOFFICER: "admin/add-officer",
    GET_SINGLE_OFFICER: "admin/get-singleofficer",
    DELETE_OFFICER: "admin/delete-officer",
    UPDATE_OFFICER: "admin/update-officerprofile",
    CHANGE_OFFICER_STATUS: "admin/change-officerstatus", //Suspend and Active

    // User management
    GET_USER_LIST: "admin/get-all-user",
    ADD_EDIT_USER: "admin/add-edit-user",
    DELETE_USER: "admin/delete-user",
    RESTRICT_USER: "admin/restrict-active-user",
    GET_USER_JOB_LIST: "admin/job-history",
    // Client management
    GET_CLIENT_LIST: "admin/get-all-client",
    ADD_EDIT_CLIENT: "admin/add-edit-client",
    DELETE_CLIENT: "admin/delete-client",
    RESTRICT_CLIENT: "admin/restrict-active-client",
    GET_CLIENT_JOB_LIST: "admin/job-history",
    GET_ACTIVE_DEACTIVE: "admin/active-account",

    // Role master
    GET_ROLE_LIST: "admin/get-permission-list",
    ADD_ROLE_MASTER: "admin/add-edit-permission",
    ROLE_MASTER_DELETE_PERMISSION: "admin/delete-Permission",

    // agent

    // ADD_AGENT: "admin/add-agency",
    ADD_AGENT: "admin/add-agency-new",
    EDIT_AGENT: "admin/update-agency",
    DELETE_AGENT: "admin/delete-agency",
    AGENT_RESTRICT_ACTIVE: "admin/restrict-active-agency",
    // job management
    GET_JOB_LIST: "admin/job-list",
    GET_SINGLE_JOB_LIST: "admin/get-job-list-by-id",
    JOB_APPROVE_REJECT: "admin/approve-reject-job",
    JOB_APPROVE_REJECT_OFFICER: "admin/job-approval-for-officer",
    GET_JOB_DETAIL_OFFICER_ACCEPT: "admin/job-for-officer-list-byId",
    // Content Management
    OFFICER_CREATE_PRIVACY_POLICY: "admin/create-privacy-policy",
    GET_PRIVACY_POLICY: "admin/get-cms-detail",
    OFFICER_TERMS_CONDITION: "admin/create-terms-and-condition",
    OFFICER_REFUND_POLICY: "admin/create-refund-policy",
    USER_CREATE_PRIVACY_POLICY: "admin/create-privacy-policy",
    USER_TERMS_CONDITION: "admin/create-terms-and-condition",

    //reveiw rating
    GET_REVIEW_LIST: "admin/get-review-and-rating",
    RATING_REVIEW: "admin/approve-reject-rating-review",
    // export button
    JOB_LIST_CSV_EXPORT_PENDINGUSER: "admin/job-list-pendinguser-to-csv",
    JOB_LIST_CSV_EXPORT_PENDINGOFFICER: "admin/job-list-pendingofficer-to-csv",
    JOB_LIST_CSV_EXPORT_ACTIVEJOBS: "admin/job-list-ActiveJobs-to-csv",
    JOB_LIST_CSV_EXPORT_COMPLETEDJOBS: "admin/job-list-CompletedJobs-to-csv",
    JOB_LIST_CSV_EXPORT_UNSERVEDJOBS: "admin/job-list-UnservedJobs-to-csv",

    // Rating and review disable
    REVIEW_ENABLE_DISABLE: "admin/update-review",

    //Payment 
     PAYOUT : "admin/payout",
     DETECT_PERCENTAGE : "admin/detect-percentage",
     GET_PAYMENT_CLIENT_HISTORY : "admin/client-payment-history",
     GET_PAYMENT_CLIENT_EXPORT_CSV : "admin/export-payment-history-for-client",
     GET_PAYMENT_DEPARTMENT_HISTORY : "admin/department-payment-history",
     GET_PAYMENT_DEPARTMENT_EXPORT_CSV : "admin/export-payment-history-for-department",
     GET_PAYMENT_AGENCY_HISTORY : "admin/agency-payment-history",
     GET_PAYMENT_AGENCY_EXPORT_CSV : "admin/export-payment-history-for-agency",
     GET_PAYMENT_OFFICER_HISTORY : "admin/officer-payment-history",
     GET_PAYMENT_OFFICER_EXPORT_CSV : "admin/export-payment-history-for-officer",

     //Payment-Percentage.
     CREATE_PAYMENT_PERCENTAGE : "admin/create-payment-percentage",
     UPDATE_PAYMENT_PERCENTAGE : "admin/edit-payment-percentage",
     GET_PAYMENT_PERCENTAGE : "admin/get-payment-percentage",


     //Notification
     GET_NOTIFICATION_HISTORY : "admin/notification-list", 
     UPDATE_NOTIFICATION_HISTORY : "admin/notification-isread-update" 
  },

  Agency: {
    // Auth
    AGENCY_LOGIN: "agency/login",
    AGENCY_REGISTER: "agency/register-agency",
    AGENCY_FORGOT_PASSWORD: "agency/forgot-password",
    AGENCY_VERIFY_OTP: "agency/verify-otp",
    AGENCY_RESET_PASSWORD: "agency/reset-password",
    AGENCY_CHANGE_PASSWORD: "agency/change-password",
    AGENCY_CHANGE_PASSWORD_NOT_AUTH: "agency/change-password-for-agency",
    AGENCY_UPDATE_PROFILE: "agency/update-agency",
    
    // Other
    GET_AGENCY_ROLE_LIST: "agency/list-of-role-for-agency",
    AGENCY_LIST_GET: "list-agency",
    // ADMIN_LIST_GET: "list-agency-admin",

    GET_STATE_LIST: "list-state",
    GET_CITY_LIST: "list-city",
    // skill
     GET_SKILL_LIST: "agency/get-all-skill",
     ADD_EDIT_SKILL: "agency/add-edit-skill",
     DELETE_SKILL: "agency/delete-skill",
    // rate add
    AGENCY_ADD_JOB_RATE: "agency/add-job-rate",
    // Supervisor and Non supervisor
    AGENCY_ADD_SUPERVISOR_NONSUPERVISOR: "agency/update-officer-type",
    // zip code
    GET_AGENCY_ZIPCODE_LIST: "agency/all-zip-code",
    // job type agency
    GET_JOBTYPE_AGENCY_LIST: "agency/all-job-type",

    // Role master
    GET_ROLE_AGENCY_LIST: "agency/get-permission-list",
    ADD_ROLE_AGENCY_MASTER: "agency/add-edit-permission",
    ROLE_AGENCY_MASTER_DELETE_PERMISSION: "agency/delete-Permission",

    // Officer management
    AGENCY_ADD_OFFICER: "agency/add-officer",
    GET_SINGLE_OFFICER: "agency/get-singleofficer",
    GET_PENDING_PROFILE: "agency/list-of-officer-for-agency-by-id",
    AGENCY_OFFICER_LIST: "agency/list-of-officer-for-agency",
    APPROVE_REJECT_OFFICER_APPLICATION: "agency/approve-by-agency",
    CHANGE_OFFICER_STATUS: "agency/change-officerstatus",
    DELETE_OFFICER: "agency/delete-officer",
    // job management
    // GET_JOB_LIST: "agency/job-list",
    GET_JOB_LIST: "agency/job-list-by-zipcode ",
    GET_SINGLE_JOB_LIST: "agency/get-job-list-by-id",
    JOB_APPROVE_REJECT: "agency/approve-reject-job",
    JOB_APPROVE_REJECT_OFFICER: "agency/job-approval-for-officer",
    GET_JOB_DETAIL_OFFICER_ACCEPT: "agency/job-for-officer-list-byId",
    // agency
    GET_AGENCY_SKILLS_LIST: "client/get-all-skill",
    //reveiw rating
    GET_REVIEW_LIST: "agency/get-review-and-rating",
    RATING_REVIEW: "agency/approve-reject-rating-review",

    // Agency User management
    AGENCY_GET_USER_LIST: "agency/list-all-user",
    AGENCY_ADD_EDIT_USER: "agency/add-edit-user",
    AGENCY_DELETE_USER: "agency/delete-user",

    // Agency Role master
    // GET_AGENCY_ROLE_LIST: "agency/get-agency-permission-list",
    GET_ROLE_LIST_AGENCY: "agency/get-agency-permission-list",
    GET_ROLE_LIST_AGENCY_SIGNUP: "agency/get-agency-permission-list1",
    ADD_AGENCY_ROLE_MASTER: "agency/add-edit-agency-permission",
    ROLE_AGENCY_DELETE_PERMISSION: "agency/delete-agency-Permission",

    // Agency get/add/edit extra work limit
    AGENCY_EXTRA_WORK_LIMIT: "agency/add-edit-extra-work-limit",
   
    // extra work permit
    GET_AGENCY_EXTRA_WORK_LIST: "agency/work-permit-list-approve-reject",

    // Transfer job
    TRANSFER_JOB_HOURS_LIMIT: "agency/add-edit-transfer-job-Hours-limit",

    // export job data Agency
    JOB_LIST_CSV_EXPORT: "agency/export-user-job-list",
    // Rating and review disable
     REVIEW_ENABLE_DISABLE: "agency/update-review",

     //Payment 
     PAYMENT_ACCOUNT_CREATE : "agency/create-payment-account",
     PAYMENT_UPDATE_CONFIG : "agency/update-payment-config",
     PAYMENT_TYPE_CHOOSE : "agency/payment-type-for-department",
     PAYMENT_OFFICER_MARKED : "agency/payment-officer-mark-paid",

     GET_NOTIFICATION_HISTORY : "agency/get-notification", 
     UPDATE_NOTIFICATION_HISTORY : "agency/isread-update-notification",
     // Supervisor
    ADD_EDIT_SUPERVISOR : "agency/edit-job-for-officer-required",
  },

  Common: {
    GET_USER_LIST: "list-user",
    GET_OFFICER_LIST: "list-officer",
    GET_ROLL_LIST: "list-role",
    GET_AGENCY_LIST: "list-agency",
    GET_DEPARTMENT_BY_AGENCY_LIST: "list-agency-rolewise",
    GET_SINGLE_AGENCY: "get-single-agency",
    DASHBOARD_COUNT: "dashboard-count",
    GET_ZIP_CODE_COMMON: "all-zip-codes",
    DASHBOARD_AGENCY_COUNT: "dashboard-count-agency",
    ADMIN_JOB_HISTORY_COMMON: "job-history-officer",
    ADMIN_AGENCY_OFFICER_HISTORY_COMMON: "get-agency-job-officer-count",
    DASHBOARD_ADMIN_OFFICER_COUNT: "dashboard-count-officer",
    


    // chat-box
    // JOIN_ROOM:"add-update-chat",
    // GET_ALL_LIST:"get-chat-list"
    JOIN_ROOM: "send-message",
    GET_ALL_LIST: "get-message",
  },
};

import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

const DetailsOfActiveJobs = () => {
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const adminActivejobsDetail = state;
  console.log("adminActivejobsDetail", adminActivejobsDetail);
  // role permission
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  // const AdminAgencyName = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const AdminAgencyName = adminLoginData?.name;
  
  return (
    <>

      <Index.Box className="user-list-flex">
        <Index.Box className="admin-page-title user-list-page-title">
          <Index.Box
            className="title-header-flex"
            onClick={() =>
              navigate("/admin/adminjobmanagement", {
                state: "ActiveJobs",
              })
            }
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            />
            {adminActivejobsDetail?.title}

          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="admin-sub-flex">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Assigned Officer & Associated Agency/Department
          </Index.Typography>
        </Index.Box>
      </Index.Box>






      <>
        {adminActivejobsDetail?.officerId.map((item, index) => {
          const officershortDetail = item?.officerId;
          { console.log(item, "item") }
          return (
            <Index.Box className="card-main">
              <Index.Box className="card-left">
                <Index.Box className="officer-profile-flex">
                  <img
                    src={PagesIndex.Png.usericon}
                    className="prof-img"
                    alt=""
                  />
                  <Index.Box className="officer-detail">
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        {officershortDetail?.firstName ? (
                          <>
                            {officershortDetail?.firstName +
                              " " +
                              officershortDetail?.lastName}
                          </>
                        ) : null}{" "}
                        &nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        ({officershortDetail?.officerType})
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Officer Assigned on :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {PagesIndex.dayjs(item?.jobAcceptedDate).format(
                          "MM-DD-YYYY"
                        )}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Officer Check-In :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {item?.jobCheckInTime && PagesIndex.dayjs(item.jobCheckInTime).isValid() ? PagesIndex.dayjs(item.jobCheckInTime).format("MM-DD-YYYY : HH:mm") : " Not checkin"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Officer Check-Out :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {item?.jobCheckOutTime && PagesIndex.dayjs(item.jobCheckOutTime).isValid() ? PagesIndex.dayjs(item.jobCheckOutTime).format("MM-DD-YYYY: HH:mm") : " Not checkout"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          );
        })}
      </>
      <Index.Box className="details-active-job-sec active-job-sec">
        {[1].map((item, index) => (
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    {/* Agency Name */}
                    {adminActivejobsDetail?.department == "Police department" ? "Associated Department" : "Associated Agency"}:&nbsp;

                  </Index.Typography>

                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminActivejobsDetail?.approveBy?.agencyName}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        ))}
      </Index.Box>




      <Index.Box className="details-active-job-sec active-job-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            General Information
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Title :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.title}
              </Index.Typography>
            </Index.Box>


            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Posted By :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.userId?.name}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Posted On :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.dayjs(adminActivejobsDetail?.createdAt).format(
                  "MM-DD-YYYY"
                )}
              </Index.Typography>
            </Index.Box>

            {
              adminActivejobsDetail?.totalAmount !== "" ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Payment :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminActivejobsDetail?.totalAmount}
                  </Index.Typography>
                </Index.Box>

              ) : null

            }


            {adminActivejobsDetail?.paymentIntentId !== "" ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Transaction Id  :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.paymentIntentId}
                </Index.Typography>
              </Index.Box>

            ) : null
            }

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Phone Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.mobileNumber}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Email Address :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.userId?.email}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Point Of Contact Name :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.pointOfContactName}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Point Of Contact Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {
                  adminActivejobsDetail?.pointOfContactNumber || "-"
                }
              </Index.Typography>
            </Index.Box>



            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Location :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.location}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Service :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.service}
              </Index.Typography>
            </Index.Box>
            {/* <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Required Skill :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminActivejobsDetail?.skills?.length > 0
                      ? adminActivejobsDetail?.skills.map((row, index) => (
                        <React.Fragment key={index}>
                              <span>{row}</span>
                              {index < adminActivejobsDetail.skills.length - 1 && (<span>, </span>)}
                        </React.Fragment>
                      
                        ))
                      : null}
                  </Index.Typography>
                </Index.Box> */}
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Department :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.department}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Type :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.jobType?.jobType}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Zip Code :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.zipCode}
              </Index.Typography>
            </Index.Box>
            {adminActivejobsDetail?.supervisor ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Supervisor :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.supervisor}
                </Index.Typography>
              </Index.Box>) : (null)}
            {adminActivejobsDetail?.nonSupervisor ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Non Supervisor :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.nonSupervisor}
                </Index.Typography>
              </Index.Box>
            ) : (null)}
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Date/Time :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {/* {jobList?.jobSchedule[0]?.jobStartTime +
                        " to " +
                        jobList?.jobSchedule[0]?.jobEndTime} */}
                {adminActivejobsDetail?.jobSchedule?.map((item, index) => {
                  return (
                    <>
                      {/* {item.isActive == true ? ( */}
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        <span className="day-title">
                          {
                            // (item?.jobStartDate)
                            PagesIndex.dayjs(item?.jobStartDate).format(
                              "MM-DD-YYYY"
                            ) +
                            " to " +
                            PagesIndex.dayjs(item?.jobEndDate).format(
                              "MM-DD-YYYY"
                            )
                          }
                        </span>{" "}
                        -{" "}
                        <span>
                          {item?.jobStartTime
                            ? item?.jobStartTime + " to "
                            : null}
                          {item?.jobEndTime}
                        </span>
                      </Index.Typography>
                      {/* ):null} */}
                    </>
                  );
                })}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Total Hours :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {/* {PagesIndex.timeDifferenceCalculation(
                adminActivejobsDetail?.jobSchedule[0]?.jobStartTime,
                adminActivejobsDetail?.jobSchedule[0]?.jobEndTime
              )} */}
                {PagesIndex.timeDifferenceCalculation(adminActivejobsDetail)} hour
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Pay Rate :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.rate ? "$" : null}
                {adminActivejobsDetail?.rate}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                No of Officer :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.totalOfficer}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Information :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.information}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Description :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                // className="work-value-text"
                className="work-value-text"
              >
                {adminActivejobsDetail?.description}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>


      <Index.Box className="details-active-job-sec active-job-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Organizer (Client)
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Name :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.userId?.name}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Mobile Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.userId?.mobileNumber}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Email Address :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminActivejobsDetail?.userId?.email}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="details-active-job-sec active-job-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Job Log
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Posted On :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.dayjs(adminActivejobsDetail?.createdAt).format(
                  "MM-DD-YYYY"
                )}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Transferred To :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                -
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Transferred On :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                -
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default DetailsOfActiveJobs;

import React, { useEffect, useRef, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import AgencyChangePasswordModal from "./AgencyChangePasswordModal";
import { ADMIN_IMAGE_ENDPOINT } from "../../../../config/DataService";
import {
  getStateList,
} from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";
import { Api } from "../../../../config/Api";
import { getAdminData } from "../../../../redux-toolkit/slice/agency-slice/AgencySlice";
import { AgencyProfileSchema } from "../../../../validation/FormikSchema";
import Loader from "../../../../common/loader/Loader";

export default function AgencyAgentProfile() {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const formRef = useRef(null)


  const [stateList, setStateList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [getstateShortName, setGetstateShortName] = useState("");
  const agencyLoginData = PagesIndex.useSelector(
    (state) => state.AgencySlice.agencyLoginData
  );
  const roleName = agencyLoginData?.roleId?.Permission_name;
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionSelected, setIsSuggestionSelected] = useState({
    state: false,
    city: false,
    zipCode: false
  })

  // 
  const [getPaymentType, setPaymentType] = useState(localStorage.getItem(`${agencyLoginData?._id}/agencypayment`))

  // Add New
  const [addnewopen, setAddNewOpen] = React.useState(false);
  const addnewhandleOpen = () => setAddNewOpen(true);
  const addnewhandleClose = () => setAddNewOpen(false);

  useEffect(() => {
    if (agencyLoginData?.address?.length) {
      setAddress(agencyLoginData?.address)
    }
  }, [agencyLoginData])

  //  phone number functionality

  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
   
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");
    
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }
    
    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
    
};
  const initialValues = {
    name:
      agencyLoginData && agencyLoginData.agencyName
        ? agencyLoginData.agencyName
        : agencyLoginData 
          ? agencyLoginData.name
          : "",
    email: agencyLoginData ? agencyLoginData?.email : "",
    address: agencyLoginData ? agencyLoginData?.address : "",
    zipCode: Object.keys(agencyLoginData).length ? agencyLoginData?.zipCode[0] : "",
    mobileNumber: agencyLoginData ? agencyLoginData?.mobileNumber : "",
    state: agencyLoginData ? agencyLoginData?.state : "",
    city: agencyLoginData ? agencyLoginData?.city : "",
    image: agencyLoginData ? agencyLoginData?.image : "",
  };


  const handleAgencyProfile = async (values) => {
    setIsLoading(true);
    console.log("values", values);
    const formdata = new FormData();
    formdata.append("agencyName", values.name);
    formdata.append("email", values.email.toLowerCase());
    formdata.append("address", values.address);
    formdata.append("mobileNumber", values?.mobileNumber);
    formdata.append("state", values.state);
    formdata.append("city", values.city);
    formdata.append("image", values.image);
    formdata.append(`zipCode`, values.zipCode);
    console.log("formdata", [...formdata]);
    try {
      const response = await PagesIndex.DataService.post(
        `${Api.Agency.AGENCY_UPDATE_PROFILE}/${agencyLoginData?._id}`,
        formdata
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message);
        setIsLoading(false);
        navigate(`/${roleName}/dashboard`);
        const data = response?.data?.data;
        dispatch(getAdminData(data));
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  // State List  Api call
  useEffect(() => {
    dispatch(getStateList()).then((res) => {
      if (res?.payload?.status == 200) {
        setStateList(res?.payload?.data);
      }
    });
  }, [dispatch]);

  // when the user change the address then used...
  const handleAddressChange = async (e) => {

    console.log(e.target.value, ":e.target.value")
    setAddress(e.target.value);
    formRef.current.setFieldValue("address", e.target.value);
    if (e.target.value?.length <= 0) {
      formRef.current.setFieldValue("address", "");
      formRef.current.setFieldValue("state", "");
      formRef.current.setFieldValue("city", "");
      formRef.current.setFieldValue("zipCode", "");
      setIsSuggestionSelected({
        state: false,
        city: false,
        zipCode: false
      })
    }
    if (e.target.value.length > 3) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${e.target.value}`
        );
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error('Error fetching address:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setAddress(suggestion.display_name);
    let stateName = suggestion.address.state || suggestion.address.state_district || "";
    let cityName = suggestion.address.city || suggestion.address.town || suggestion.address.village || "";
    let zipCode = suggestion.address.postcode || "";
    formRef.current.setFieldValue("address", suggestion.display_name);
    formRef.current.setFieldValue("state", stateName);
    formRef.current.setFieldValue("city", cityName);
    formRef.current.setFieldValue("zipCode", zipCode);
    setIsSuggestionSelected({
      state: stateName?.length ? true : false,
      city: cityName?.length ? true : false,
      zipCode: zipCode?.length ? true : false
    })
    setSuggestions([]);
  };

  return (
    <>
      <PagesIndex.Formik
        innerRef={formRef}
        validationSchema={AgencyProfileSchema}
        initialValues={initialValues}
        onSubmit={handleAgencyProfile}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit} className="profile-form-wrape">
            {console.log("error", errors)}
            <Index.Box className="dashboard-conten">
              <Index.Box className="">
                <Index.Box className="title-header-flex">
                  <Index.Link to={`/${roleName}/dashboard`}>
                    <img
                      src={PagesIndex.Svg.leftArrow}
                      className="left-arrow-ic"
                      alt="search grey img"
                    />
                  </Index.Link>
                </Index.Box>

                <Index.Box className="common-card">
                  <Index.Grid container spacing={3} justifyContent={"center"}>
                    <Index.Grid item xs={12} sm={5} md={6} lg={7}>
                      <Index.Box className="officer-profile-box">
                        <Index.Box className="profile-edit-flex">
                          <Index.Box className="profile-img-box">
                            <img
                              className="upload-prof-img"
                              src={
                                values?.image
                                  ? values?.image instanceof Blob
                                    ? URL.createObjectURL(values.image)
                                    : `${ADMIN_IMAGE_ENDPOINT}${values?.image}`
                                  : PagesIndex.Png.usericon
                              }
                              alt="profile"
                            />
                          </Index.Box>
                          <Index.Box className="profile-file-upload-main">
                            <Index.IconButton
                              color="primary"
                              className="profile-upload-btn"
                              aria-label="upload picture"
                              component="label"
                              disableRipple
                            >
                              <input
                                hidden
                                name="image"
                                accept="image/*"
                                type="file"
                                className="upload-input-details"
                                onChange={(e) => {
                                  try {
                                    if (e.target?.files?.length !== 0) {
                                      setFieldValue("image", e.target.files[0]);
                                    }
                                  } catch (error) {
                                    console.error(error);
                                    e.target.value = null;
                                  }
                                }}
                              />

                              <img
                                src={PagesIndex.Svg.editNew}
                                className="profile-edit-icon"
                                alt=""
                              />
                            </Index.IconButton>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="profile-error-box">
                          {errors.image && (
                            <Index.FormHelperText error>
                              {errors.image}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="grid-row">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                  Full Name
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Please enter full name"
                                    name="name"
                                    value={values.name}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      setFieldValue(name, value.trim());
                                      handleChange(e);
                                      setFieldTouched("name", true);
                                    }}
                                    inputProps={{ maxLength: 15 }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === " " &&
                                        e.target.value.trim() === ""
                                      ) {
                                        e.preventDefault(); // Prevent space as the first character
                                      } else if (
                                        e.target.value.trim().split(" ").length >=
                                        2 &&
                                        e.key === " "
                                      ) {
                                        e.preventDefault(); // Prevent adding additional spaces between words
                                      } else if (
                                        e.key === " " &&
                                        e.target.value.endsWith(" ")
                                      ) {
                                        e.preventDefault(); // Prevent additional spaces at the end
                                      }
                                      if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                        e.preventDefault(); // Prevent non-numeric characters
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      errors.name && touched.name
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.name && touched.name
                                        ? errors.name
                                        : null
                                    }
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                  Email Address
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Please enter email"
                                    name="email"
                                    value={values.email}
                                    disabled
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      errors.email && touched.email ? true : false
                                    }
                                    helperText={
                                      errors.email && touched.email
                                        ? errors.email
                                        : null
                                    }
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 6",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                  Phone Number
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Please enter phone number"
                                    name="mobileNumber"
                                    value={values?.mobileNumber}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handlePhoneChange(e, setFieldValue);
                                    }}
                                    inputProps={{ maxLength: 10 }}
                                    error={
                                      errors.mobileNumber && touched.mobileNumber
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.mobileNumber && touched.mobileNumber
                                        ? errors.mobileNumber
                                        : false
                                    }
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 6",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                  Payment Type
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    // placeholder="(123) 456-7890"
                                    name="payment_type"
                                    value={roleName == "Agency" ? "Security Agency" :  getPaymentType !== undefined ? getPaymentType : ''}
                                    disabled
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>



                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                  Address
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextareaAutosize
                                    fullWidth
                                    id="fullWidth"
                                    name="address"
                                    className="form-control-textarea hgt-address"
                                    placeholder="Please enter address"
                                    value={address}
                                    onChange={handleAddressChange}
                                    error={
                                      touched.address && errors.address
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched.address && errors.address
                                        ? true
                                        : false
                                    }
                                  />
                                  {suggestions.length > 0 && (
                                    <ul className="address-list">
                                      {suggestions.map((suggestion, index) => (
                                        <li
                                          key={index}
                                          onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                          {suggestion.display_name}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                  <Index.FormHelperText error>
                                    {errors.address && touched.address
                                      ? errors.address
                                      : null}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 4",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                  State
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    id="fullWidth"
                                    className="form-control w-100-full"
                                    name="state"
                                    labelId="stateName-placeholder"
                                    placeholder="Please enter state"
                                    value={values.state}
                                    disabled={isSuggestionSelected?.state ? true : false}
                                    onChange={(e) => {
                                      if (e.target.value?.length <= 30) {
                                        setFieldValue("state", e.target.value)
                                      }
                                    }}
                                    error={
                                      touched.state && errors.state
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched.state && errors.state
                                        ? true
                                        : false
                                    }
                                  >
                                  </Index.TextField>
                                  <Index.FormHelperText error>
                                    {errors.state && touched.state
                                      ? errors.state
                                      : null}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 4",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                  City
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    id="fullWidth"
                                    className="form-control w-100-full"
                                    name="city"
                                    labelId="stateName-placeholder"
                                    placeholder="Please enter city"
                                    value={values.city}
                                    disabled={isSuggestionSelected?.city ? true : false}
                                    onChange={(e) => {
                                      if (e.target.value?.length <= 30) {
                                        setFieldValue("city", e.target.value)
                                      }
                                    }}
                                    error={
                                      touched.city && errors.city
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched.city && errors.city
                                        ? true
                                        : false
                                    }
                                  >
                                  </Index.TextField>
                                  <Index.FormHelperText error>
                                    {errors.city && touched.city
                                      ? errors.city
                                      : null}
                                  </Index.FormHelperText>
                                </Index.Box>

                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 4",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                  Zip Code
                                </Index.FormHelperText>
                                <Index.Box className="form-group">

                                  <Index.TextField
                                    id="fullWidth"
                                    className="form-control w-100-full"
                                    name="zipCode"
                                    labelId="stateName-placeholder"
                                    placeholder="Please enter zip code"
                                    value={values.zipCode}
                                    disabled={isSuggestionSelected?.zipCode ? true : false}
                                    onChange={(e) => {
                                      const numberPattern = /^[0-9-]+$/
                                      if (e.target.value.length <= 0) {
                                        setFieldValue(`zipCode`, "");
                                      } else if (numberPattern.test(e.target.value) && e.target.value.length <= 9) {
                                        setFieldValue(`zipCode`, e.target.value);
                                      }
                                    }}
                                    error={
                                      touched.zipCode && errors.zipCode
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched.zipCode && errors.zipCode
                                        ? true
                                        : false
                                    }
                                  />
                                  <Index.FormHelperText error>
                                    {errors.zipCode && touched.zipCode
                                      ? errors.zipCode
                                      : null}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 3",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="modal-user-btn-flex" sx={{ marginTop: "0px" }}>
                                <Index.Box className="save-btn-main border-btn-main">
                                  <Index.PrimaryButton
                                    type="submit"
                                    btnLabel="Update"
                                    className="btn-primary bg-approve"
                                    disabled={isLoading}
                                  >
                                    {isLoading ? <Loader /> : "Click me"}
                                  </Index.PrimaryButton>
                                </Index.Box>
                                <Index.Box className="save-btn-main">
                                  <Index.PrimaryButton
                                    btnLabel="Cancel"
                                    className="btn-primary bg-suspend"
                                    onClick={() => navigate(`/${roleName}/dashboard`)}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 3",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="change-pass-text-main" justifyContent={"center"} marginTop={"0px"}>
                                <Index.Link
                                  className="change-pass-link"
                                  onClick={addnewhandleOpen}
                                >
                                  Want to change password ?
                                </Index.Link>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>

              </Index.Box>

              {/* Add New User modal */}

              <AgencyChangePasswordModal
                addnewhandleClose={addnewhandleClose}
                addnewopen={addnewopen}
              />
            </Index.Box>
          </form>
        )}
      </PagesIndex.Formik >
    </>
  );
}

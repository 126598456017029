import React, { useState } from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import { SwipeableDrawer } from "@mui/material";
import DataService, {
  ADMIN_IMAGE_ENDPOINT,
} from "../../../../config/DataService";
import AddNewUser from "./user-manage-modal/AddNewUser";
import EditNewUser from "./user-manage-modal/EditNewUser";
import { getUserList } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import UserDeleteModal from "./user-manage-modal/UserDeleteModal";
import { getAgencyUserList } from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";
// import AdminUserRestrict from "./user-manage-modal/AdminUserRestrict";

function UserManage(props) {
  const [isActive, setActive] = useState(Array(0).fill(false));
  const [searchValue, setSearchValue] = useState("");
  
  const toggleMenuClass = (index) => {
    const updatedStates = [...isActive];
    updatedStates[index] = !updatedStates[index];
    setActive(updatedStates);
  };

  const dispatch = PagesIndex.useDispatch();

  // add user modal
  const [open, setOpen] = useState(false);
  const [userListData, setUserListData] = useState(false);
  const [editUserData, setEditUserData] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // Add user Modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditUserData("");
  };
  // Edit User Details modal
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleEditOpen = () => {
    setOpenEditModal(true);
  };
  const handleCloseEdit = () => setOpenEditModal(false);

  // Restrict modal
  const [restrictUserStatus, setRestrictUserStatus] = useState(false);
  const [restrictUserModalopen, setRestrictUserModalOpen] = useState(false);
  const restrictUserhandleOpen = (userId, status) => {
    const data = {
      userId: userId,
      status: !status,
    };
    setRestrictUserModalOpen(true);
    setRestrictUserStatus(data);
  };
  const restrictUserhandleClose = () => {
    setRestrictUserModalOpen(false);
  };

  // Delete functionality
  const [deleteUserId, setDeleteUserId] = useState([]);
  const [openDeleteNew, setOpenDeleteNew] = useState(false);
  const handleOpenDeleteNew = () => setOpenDeleteNew(true);
  const handleCloseDeleteNew = () => setOpenDeleteNew(false);

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // get user list
  const getUserListData = () => {
    dispatch(getAgencyUserList()).then((res) => {
      if (res?.payload?.status == 200) {
        setUserListData(res?.payload?.data);
        setFilteredData(res?.payload?.data);
      }
    });
  };

  PagesIndex.useEffect(() => {
    getUserListData();
  }, []);

  // Search on table
  const requestSearch = (e) => {
    setSearchValue(e?.target?.value);
    const filteredData = userListData?.filter((data) =>
      `${data?.name.toLowerCase()}${data?.mobileNumber
        } ${data?.email.toLowerCase()}`.includes(e?.target?.value?.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  
  return (
    <div>
      <Index.Box className="user-manage-page">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            System Agency Management
          </Index.Typography>

          {/* Top search */}
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                    onKeyDown={(e) => {
                      if (
                        e.key === " " &&
                        e.target.value.trim() === ""
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            
            {/* Add user Button */}
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add Sub Admin
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        {/* Edit User Details End */}

        <Index.Box className="tabpanel-main">
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <Index.Box className="card-main">
                <Index.Box className="card-left">
                  <Index.Box className="officer-profile-flex">
                    <img
                      src={
                        item?.image
                          ? `${ADMIN_IMAGE_ENDPOINT}${item?.image}`
                          : PagesIndex.Png.usericon
                      }
                      alt=""
                      className="prof-img"
                    />

                    <Index.Box className="officer-detail">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-title"
                      >
                        {item?.name}
                      </Index.Typography>

                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-email"
                      >
                        {item?.email}
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-numebr"
                      >
                        {item?.mobileNumber}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="card-right btn-main-secondary btn-main-red btn-main-orange btn-main-yellow btn-main-green">

                  <Index.Link
                    to=""
                    onClick={() => toggleMenuClass(index)}
                    className="more-btn"
                  >
                    <img src={PagesIndex.Svg.dotmenu} alt="" />
                  </Index.Link>
                  <Index.Box
                    id="btn-toggle-popup"
                    className={
                      isActive[index] ? "toggle-pop-show" : null
                    }
                  >



                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        btnLabel="Edit"
                        className="btn-orange"
                        onClick={() => {
                          handleOpen();
                          setEditUserData(item);
                        }}
                      >
                        <img src={PagesIndex.Svg.editNew} alt="delete" className="admin-icons" />
                      </Index.Button>
                    </Index.Tooltip>

                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        btnLabel="delete"
                        className="btn-red"
                        onClick={() => {
                          handleOpenDeleteNew();
                          setDeleteUserId(item?._id);
                        }}
                      >
                        <img src={PagesIndex.Svg.deletewhite} alt="delete" className="admin-icons" />
                      </Index.Button>
                    </Index.Tooltip>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            ))
          ) : (
            <PagesIndex.NoDataFound />
          )}
        </Index.Box>
      </Index.Box>

      {/* Add New User modal */}

      <AddNewUser
        getUserListData={getUserListData}
        handleClose={handleClose}
        open={open}
        editUserData={editUserData}
      />

      {/* End Add New User */}
      {/* Edit User Details start */}
      <EditNewUser
        getUserListData={getUserListData}
        handleCloseEdit={handleCloseEdit}
        openEditModal={openEditModal}
        editUserData={editUserData}
      />

      {/* Restrict User modal */}
      {/* <AdminUserRestrict
        restrictUserModalopen={restrictUserModalopen}
        restrictUserhandleClose={restrictUserhandleClose}
        restrictUserStatus={restrictUserStatus}
        getUserListData={getUserListData}
      /> */}
      {/* delete modal */}
      <UserDeleteModal
        handleCloseDeleteNew={handleCloseDeleteNew}
        openDeleteNew={openDeleteNew}
        getUserListData={getUserListData}
        deleteUserId={deleteUserId}
      />
    </div>
  );
}

export default UserManage;

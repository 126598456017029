import * as Yup from "yup";

const passwordRegex =
  // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/;
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\\|[\]{};:'",.<>/?]).{8,16}$/;

const nameRegex = /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/;
// const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/;
// const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})(?!com))$/;

const emailRegex = /^(?!.*\.com\.)[\w.-]+@[\w.-]+\.[a-zA-Z]{2,}$/;

// const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+(\.\w{2,3})+$/;
const phoneRegExp =
  /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{3,})$/;
const otpRegex = /^\d{4}$/;
const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;
const START_SPACE = /^(?!\s).*$/;

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};

// // Validation EditProfile
const digitsOnly = (value) =>
  /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;
const mobilePattern = (value) =>
  /^[1-9][0-9]*$/.test(value) || value.length === 0;
const textAndSpaceOnly = (value) =>
  /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;

// Admin Schema Validations
export const adminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  password: Yup.string()
    // .matches(
    //   passwordRegex,
    //   "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character."
    // )
    .required("Please enter your password"),
  // .min(8, "Password must be at least 8 characters")
  // .max(16, "Password must be at most 16 characters"),
  // .matches(/[0-9]/, getCharacterValidationError("digit"))
  // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
  // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
  // .matches(/[^\w\s]/, getCharacterValidationError("special")),
});

export const adminForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address"),
});

export const adminOtpVerfiySchema = Yup.object().shape({
  otp: Yup.string()
    .required("Please enter your OTP")
    .matches(otpRegex, "Please enter valid OTP"),
});

export const adminResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string("Please enter New password")
    .required("Please enter new password")
    .matches(
      passwordRegex,
      "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character"
    )
    .min(8, "New password must be 8 characters long")
    .max(16, "New password must be at most 16 characters"),
});

export const adminProfileUpdateSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter full name")
    .min(2, "Full name must be at least 2 characters")
    .max(15, "Full name must be at most 15 characters")
    // .test("valid-name", "Please enter a valid name", (value) => {
    //   if (!value) return true; // Allow empty string

    //   // Check for leading or trailing spaces
    //   if (/^\s|\s$/.test(value)) {
    //     return false;
    //   }

    //   if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
    //     return false
    //   }

    //   // If all checks pass, return true
    //   return true;
    // }),
    .test("no-consecutive-spaces", "Please enter valid full name", (value) => {
      if (!value) return true; // Allow empty string
      return !/\s{2,}/.test(value); // Check for consecutive spaces
    })
    .test("no-leading-space", "Please enter a valid full name", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Please enter a valid full name", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test("single-spaces", "Please enter a valid full name", (value) => {
      if (!value) return true; // Allow empty string
      return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
    }),
  email: Yup.string()
    .required("Please enter email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string("Please enter your Phone number")
    .min(10, "Phone number must be 10 digits")
    .required("Please enter your phone number"),
  // .min(10, "Phone number must be 10 digit long"),
  // image: Yup
  // .mixed()
  // .nullable()
  // .required("Profile is required")
  // .test(
  //   "FILE_FORMAT",
  //   "Allow only jpg,jpeg,png file",
  //   (value) =>
  //     !value || (value!=="" &&
  //     (["jpg", "jpeg", "png"].includes(
  //       value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
  //     ) &&
  //       // ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
  //       ["jpg", "jpeg", "png"].includes(value?.type))
  // ))
  // .test(
  //   "fileSize",
  //   "File size too large, max file size is 2.048 Mb",
  //   (file) => {
  //     if (file) {
  //       return file.size <= 2048000;
  //     } else {
  //       return true;
  //     }
  //   }
  // ),

  image: Yup.mixed()
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"];
            let fileFormat = value?.split(".");
            return format?.includes(fileFormat[fileFormat?.length - 1]);
          }
        } else {
          return true;
        }
      }
    )
    .test(
      "FILE_SIZE",
      "File size too large, max file size is 2.048 Mb",
      (value) => {
        if (value?.size != undefined) {
          return !value || (value && value?.size <= 2048000);
        } else {
          return true;
        }
      }
    ),
});

export const adminchangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please enter old password"),
  // .matches(
  //   passwordRegex,
  //   "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character (e.g: John@1234)"
  // )
  // .min(8, "Old pssword must be 8 characters long")
  // .max(16, "Old password cannot exceed 16 characters"),
  newPassword: Yup.string()
    .required("Please enter new password")
    .matches(passwordRegex, "(e.g: John@1234)")
    // .matches(/[0-9]/, "New password requires a number")
    // .matches(/[a-z]/, "New password requires a lowercase letter")
    // .matches(/[A-Z]/, "New password requires an uppercase letter")
    // .matches(/[^\w]/, "New password requires a special character")
    .min(8, "New password must be 8 characters long")
    .max(16, "New password cannot exceed 16 characters"),
  confirmPassword: Yup.string()
    .required("Please re-enter new password")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Re-enter new password is not matched with new password"
    ),
});

// zipcode
export const zipCodeAddSchema = Yup.object().shape({
  zipCode: Yup.string()
    .required("Please enter your zip code")
    .min(5, "Zip code must be 5 digits long"),
});
// job type
export const jobTypeAddSchema = Yup.object().shape({
  jobType: Yup.string("Please enter your job type")
    .test("no-consecutive-spaces", "Please enter valid job type", (value) => {
      if (!value) return true; // Allow empty string
      return !/\s{2,}/.test(value); // Check for consecutive spaces
    })
    .test("no-leading-space", "Please enter valid job type", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Please enter valid job type", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test("single-spaces", "Please enter valid job type", (value) => {
      if (!value) return true; // Allow empty string
      return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
    })
    .min(2, "Job type must be at least 2 characters")
    .max(30, "Job type must be at most 30 characters")
    .matches(nameRegex, "Please enter valid job type")
    .required("Please enter your job type"),
}); // job type
export const skillsAddSchema = Yup.object().shape({
  skill: Yup.string("Please enter your skill")
  .required("Please enter your skill")
  .test("valid-name", "Please enter a valid name", (value) => {
    if (!value) return true; // Allow empty string

    // Check for leading or trailing spaces
    if (/^\s|\s$/.test(value)) {
      return false;
    }

    if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
      return false;
    }

    // If all checks pass, return true
    return true;
  })
  .matches(/^[a-zA-Z0-9\s]*$/, "Special characters is not allow")
  .min(2, "Skill must be at least 2 characters")
    // .matches(/^[a-zA-Z0-9\s]*$/, "Please enter valid skill")
    // .min(2, "Skill must be at least 2 characters")
    .max(30, "Skill must be at least 30 characters"),
    
});

// Admin officer Schema
export const adminAddOfficerSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    // .matches(START_SPACE, "Start space not allowed")
    // .matches(ALPHA_NUMERIC_DASH_REGEX, "First name contains only alphabate")
    .min(2, "First name must be 2 characters long")
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed")
    .max(25, "First name cannot exceed 25 characters"),
  lastName: Yup.string()
    .required("Please enter your last name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    // .matches(START_SPACE, "Start space not allowed")
    // .matches(ALPHA_NUMERIC_DASH_REGEX, "Last name contains only alphabate")
    .min(2, "Last name must be 2 characters long")
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters is not allow")
    .max(25, "Last name cannot exceed 25 characters"),
  mobileNumber: Yup.string()
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  // .matches(/^[1-9][0-9]*$/,"Please enter valid phone number")
  // .test("inputEntry", "Phone number should have digits only", digitsOnly)
  // .test("inputEntry", "Phone number is not valid", mobilePattern)
  // .min(10, "Phone number minimum length must be 10")
  // .max(12, "Phone number maximum length must be 12"),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  // zipCode: Yup.string("Please enter your zip code")
  //   .matches(/^(?!0+$)\d+$/, "Zip code should not be all zero")
  //   .required("Please enter your zip code")
  //   .min(5, "Zip code must be 5 digit long"),
  agencyId: Yup.string().required("Please select agency "),
  officerType: Yup.string("Please select officer type").required(
    "Please select officer type"
  ),
  password: Yup.string("Please enter your password")
    .required("Please enter your password")
    .matches(
      passwordRegex,
      "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character."
    )
    // .matches(/[0-9]/, getCharacterValidationError("digit"))
    // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    // .matches(/[^\w\s]/, getCharacterValidationError("special"))
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters"),
  confirmPassword: Yup.string()
    .required("Please enter your confirm password")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password is not match with password"
    ),
});

// const timeToMilliseconds = (time) => {
//   console.log("303",time)
//   const [hours, minutes] = time.split(":").map(Number);
//   return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
// };

const timeToMilliseconds = (time) => {
  if (!time) {
    return 0; // or some other default value or error handling
  }
  const [hours, minutes] = time.split(":").map(Number);
  return (hours * 60 + minutes) * 60 * 1000;
};

export const editOfficerDetailSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    // .matches(ALPHA_NUMERIC_DASH_REGEX, "First name contains only alphabate")
    .min(2, "First name must be 2 characters long")
    // .max(15, "First name cannot exceed 15 characters"),
    .max(15, "Please enter valid first name"),
  lastName: Yup.string()
    .required("Please enter your last name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    // .matches(START_SPACE, "Start space not allowed")
    // .matches(ALPHA_NUMERIC_DASH_REGEX, "Last name contains only alphabate")
    .min(2, "Last name must be 2 characters long")
    // .max(15, "Last name cannot exceed 15 characters"),
    .max(15, "Please enter valid last name"),

  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string("Please enter your phone number")
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  skills: Yup.array()
    .of(Yup.string("Please select your skill"))
    .min(1, "Please select at least one skill")
    .required("Please enter your skill"),
  jobType: Yup.array().min(1, "Please select at least one job type "),
  // zipCode: Yup.string()
  // .of(Yup.string("Please enter your zipCode"))
  // .min(1, "Please select at least one zipCode")
  // .required("Please enter your zipCode"),
  // category: Yup.string("Please enter your category").required(
  //   "Please enter your category"
  // ),
  // rank: Yup.string("Please enter your rank").required("Please enter your rank"),
  // badgeNumber: Yup.string("Please enter your badge number").required(
  //   "Please enter your badge number"
  // ),
  // yearsOnDuty:Yup.string()
  // .required("Please enter your years on duty"),
  monthsOnDuty: Yup.string().required("Please select time on duty"),
  rate: Yup.string()
    .required("Please enter required pay rate")
    .max(5, "Pay rate cannot exceed 5 digits"),
  //  days: Yup.array().of(
  //   Yup.object().shape({
  //     isActive: Yup.boolean(),
  //     startTime: Yup.string().when('isActive',{
  //       is:true,
  //       then:()=> Yup.string().required('Please enter start time')
  //     }),
  //     endTime: Yup.string().when('isActive',{
  //       is:true,
  //       then:()=> Yup.string().required('Please enter end time')
  //     })
  //   })
  // ),

  //Working.
  days: Yup.array().of(
    Yup.object().shape({
      isActive: Yup.boolean(),
      startTime: Yup.string().when("isActive", {
        is: true,
        then: (schema) =>
          schema
            .required("Please enter start time")
            .test(
              "valid-time",
              "Start time must be between 12:00 AM and 11:59 PM",
              (value) => {
                const milliseconds = timeToMilliseconds(value);
                return (
                  milliseconds >= 0 &&
                  milliseconds <= 23 * 60 * 60 * 1000 + 59 * 60 * 1000
                );
              }
            ),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
      endTime: Yup.string()
        .when("isActive", {
          is: true,
          then: (schema) =>
            schema
              .required("Please enter end time")
              .test(
                "valid-time",
                "End time must be between 12:00 AM and 11:59 PM",
                (value) => {
                  const milliseconds = timeToMilliseconds(value);
                  return (
                    milliseconds >= 0 &&
                    milliseconds <= 23 * 60 * 60 * 1000 + 59 * 60 * 1000
                  );
                }
              ),
          otherwise: (schema) => schema.notRequired().nullable(),
        })
        .when(["isActive", "startTime"], {
          is: (isActive, startTime) => isActive && !!startTime,
          then: (schema) =>
            schema.test(
              "end-time-after-start-time",
              "End time must be at least 1 hour after start time",
              function (endTime) {
                const { startTime } = this.parent;
                const startMilliseconds = timeToMilliseconds(startTime);
                const endMilliseconds = timeToMilliseconds(endTime);

                return endMilliseconds - startMilliseconds >= 60 * 60 * 1000;
              }
            ),
        }),
    })

    //     .when("startTime", {
    //       is: (startTime) => startTime,
    //       then: (schema) =>
    //         schema.test(
    //           "end-time-after-start-time",
    //           "End time must be at least 1 hour after start time",
    //           function (endTime) {
    //             const { startTime } = this.parent;
    //             const startMilliseconds = timeToMilliseconds(startTime);
    //             const endMilliseconds = timeToMilliseconds(endTime);

    //             return endMilliseconds - startMilliseconds >= 60 * 60 * 1000;
    //           }
    //         ),
    //     }),
    // })
  ),

  // days: Yup.array().of(
  //   Yup.object().shape({
  //     isActive: Yup.boolean(),
  //     startTime: Yup.string().when("isActive", {
  //       is: true,
  //       then: (schema) =>
  //         schema
  //           .required("Please enter start time")
  //           .test(
  //             "valid-time",
  //             "Start time must be between 12:00 AM and 11:59 PM",
  //             (value) => {
  //               const milliseconds = timeToMilliseconds(value);
  //               return (
  //                 milliseconds >= 0 &&
  //                 milliseconds <= 23 * 60 * 60 * 1000 + 59 * 60 * 1000
  //               );
  //             }
  //           ),
  //       otherwise: (schema) => schema.notRequired(),
  //     }),
  //     endTime: Yup.string().when("isActive", {
  //       is: true,
  //       then: (schema) =>
  //         schema
  //           .required("Please enter end time")
  //           .test(
  //             "valid-time",
  //             "End time must be between 12:00 AM and 11:59 PM",
  //             (value) => {
  //               const milliseconds = timeToMilliseconds(value);
  //               return (
  //                 milliseconds >= 0 &&
  //                 milliseconds <= 23 * 60 * 60 * 1000 + 59 * 60 * 1000
  //               );
  //             }
  //           ),
  //       otherwise: (schema) => schema.notRequired(),
  //     }).when("startTime", {
  //       is: (startTime) => !!startTime,
  //       then: (schema) =>
  //         schema.test(
  //           "end-time-after-start-time",
  //           "End time must be at least 1 hour after start time",
  //           function (endTime) {
  //             const { startTime } = this.parent;
  //             const startMilliseconds = timeToMilliseconds(startTime);
  //             const endMilliseconds = timeToMilliseconds(endTime);

  //             return endMilliseconds - startMilliseconds >= 60 * 60 * 1000;
  //           }
  //         ),
  //       otherwise: (schema) => schema.notRequired(),
  //     }),
  //   })
  // ),

  // days : Yup.array().of(
  //   Yup.object().shape({
  //     isActive: Yup.boolean(),
  //     startTime: Yup.string().when("isActive", {
  //       is: true,
  //       then: (schema) =>
  //         schema
  //           .required("Please enter start time")
  //           .test(
  //             "valid-time",
  //             "Start time must be between 12:00 AM and 11:59 PM",
  //             (value) => {
  //               const milliseconds = timeToMilliseconds(value);
  //               return (
  //                 milliseconds >= 0 &&
  //                 milliseconds <= 23 * 60 * 60 * 1000 + 59 * 60 * 1000
  //               );
  //             }
  //           ),
  //       otherwise: (schema) => schema.notRequired(),
  //     }),
  //     endTime: Yup.string().when("isActive", {
  //       is: true,
  //       then: (schema) =>
  //         schema
  //           .required("Please enter end time")
  //           .test(
  //             "valid-time",
  //             "End time must be between 12:00 AM and 11:59 PM",
  //             (value) => {
  //               const milliseconds = timeToMilliseconds(value);
  //               return (
  //                 milliseconds >= 0 &&
  //                 milliseconds <= 23 * 60 * 60 * 1000 + 59 * 60 * 1000
  //               );
  //             }
  //           ),
  //       otherwise: (schema) => schema.notRequired(),
  //     }).when("startTime", {
  //       is: (startTime) => startTime,
  //       then: (schema) =>
  //         schema.test(
  //           "end-time-after-start-time",
  //           "End time must be at least 1 hour after start time",
  //           function (endTime) {
  //             const { startTime } = this.parent;
  //             const startMilliseconds = timeToMilliseconds(startTime);
  //             const endMilliseconds = timeToMilliseconds(endTime);

  //             return endMilliseconds - startMilliseconds >= 60 * 60 * 1000;
  //           }
  //         ),
  //       otherwise: (schema) => schema.notRequired(),
  //     }),
  //   })
  // ),

  // days: Yup.array().of(
  //   Yup.object().shape({
  //     isActive: Yup.boolean(),
  //     startTime: Yup.string().test({
  //       name: 'validateStartTime',
  //       exclusive: true,
  //       test: function (value) {
  //         const isTouched = this.parent.touched && this.parent.touched.days && this.parent.touched.days[this.path];
  //         return (this.parent.isActive && isTouched) ? !!value : true;
  //       },
  //       message: 'Start time is required when the switch is on',
  //     }),
  //     endTime: Yup.string().test({
  //       name: 'validateEndTime',
  //       exclusive: true,
  //       test: function (value) {
  //         const isTouched = this.parent.touched && this.parent.touched.days && this.parent.touched.days[this.path];
  //         return (this.parent.isActive && isTouched) ? !!value : true;
  //       },
  //       message: 'End time is required when the switch is on',
  //     }),
  //   })
  // ),
});
export const adminOfficerFilterSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter name")
    .matches(START_SPACE, "Start space not allowed")
    .matches(ALPHA_NUMERIC_DASH_REGEX, "Name contains only alphabate")
    .min(2, "Name must be 2 characters long")
    .max(100, "Name cannot exceed 100 characters")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    }),
  city: Yup.string().required("Please enter city"),
});

// officer cms schema
export const officerPrivacyPolicySchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter your title")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  description: Yup.string()
    .matches(START_SPACE, "Start space not allowed")
    .required("Please enter your content")
    .min(2, "Content must be 2 characters long")
    .max(1000, "Content cannot exceed 1000 characters"),
});
export const officerTermsAndConditionSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter your title")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  description: Yup.string()
    .matches(START_SPACE, "Start space not allowed")
    .required("Please enter your content")
    .min(2, "Content must be 2 characters long")
    .max(1000, "Content cannot exceed 1000 characters"),
});
export const officerRefundPolicySchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter your title")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  description: Yup.string()
    .matches(START_SPACE, "Start space not allowed")
    .required("Please enter your content")
    .min(2, "Content must be 2 characters long")
    .max(1000, "Content cannot exceed 1000 characters"),
});

// user cms schema
export const userPrivacyPolicySchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter your title")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  description: Yup.string()
    .matches(START_SPACE, "Start space not allowed")
    .required("Please enter your content")
    .min(2, "Content must be 2 characters long")
    .max(1000, "Content cannot exceed 1000 characters"),
});
export const userTermsAndConditionSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter your title")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  description: Yup.string()
    .matches(START_SPACE, "Start space not allowed")
    .required("Please enter your content")
    .min(2, "Content must be 2 characters long")
    .max(1000, "Content cannot exceed 1000 characters"),
});
export const userRefundPolicySchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter your title")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  description: Yup.string()
    .matches(START_SPACE, "Start space not allowed")
    .required("Please enter your content")
    .min(2, "Content must be 2 characters long")
    .max(1000, "Content cannot exceed 1000 characters"),
});

// Admin Agenct schema
export const adminAddAgentSchema = Yup.object().shape({
  agencyName: Yup.string()
    .min(2, "Agency/department name must be 2 characters long")
    .required("Please enter your agency/department name")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .max(50, "Agency/department name cannot exceed 50 characters"),
  roleName: Yup.string().required("Please select your role"),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string("Please enter your phone number")
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  zipCode: Yup.string()
    .required("Please enter your zip code")
    .min(5, "Zip code must be at least 5 numbers")
    .max(9, "Zip code maximum 9 numbers are allowed"),
  // .min(5, "Zip code must be 5 digit long"),
  city: Yup.string("Please enter your city")
    .required("Please enter your city")
    .min(2, "City name must be at least 2 characters"),
  state: Yup.string("Please enter your state")
    .required("Please enter your state")
    .min(2, "State name must be at least 2 characters"),
  address: Yup.string("Please enter your address")
    .required("Please enter your address")
    .test("no-leading-space", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test("single-spaces", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string
      return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
    })
    .matches(START_SPACE, "Please enter valid address")
    // .matches(/^[a-zA-Z0-9 ]*[^@#^!%]*$/, "Special charector not allowed")
    .min(2, "Address must be at least 3 characters"),
  // .max(50, "Address cannot exceed 500 characters"),
  password: Yup.string("Please enter your password")
    .required("Please enter your password")
    .matches(
      passwordRegex,
      "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character"
    )
    // .matches(/[0-9]/, getCharacterValidationError("digit"))
    // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    // .matches(/[^\w\s]/, getCharacterValidationError("special"))
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters"),
  confirmPassword: Yup.string()
    .required("Please enter your confirm password")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password is not match with password"
    ),
});

export const adminEditAgentSchema = Yup.object().shape({
  agencyName: Yup.string("Please enter your agency name")
    .min(2, "Agency name must be 2 characters long")
    .matches(/^[^\s].*$/, "Agency name cannot start with a space")
    .required("Please enter your agency name")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .max(30, "Agency name cannot exceed 30 characters"),
  roleName: Yup.string().required("Please select your role"),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string("Please enter your Phone number")
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .required("Please enter your Phone number")
    .min(10, "Phone number must be 10 digits"),
  // .min(10, "Mobile number must be 10 digit long"),
  // weekWorkLimit: Yup.string()
  //   // .matches(/^([01]\d|2[0-2])$/, 'Time must be in the format HH')
  //   .required('Week work limit is required'),
  // dayWorkLimit: Yup.string()
  //   // .matches(/^([01]\d|2[0-2])$/, 'Time must be in the format HH')
  //   .required('Day work limit is required'),
  zipCode: Yup.string()
    .required("Please enter your zip code")
    .min(5, "Zip code must be at least 5 numbers")
    .max(9, "Zip code maximum 9 numbers are allowed"),
  city: Yup.string("Please enter your city")
    .required("Please enter your city")
    .min(2, "City name must be at least 2 characters"),
  state: Yup.string("Please enter your state")
    .required("Please enter your state")
    .min(2, "State name must be at least 2 characters"),
  address: Yup.string("Please enter your address")
    .required("Please enter your address")
    .test("no-leading-space", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test("single-spaces", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string
      return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
    })
    .matches(START_SPACE, "Please enter valid address")
    .matches(/^[a-zA-Z0-9 ]*[^@#^!%]*$/, "Please enter valid address")
    .min(2, "Address must be at least 3 characters"),
});

// Agency Schema Validations

export const agencyRateSchema = Yup.object().shape({
  rate: Yup.number().required("Please enter pay rate"),
});
// Select supervisor and non supervisor Schema Validations

export const agencySelectSuperNsuperSchema = Yup.object().shape({
  supervisorType: Yup.string().required(
    "Please select supervisor/non supervisor"
  ),
});

// Supervisor Schema Validations
export const agencySupervisorSchema = Yup.object().shape({
  supervisor: Yup.number().required("Please enter supervisor"),
  nonSupervisor: Yup.number().required("Please enter non-supervisor"),
});

// Non Supervisor Schema Validations
// export const agencyNonSupervisorSchema = Yup.object().shape({
//   nonSupervisor: Yup.number()
//     .required("Please enter non-supervisor"),
// });

export const AddRemarkSchema = Yup.object().shape({
  textarea: Yup.string()
    // .matches(/^[0-9]*$/, "Special characters is not allow")
    .required("Please enter remark")
    // .min(8, "Password must be at least 8 characters")
    .max(200, "Remark must be at most 200 characters"),
});

export const AgencyworkPermitSchema = Yup.object().shape({
  dayWorkLimit: Yup.string()
    // .matches(/^([01]\d|2[0-2])$/, 'Time must be in the format HH')
    .required("Day work limit is required"),
  weekWorkLimit: Yup.string()
    // .matches(/^([01]\d|2[0-2])$/, 'Time must be in the format HH')
    .required("Week work limit is required"),
  // dayWorkLimit : Yup.number()
  // .required("Please enter day work limit")
});
export const AgencyEditWorkPermitSchema = Yup.object().shape({
  weekWorkLimit: Yup.string()
    // .matches(/^([01]\d|2[0-2])$/, 'Time must be in the format HH')
    .required("Week work limit is required"),
  dayWorkLimit: Yup.string()
    // .matches(/^([01]\d|2[0-2])$/, 'Time must be in the format HH')
    .required("Day work limit is required"),
});

export const AgencyTransferjobSchema = Yup.object().shape({
  jobTransferHours: Yup.string()
    .required("Please enter the hours limit")
    .matches(/^(?!0+$)\d+$/, "Zero value is not allowed"),
});
export const AgencyEditTransferjobSchema = Yup.object().shape({
  jobTransferHours: Yup.string()
    .required("Please enter the hours limit")
    .matches(/^(?!0+$)\d+$/, "Zero value is not allowed"),
});

export const AgencyloginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  password: Yup.string().required("Please enter your password"),
  // .matches(
  //   passwordRegex,
  //   "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character."
  // )
  // .matches(/[0-9]/, getCharacterValidationError("digit"))
  // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
  // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
  // .matches(/[^\w\s]/, getCharacterValidationError("special"))
});

export const AgencyForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
});

export const AgencyProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your full name")
    .min(2, "Full name must be at least 2 characters")
    .max(15, "Full name at most 15 characters")
    // .test("valid-name", "Please enter a valid name", (value) => {
    //   if (!value) return true; // Allow empty string

    //   // Check for leading or trailing spaces
    //   if (/^\s|\s$/.test(value)) {
    //     return false;
    //   }

    //   if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
    //     return false
    //   }

    //   // If all checks pass, return true
    //   return true;
    // })
    .test("no-consecutive-spaces", "Please enter valid full name", (value) => {
      if (!value) return true; // Allow empty string
      return !/\s{2,}/.test(value); // Check for consecutive spaces
    })
    .test("no-leading-space", "Please enter a valid full name", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Please enter a valid full name", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test("single-spaces", "Please enter a valid full name", (value) => {
      if (!value) return true; // Allow empty string
      return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
    }),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string("Please enter your Phone number")
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  zipCode: Yup.string()
    .required("Please enter your zip code")
    .min(5, "Zip code must be at least 5 numbers")
    .max(9, "Zip code maximum 9 numbers are allowed"),
  city: Yup.string("Please enter your city")
    .required("Please enter your city")
    .min(2, "City name must be at least 2 characters")
    .max(30, "City name maximum 15 characters are allowed")
    .test("no-leading-space", "Leading space are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing space are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single space between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  state: Yup.string("Please enter your state")
    .required("Please enter your state")
    .min(2, "State name must be at least 2 characters")
    .max(30, "City name maximum 15 characters are allowed")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  address: Yup.string()
    .required("Please enter your address")
    .test("no-consecutive-spaces", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string
      return !/\s{2,}/.test(value); // Check for consecutive spaces
    })
    .test("no-leading-space", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string after trimming
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string after trimming
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    // .matches(/^[a-zA-Z0-9 ]*[$%^&*()_+!#~#`{}[]{}]*$/, " reg Please enter valid address")
    .min(3, "Address must be at least 3 characters long"),
  // .max(500, "Address cannot exceed 500 characters"),
  image: Yup.mixed()
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"];
            let fileFormat = value?.split(".");
            return format?.includes(fileFormat[fileFormat?.length - 1]);
          }
        } else {
          return true;
        }
      }
    )
    .test(
      "FILE_SIZE",
      "File size too large, max file size is 2.048 Mb",
      (value) => {
        if (value?.size != undefined) {
          return !value || (value && value?.size <= 2048000);
        } else {
          return true;
        }
      }
    ),
});

export const AgencyotpSchema = Yup.object().shape({
  otp: Yup.string()
    .required("Please enter OTP")
    .matches(otpRegex, "Please enter valid OTP"),
});

export const agencyResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string("Please enter your new password")
    .required("Please enter your new password")
    .matches(passwordRegex, "(e.g: John@1234)")
    // .matches(/[0-9]/, getCharacterValidationError("digit"))
    // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    // .matches(/[^\w\s]/, getCharacterValidationError("special"))
    .min(8, "New password must be at least 8 characters")
    .max(16, "New password must be at most 16 characters"),
});

export const agencySignupSchema = Yup.object().shape({
  roleName: Yup.string("Please select your role").required(
    "Please select your role"
  ),
  agencyName: Yup.string()
    .required("Please enter your agency/department name")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .min(5, "Agency/department name must be at least 5 characters ")
    .max(50, "Agency/department name at most 50 characters"),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string()
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .matches(/^[1-9][0-9]*$/, "Please enter valid phone number")
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  zipCode: Yup.string()
    .required("Please enter your zip code")
    .min(5, "Zip code must be at least 5 numbers")
    .max(9, "Zip code maximum 9 numbers are allowed"),
  city: Yup.string("Please enter your city")
    .required("Please enter your city")
    .min(2, "City name must be at least 2 characters")
    .max(30, "City name maximum 15 characters are allowed")
    .test("no-leading-space", "Leading space are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing space are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single space between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  state: Yup.string("Please enter your state")
    .required("Please enter your state")
    .min(2, "State name must be at least 2 characters")
    .max(30, "City name maximum 15 characters are allowed")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  address: Yup.string()
    .required("Please enter your address")
    .test("no-consecutive-spaces", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string
      return !/\s{2,}/.test(value); // Check for consecutive spaces
    })
    .test("no-leading-space", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string after trimming
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Please enter valid address", (value) => {
      if (!value) return true; // Allow empty string after trimming
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    // .matches(/^[a-zA-Z0-9 ]*[$%^&*()_+!#~#`{}[]{}]*$/, "Please enter valid address")
    .min(3, "Address must be at least 3 characters long"),
  // .max(500, "Address cannot exceed 500 characters")
  password: Yup.string("Please enter your password")
    .required("Please enter your password")
    .matches(
      passwordRegex,
      "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character"
    )
    // .matches(/[0-9]/, getCharacterValidationError("digit"))
    // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    // .matches(/[^\w\s]/, getCharacterValidationError("special"))
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters"),
  confirmPassword: Yup.string()
    .required("Please enter your confirm password")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password is not match with password"
    ),
});

export const AgencyChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please enter old password"),
  // .matches(
  //   passwordRegex,
  //   "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character (e.g: John@1234)"
  // )
  // .matches(/[0-9]/, "Password requires a number")
  // .matches(/[a-z]/, "Password requires a lowercase letter")
  // .matches(/[A-Z]/, "Password requires an uppercase letter")
  // .matches(/[^\w]/, "Password requires a special character")
  // .min(8, "Old pssword must be 8 characters long")
  // .max(16, "Old password cannot exceed 16 characters"),
  newPassword: Yup.string()
    .required("Please enter new password")
    .matches(passwordRegex, "(e.g: John@1234)")
    // .matches(/[0-9]/, "New password requires a number")
    // .matches(/[a-z]/, "New password requires a lowercase letter")
    // .matches(/[A-Z]/, "New password requires an uppercase letter")
    // .matches(/[^\w]/, "New password requires a special character")
    .min(8, "New password must be 8 characters long")
    .max(16, "New password cannot exceed 16 characters"),
  confirmNewPassword: Yup.string()
    .required("Please Re-enter new password")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Re-enter new password must matched"
    ),
});

export const AgencyAddOfficerSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter first name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters is not allow")
    // .matches(START_SPACE, "Start space not allowed")
    // .matches(ALPHA_NUMERIC_DASH_REGEX, "First name contains only alphabate")
    .min(2, "First name must be 2 characters long"),

  lastName: Yup.string()
    .required("Please enter last name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters is not allow")
    // .matches(START_SPACE, "Start space not allowed")
    // .matches(ALPHA_NUMERIC_DASH_REGEX, "Last name contains only alphabate")
    .min(2, "Last name must be 2 characters long"),
  // mobileNumber: Yup.string("Please enter phone number")
  //   .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
  //   .required("Please enter your phone number")
  //   .min(10, "Mobile number must be 10 digit long")
  //   .max(10, "Max 10 digit"),

  mobileNumber: Yup.string()
    .required("Please enter phone number")
    .matches(/^[1-9][0-9]*$/, "Please enter valid phone number")
    .min(10, "Phone number must be 10 digits"),
  // .test("inputEntry", "Phone number should have digits only", digitsOnly)
  // .test("inputEntry", "Phone number is not valid", mobilePattern)
  // .min(10, "Phone number minimum length must be 10")
  // .max(12, "Phone number maximum length must be 12"),

  // zipCode: Yup.string("Please enter your zip code")
  //   .matches(/^(?!0+$)\d+$/, "Zip code should not be all zero")
  //   .required("Please enter your zip code")
  //   .min(5, "Zip code must be 5 digit long"),

  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    // .matches(emailRegex, "This is not a valid format"),
    .matches(emailRegex, "Please enter valid email address"),

  agencyId: Yup.string("Please select agency").required("Please select agency"),
  // roleName: Yup.string(),

  // officerType: Yup.string()
  //   .when('roleName', {
  //     is: (roleName) => roleName?.toLowerCase() !== 'department', // Condition to check
  //     then: Yup.string()
  //       .required('Please select officer type'), // Validation rule when condition is true
  //     otherwise: Yup.string() // No validation rule when condition is false
  //   }),

  officerType: Yup.string("Please select officer type").required(
    "Please select officer type"
  ),
  password: Yup.string("Please enter password")
    .required("Please enter your password")
    .matches(
      passwordRegex,
      "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character"
    )
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters"),
  confirmPassword: Yup.string()
    .required("Please enter confirm password")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password is not match with password"
    ),
});

export const AdminAddUserSchema = Yup.object().shape({
  name: Yup.string("Please enter your name")
    .required("Please enter your name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    // .test("no-leading-space", "Please enter valid name", (value) => {
    //   if (!value) return true; // Allow empty string -> Leading spaces are not allowed
    //   return value[0] !== " "; // Check if the first character is not a space
    // })
    // .test("single-spaces", "Only single spaces between words are allowed", (value) => {
    //   const splitValues = value.split(" ")
    //   console.log("splitValues", splitValues)
    //   if (!value) return true;
    //   const hasConsecutiveSpaces = /\s{2,}/.test(value);
    //   const hasLeadingOrTrailingSpaces = /^\s|\s$/.test(value);

    //   return !hasConsecutiveSpaces && !hasLeadingOrTrailingSpaces;
    //   // if (splitValues?.length > 0 && splitValues?.length === 2) return true; // Allow empty string -> Only single spaces between words are allowed
    //   // if (splitValues?.length > 0 && splitValues?.length > 2) return false; // Allow empty string -> Only single spaces between words are allowed
    //   // return !/\s{2,}/.test(value); // Check if there are two or more consecutive spaces
    // })
    // .test("no-trailing-space", "Please enter valid name", (value) => {
    //   if (!value) return true; // Allow empty string -> Trailing spaces are not allowed
    //   return value[value.length - 1] !== " "; // Check if the last character is not a space
    // })

    .min(2, "Please enter at least 2 characters")
    .max(25, "Name cannot exceed 25 characters")
    .matches(nameRegex, "Please enter valid name"),

  poc_name: Yup.string()
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .min(2, "Name must be at least 2 characters")
    .max(25, "Name cannot exceed 25 characters")
    .matches(nameRegex, "Please enter a valid point of contact name"),

  roleName: Yup.string()
    .required("Please select your role")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string()
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  // roleName: Yup.string()
  //   .required("Please select your role"),
  password: Yup.string("Please enter your password")
    .required("Please enter your password")
    .matches(
      passwordRegex,
      "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character"
    )
    // .matches(/[0-9]/, getCharacterValidationError("digit"))
    // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    // .matches(/[^\w\s]/, getCharacterValidationError("special"))
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters"),
  confirmPassword: Yup.string()
    .required("Please enter your confirm password")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password is not match with password"
    ),
});
export const AgencyVendorSchema = Yup.object().shape({
  name: Yup.string("Please enter your name")
    .required("Please enter your name / business name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    // .test("no-leading-space", "Please enter valid name", (value) => {
    //   if (!value) return true; // Allow empty string -> Leading spaces are not allowed
    //   return value[0] !== " "; // Check if the first character is not a space
    // })
    // .test("single-spaces", "Only single spaces between words are allowed", (value) => {
    //   const splitValues = value.split(" ")
    //   console.log("splitValues", splitValues)
    //   if (!value) return true;
    //   const hasConsecutiveSpaces = /\s{2,}/.test(value);
    //   const hasLeadingOrTrailingSpaces = /^\s|\s$/.test(value);

    //   return !hasConsecutiveSpaces && !hasLeadingOrTrailingSpaces;
    //   // if (splitValues?.length > 0 && splitValues?.length === 2) return true; // Allow empty string -> Only single spaces between words are allowed
    //   // if (splitValues?.length > 0 && splitValues?.length > 2) return false; // Allow empty string -> Only single spaces between words are allowed
    //   // return !/\s{2,}/.test(value); // Check if there are two or more consecutive spaces
    // })
    // .test("no-trailing-space", "Please enter valid name", (value) => {
    //   if (!value) return true; // Allow empty string -> Trailing spaces are not allowed
    //   return value[value.length - 1] !== " "; // Check if the last character is not a space
    // })

    .min(2, "Please enter at least 2 characters")
    .max(25, "Name cannot exceed 25 characters")
    .matches(nameRegex, "Please enter valid name"),

  poc_name: Yup.string()
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .min(2, "Name must be at least 2 characters")
    .max(25, "Name cannot exceed 25 characters")
    .matches(nameRegex, "Please enter a valid point of contact name"),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string()
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  // roleName: Yup.string()
  //   .required("Please select your role"),
  password: Yup.string("Please enter your password")
    .required("Please enter your password")
    .matches(
      passwordRegex,
      "Password must be contain 8 to 16 characters long and at least 1 lowercase letter, 1 uppercase letter, 1 special letter and 1 numeric character"
    )
    // .matches(/[0-9]/, getCharacterValidationError("digit"))
    // .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    // .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    // .matches(/[^\w\s]/, getCharacterValidationError("special"))
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters"),
  confirmPassword: Yup.string()
    .required("Please enter your confirm password")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password is not match with password"
    ),
});
export const AgencyVendorEditSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    .min(2, "Name must be at least 2 characters")
    .max(25, "Edit cannot exceed 25 characters")
    .matches(nameRegex, "Special characters are not allowed"),

  poc_name: Yup.string()
    .test(
      "no-consecutive-spaces",
      "Consecutive spaces are not allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/\s{2,}/.test(value); // Check for consecutive spaces
      }
    )
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .min(2, "Name must be at least 2 characters")
    .max(25, "Name cannot exceed 25 characters")
    .matches(nameRegex, "Special characters are not allows"),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string()
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  // .min(10, "Mobile number must be 10 digit long"),
});

export const AdminEditUserSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    .min(2, "Name must be at least 2 characters")
    .max(25, "Edit cannot exceed 25 characters")
    .matches(nameRegex, "Special characters are not allowed"),

  poc_name: Yup.string()
    .test(
      "no-consecutive-spaces",
      "Consecutive spaces are not allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/\s{2,}/.test(value); // Check for consecutive spaces
      }
    )
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .min(2, "Name must be at least 2 characters")
    .max(25, "Name cannot exceed 25 characters")
    .matches(nameRegex, "Special characters are not allows"),
  roleName: Yup.string()
    .required("Please select your role")
    .test(
      "no-consecutive-spaces",
      "Consecutive spaces are not allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/\s{2,}/.test(value); // Check for consecutive spaces
      }
    )
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string()
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  // .min(10, "Mobile number must be 10 digit long"),
});

//
export const AgencyEditUserSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your name")
    .test("valid-name", "Please enter a valid name", (value) => {
      if (!value) return true; // Allow empty string

      // Check for leading or trailing spaces
      if (/^\s|\s$/.test(value)) {
        return false;
      }

      if (value.split(" ")?.length > 0 && value.split(" ")?.length > 2) {
        return false;
      }

      // If all checks pass, return true
      return true;
    })
    .min(2, "Name must be at least 2 characters")
    .max(25, "Edit cannot exceed 25 characters")
    .matches(nameRegex, "Special characters are not allowed"),

  poc_name: Yup.string()
    .test(
      "no-consecutive-spaces",
      "Consecutive spaces are not allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/\s{2,}/.test(value); // Check for consecutive spaces
      }
    )
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .min(2, "Name must be at least 2 characters")
    .max(25, "Name cannot exceed 25 characters")
    .matches(nameRegex, "Special characters are not allows"),
  roleName: Yup.string()
    .required("Please select your role")
    .test(
      "no-consecutive-spaces",
      "Consecutive spaces are not allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/\s{2,}/.test(value); // Check for consecutive spaces
      }
    )
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    ),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter valid email address")
    .matches(emailRegex, "Please enter valid email address"),
  mobileNumber: Yup.string()
    // .matches(/^(?!0+$)\d+$/, "Phone number should not be all zero")
    // .required("Please enter your phone number")
    .min(10, "Phone number must be 10 digits"),
  // .min(10, "Mobile number must be 10 digit long"),
});
// Admin side
export const suspendDateValidationSchema = Yup.object().shape({
  startDate: Yup.string().required("Please select suspend from date"),
  endDate: Yup.string().required("Please select suspend to date"),
});

// Agency side
export const suspendAgencyDateValidationSchema = Yup.object().shape({
  startDate: Yup.string().required("Please select suspend from date"),
  endDate: Yup.string().required("Please select suspend to date"),
});

// Role Master

export const roleMasterValidationSchema = Yup.object().shape({
  roleName: Yup.string()
    .matches(/^[A-Za-z][A-Za-z\s]*$/, "Please enter valid role name")
    .test("no-leading-space", "Please enter valid roll name", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Please enter valid roll name", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test("single-spaces", "Please enter valid roll name", (value) => {
      if (!value) return true; // Allow empty string
      return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
    })
    .min(2, "Please enter minimum 2 characters in role name")
    .max(30, "Role name is too long, max 30 characters")
    .required("Please enter role name"),
});

export const roleMasterAgencyValidationSchema = Yup.object().shape({
  roleName: Yup.string()
    .matches(/^[A-Za-z][A-Za-z\s]*$/, "Please enter valid role name")
    .test("no-leading-space", "Leading spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Trailing spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    })
    .test(
      "single-spaces",
      "Only single spaces between words are allowed",
      (value) => {
        if (!value) return true; // Allow empty string
        return !/ {2,}/.test(value); // Check if there are two or more consecutive spaces
      }
    )
    .min(2, "Role name is too short, min 2 characters")
    .max(30, "Role name is too long, max 30 characters")
    .required("Role name is required"),
});

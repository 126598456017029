import React, { useEffect, useState } from "react";
import Index from "../../index";
import OTPInput from "react-otp-input";
import PagesIndex from "../../PageIndex";
import "./auth.style.css";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { AgencyotpSchema } from "../../../validation/FormikSchema";
import { useNavigationType } from "react-router-dom";

const AgencyForgotPasswordOtpVerifiy = () => {
  const location = PagesIndex.useLocation();
  const state = location.state || {};
  const navigate = PagesIndex.useNavigate();
  const navType = useNavigationType();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  const handleAdminOtpVerification = async (values) => {
    const data = {
      id: state.AgencyId,
      otp: values.otp,
    };
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_VERIFY_OTP,
        data
      );
      const ResetId = response?.data?.data[0]?._id;
      PagesIndex.toast.success(response?.data?.message);
      if (ResetId) {
        navigate("/resetpassword", { state: { ResetId } });
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };
  // Resend OTP functionality
  const resendOTP = async (setFieldValue) => {
    setMinutes(1);
    setSeconds(30);
    setFieldValue("otp", ""); // Clear the OTP field

    const urlEncoded = new URLSearchParams();
    urlEncoded.append("email", state?.email);
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_FORGOT_PASSWORD,
        urlEncoded
      );
      const AgencyId = response?.data?.data?.id;
      console.log("id", response?.data?.data?.id);
      PagesIndex.toast.success(response?.data?.message, {
        toastId: "customId",
      });
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customId",
      });
    }
  };
  // Timeing counting functionality
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);



// Handle browser back button
  useEffect(() => {
    if (navType === "POP") {
      navigate("/");
    }
  }, [navigate]);


  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <PagesIndex.Formik
            validationSchema={AgencyotpSchema}
            initialValues={{
              otp: "",
            }}
            onSubmit={handleAdminOtpVerification}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="admin-login-box">
                  <Index.Box className="admin-login-main">
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        OTP Verification
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Enter OTP
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.Box className="otp-main">
                          <Index.Box className="form-group  ">
                            <Index.Box className="form-control">
                              <OTPInput
                                name="otp"
                                numInputs={4}
                                inputStyle="otp-verification-input"
                                containerStyle="otp-verification-input-wrap"
                                value={values.otp}
                                onChange={(file) => setFieldValue("otp", file)}
                                error={Boolean(errors.otp)}
                                renderInput={(props) => <input {...props} />}
                              />
                            </Index.Box>

                            {errors.otp && touched.otp && (
                              <Index.FormHelperText error>
                                {errors.otp}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="countdown-box">
                        <Index.Box>
                          <Index.Typography className="countdown-text">
                            Time Remaining :{" "}
                            <span style={{ fontWeight: 600 }}>
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </span>
                          </Index.Typography>
                        </Index.Box>
                        {minutes === 0 && seconds === 0 ? (
                          <>
                            <Index.Typography
                              className="resend-text"
                              onClick={() => resendOTP(setFieldValue)}
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              Resend OTP ?
                            </Index.Typography>
                          </>
                        ) : (
                          // <Index.Box>
                          // <Index.Typography
                          //   className="resend-text"
                          //   style={{ color: "gray" }}
                          // >
                          //   Resend OTP in{" "}
                          //   {minutes < 10 ? `0${minutes}` : minutes}:
                          //   {seconds < 10 ? `0${seconds}` : seconds}
                          // </Index.Typography>
                          // </Index.Box>
                          null
                        )}
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Next
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AgencyForgotPasswordOtpVerifiy;

import React, { useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const OfficerDeleteModal = (props) => {
  const {
    handleCloseDeleteNew,
    openDeleteNew,
    getAgencyOfficerListData,
    deleteOfficerId,
    tabName
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { agencyLoginData} = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  console.log(agencyLoginData,"agencyLoginData")
  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;
  const navigate = PagesIndex.useNavigate();

  //   console.log("deleteOfficerId",deleteOfficerId);

  const handleOfficerDelete = () => {
    setIsLoading(true);
    const id = deleteOfficerId?._id;
    DataService.get(`${Api.Agency.DELETE_OFFICER}/${id}`)
      .then((response) => {
        if (response?.data?.status === 200) {
          PagesIndex.toast.success(response?.data?.message);
          handleCloseDeleteNew();
          // navigate(`/${roleName}/officer-management`, { state: {tabValue: "ACCEPTED" } });
          navigate(`/${roleName}/officer-management`, { state: {tabValue: tabName } });
          getAgencyOfficerListData();
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };

  const handleOfficerDeleteConfirm = () => {
    handleOfficerDelete();
  };
  return (
    <>
      <Index.Modal
        open={openDeleteNew}
        onClose={handleCloseDeleteNew}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner extra-delete"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            > Delete Officer
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={handleCloseDeleteNew}
              alt=""
            />
          </Index.Box>
          <Index.Box className="modal-body">
          <Index.Box className="admin-modal-hgt-scroll">
          <Index.Box className="delete-modal-body">
              <img
                src={PagesIndex.Svg.deletecross}
                alt="Delete"
                className="delete-icon"
              />
              <Index.Typography component="h6" className="delete-title ">Are you sure?</Index.Typography>
              <Index.Typography component="p" className="delete-message ">
                Do you really want to delete this officer? 
              </Index.Typography>
            </Index.Box>
            </Index.Box>
            </Index.Box>
           <Index.Box class="modal-footer">
            <Index.Box class="modal-footer-btn-flex">
              <Index.Box class="btn-main-primary">
              <Index.PrimaryButton
                btnLabel="Delete"
                className="btn-primary bg-approve"
                onClick={() => handleOfficerDeleteConfirm()}
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : "Click Me"}
              </Index.PrimaryButton>
              </Index.Box>
             <Index.Box class="btn-main-primary">
              <Index.PrimaryButton
                btnLabel="Cancel"
                onClick={handleCloseDeleteNew}
               
                 className="btn-primary bg-suspend"
              />
              </Index.Box>
            </Index.Box>
            </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default OfficerDeleteModal;

import React from "react";
import Index from "../../index";
import PagesIndex from "../../PageIndex";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";

export default function AgencyForgotPasswordNew() {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  const handleAgencyForgotPassword = async (values) => {
    // console.log("values>>", values);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("email", values?.email.toLowerCase());
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_FORGOT_PASSWORD,
        urlEncoded
      );
      const AgencyId = response?.data?.data?.id;
      console.log("id", response?.data?.data?.id);
      PagesIndex.toast.success(response?.data?.message);
      setTimeout(() => {
        navigate("/otpverification", { state: { AgencyId, email: values?.email.toLowerCase() } });
      }, 2000);
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          {/* <PagesIndex.AuthBackground /> */}
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <PagesIndex.Formik
                validationSchema={PagesIndex.AgencyForgotPasswordSchema}
                initialValues={{
                  email: "",
                }}
                onSubmit={handleAgencyForgotPassword}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldTouched,
                  handleFocus,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Forgot Password
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Enter your email, and we'll send you an OTP to reset your password.
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email Address
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            hiddenLabel
                            className="form-control"
                            placeholder="Enter Email Address"
                            name="email"
                            autoComplete="off"
                            onFocus={handleFocus}
                            value={values.email}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            error={errors.email && touched.email ? true : false}
                            helperText={
                              errors.email && touched.email
                                ? errors.email
                                : null
                            }
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Send OTP
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

import React, { memo, useEffect, useRef, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import { io } from "socket.io-client";
import { SOCKET_ENDPOINT } from "../../../../../config/DataService";
const ENDPOINT = "https://api.protekt-security.appworkdemo.com";


const ChatModal = ({
  open,
  onClose,
  chatId,
  setChatId,
  agencyJobData,
  selectedData,
  tabName
}) => {
  const [chatList, setChatList] = useState([]);
  const [groupedMessages, setGroupedMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [messageInput, setMessageInput] = useState("");
  const [socket, setSocket] = useState(null); // State to hold the socket instance
  // const token = localStorage.getItem("AgencyToken");
  const agencyLoginData = PagesIndex.useSelector(
    (state) => state.AgencySlice.agencyLoginData
  );

  const divRef = useRef(null);

  // Scroll to bottom of message list
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chatList]);

  useEffect(() => {
    if (open) {
      // Delay scrolling until after rendering
      setTimeout(() => {
        if (divRef.current) {
          divRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }, 1000);
    } else {
      setChatList([]);
    }
  }, [open]);
  

  useEffect(() => {
    const newSocket = io(SOCKET_ENDPOINT ?? ENDPOINT, {
      autoConnect: true,
      transports: ["websocket"],
    });

    newSocket.on("connect", () => {
      setSocket(newSocket);

      newSocket.emit("JoinChat", {
        id: chatId,
      });

      newSocket.on("PreviousMessages", (newMessages) => {
        console.log("PreviousMessages",newMessages)
        setChatList(newMessages); // Update chat list with previous messages
      });

      newSocket.on("connect_error", (error) => {
        console.log("Socket Error", error);
      });
    });

    return () => {
      newSocket.disconnect();
    };
  }, [chatId]);

  // console.log("79",chatId)

  const sendMessage = () => {
    if (messageInput.trim() === "") return; // Prevent sending empty messages

    const newMessage = {
      senderId: agencyLoginData?._id,
      message: messageInput,
      from: agencyLoginData?.agencyName,
      time: new Date().toISOString(),
      receiverId: selectedData ? selectedData?.userId?._id : null,
    };

    setChatList((prevChatList) => [...prevChatList, newMessage]); // Update frontend chat list immediately

    socket.emit("sendMessage", {
      id: chatId,
      from: agencyLoginData?.agencyName,
      message: messageInput,
      senderId: agencyLoginData?._id,
      receiverId: selectedData ? selectedData?.userId?._id : null,
    });

    setMessageInput(""); // Clear message input after sending
  };

  // Group messages by date for display
  const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
      const date = message?.time ? new Date(message.time).toISOString().split("T")[0] : null;

      if (date && !acc[date]) {
        acc[date] = [];
      }
      if (date) {
        acc[date].push(message);
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    if (chatList.length > 0) {
      const grouped = groupMessagesByDate(chatList);
      setGroupedMessages(grouped);
    }
  }, [chatList]);

  const formatTime = (time) => {
    return new Date(time).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  const today = PagesIndex.moment().format('YYYY-MM-DD');
  const yesterday = PagesIndex.moment().subtract(1, 'day').format('YYYY-MM-DD');

  const displayDate = (date) => {
    if (date === today) return 'Today';
    else if (date === yesterday) return 'Yesterday';
    else return PagesIndex.moment(date).format('MMM DD YYYY');
  };

  return (
    <Index.SwipeableDrawer
      className="filter-main chat-box-section"
      anchor="right"
      open={open}
      onClose={() => {
        onClose();
        setChatId("");
        setTabValue(0);
      }}
      onOpen={() => { }}
    >
      <Index.Box className="filter-header chat-header">
        <Index.Typography className="filter-title chat-title">
          {tabValue === 0 ? (
            <Index.Typography className="chat-user-name">
              {selectedData?.title ?? ""}
            </Index.Typography>
          ) : (
            "User List"
          )}
        </Index.Typography>
        <img
          src={PagesIndex.Svg.closeicon}
          onClick={() => {
            onClose();
            setChatId("");
            setTabValue(0);
          }}
          alt=""
        />
      </Index.Box>

   {   console.log("175",tabValue,groupedMessages, chatList)}

      {tabValue === 0 && (
        <Index.Box className="chat-userlist-main-box">
          {Object.keys(groupedMessages).map((date) => (
            <React.Fragment key={date}>
              <Index.Typography className="chat-date-header">
                {displayDate(date)}
              </Index.Typography>
              {groupedMessages[date].map((list, index) => (
                <React.Fragment key={index}>
                  {agencyLoginData?._id === list.senderId ? (
                    <Index.Box>
                      <Index.Box className="chat-right-text">
                        <Index.Typography className="chat-userlist-text">
                          {list.message}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Typography className="chat-time-text-right chat-time-text"> {formatTime(list.time)}</Index.Typography>
                    </Index.Box>
                  ) : (
                    <Index.Box>
                      <Index.Box className="chat-left-text">
                        <Index.Typography className="chat-userlist-text">
                          {list?.message}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Typography className="chat-time-text-left chat-time-text">{formatTime(list.time)}</Index.Typography>
                    </Index.Box>
                  )}
                </React.Fragment>
              ))}
              <div ref={divRef} />
            </React.Fragment>
          ))}
        </Index.Box>
      )}

      {tabName == "PendingUser" ? (
        <Index.Box className="chat-input-btn-box">
          <Index.TextField
            fullWidth
            className="chat-text-fied"
            size="small"
            placeholder="Message"
            value={messageInput}
            multiline
            row={3}
            maxRows={3}
            onChange={(e) => setMessageInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
          />

          <Index.Button className="chat-btn" disabled={messageInput.trim() === ''} onClick={sendMessage}>
            <Index.SendIcon className="chat-menu-icon" />
          </Index.Button>
        </Index.Box>
      ) : null
      }

    </Index.SwipeableDrawer>
  );
};

export default memo(ChatModal);

import React, { useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const SkillDeleteModal = (props) => {
  const {
    handleCloseSkillsDelete,
    skillsOpenDelete,
    getSkillList,
    deleteSkillsId,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSkillDelete = async () => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", deleteSkillsId);
    try {
      const response = await DataService.post(
        PagesIndex.Api.Agency.DELETE_SKILL,
        urlEncoded
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customId",
        });
        handleCloseSkillsDelete();
        getSkillList();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); // Simulating 2 seconds loading time
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customId",
      });
      setIsLoading(false);
    }
  };

  const handleOfficerDeleteConfirm = () => {
    handleSkillDelete();
  };
  return (
    <>
      <Index.Modal
        open={skillsOpenDelete}
        onClose={handleCloseSkillsDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner extra-delete"
        >


          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            > Delete Skill 

            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={handleCloseSkillsDelete}
              alt=""
            />
          </Index.Box>


          <Index.Box className="modal-body">
            <Index.Box className="admin-modal-hgt-scroll">
              <Index.Box className="delete-modal-body">
                <img
                  src={PagesIndex.Svg.deletecross}
                  alt="Delete"
                  className="delete-icon"
                />
                <Index.Typography component="h6" className="delete-title">Are you sure?</Index.Typography>
                <Index.Typography component="p" className="
                delete-message">
                      Do you really want to delete this skills?
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="modal-footer">
            <Index.Box className="modal-footer-btn-flex">
              <Index.PrimaryButton
                btnLabel="Delete"
                className="btn-primary bg-approve"
                onClick={() => handleOfficerDeleteConfirm()}
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : "Click Me"}
              </Index.PrimaryButton>

              <Index.PrimaryButton
                btnLabel="Cancel"
                onClick={handleCloseSkillsDelete}
                className="btn-primary bg-suspend"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default SkillDeleteModal;

import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

const AdminDetailsOfPendingOfficer = () => {
  const [ratingvalue, setRatingValue] = React.useState(2);
  const location = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const adminPendingjobsDetail = location?.state?.officerDetails;
  console.log(adminPendingjobsDetail, "adminPendingjobsDetail")
  
  return (
    <>

      <Index.Box className="user-list-flex">
        <Index.Box className="admin-page-title user-list-page-title">
          <Index.Box
            onClick={() =>
              navigate("/admin/adminjobmanagement", { state: location?.state?.tab })
            }
          // onClick={() =>
          //   navigate("/admin/adminjobmanagement", {
          //     state: {
          //       status1: "PendingOfficer",
          //       status2: "afterOfficerApprove"
          //     }
          //   })}
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            />
            {adminPendingjobsDetail?.title}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="admin-sub-flex">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Assigned Officer & Associated Agency
          </Index.Typography>
        </Index.Box>
      </Index.Box>



      <>
        {adminPendingjobsDetail?.officerId.map((item, index) => {
          const officershortDetail = item?.officerId;
          return (
            <Index.Box className="card-main">
              <Index.Box className="card-left">
                <Index.Box className="officer-profile-flex">
                  <img
                    src={PagesIndex.Png.usericon}
                    className="prof-img"
                    alt=""
                  />
                  <Index.Box className="officer-detail">
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        {officershortDetail?.firstName ? (
                          <>
                            {officershortDetail?.firstName +
                              " " +
                              officershortDetail?.lastName}
                          </>
                        ) : null}
                        &nbsp;
                      </Index.Typography>

                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        ({officershortDetail?.officerType})
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Officer Assigned on :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className=" work-value-text"
                      >
                        {PagesIndex.dayjs(item?.jobAcceptedDate).format(
                          "MM-DD-YYYY"
                        )}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          );
        })}
      </>
      <>
        {[1].map((item, index) => (
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail-flex">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable "
                  >
                    {adminPendingjobsDetail?.department == "Police department" ? "Associated Department" : "Associated Agency"}:&nbsp;

                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className=" work-value-text"
                  >
                    {adminPendingjobsDetail?.approveBy?.agencyName}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        ))}
      </>


      <Index.Box className="admin-sub-title-main">
        <Index.Typography
          component="p"
          variant="p"
          className="admin-sub-title"
        >
          General Information
        </Index.Typography>
      </Index.Box>
      <Index.Box className="card-main">
        <Index.Box className="card-left">
          <Index.Box className="officer-detail">
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Title :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.title}
              </Index.Typography>
            </Index.Box>


            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Posted By :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.userId?.name}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Posted On :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.dayjs(adminPendingjobsDetail?.createdAt).format(
                  "MM-DD-YYYY"
                )}
              </Index.Typography>
            </Index.Box>


            {
              adminPendingjobsDetail?.totalAmount !== "" ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Payment :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminPendingjobsDetail?.totalAmount}
                  </Index.Typography>
                </Index.Box>

              ) : null

            }

            {
              adminPendingjobsDetail?.paymentIntentId !== "" ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Transaction Id  :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminPendingjobsDetail?.paymentIntentId}
                  </Index.Typography>
                </Index.Box>

              ) : null
            }

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Phone Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.mobileNumber}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Email Address :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.userId?.email}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Point Of Contact Name :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.pointOfContactName}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Point Of Contact Number :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {
                  adminPendingjobsDetail?.pointOfContactNumber || "-"
                }
              </Index.Typography>
            </Index.Box>



            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Location :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.location}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Service :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.service}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Department :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.department}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Job Type :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.jobType?.jobType}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Zip Code :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.zipCode}
              </Index.Typography>
            </Index.Box>

            {adminPendingjobsDetail?.supervisor ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Supervisor :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.supervisor}
                </Index.Typography>
              </Index.Box>) : (null)}
            {adminPendingjobsDetail?.nonSupervisor ? (
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Non Supervisor :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.nonSupervisor}
                </Index.Typography>
              </Index.Box>
            ) : (null)}

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Date/Time :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.jobSchedule?.map((item, index) => {
                  return (
                    <>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        <span className="day-title">
                          {
                            PagesIndex.dayjs(item?.jobStartDate).format(
                              "MM-DD-YYYY"
                            ) +
                            " to " +
                            PagesIndex.dayjs(item?.jobEndDate).format(
                              "MM-DD-YYYY"
                            )
                          }
                        </span>{" "}
                        -{" "}
                        <span>
                          {item?.jobStartTime
                            ? item?.jobStartTime + " to "
                            : null}
                          {item?.jobEndTime}
                        </span>
                      </Index.Typography>
                      {/* ):null} */}
                    </>
                  );
                })}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Total Hours :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {PagesIndex.timeDifferenceCalculation(adminPendingjobsDetail)} hour

              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Pay Rate :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.rate ? "$" : null}
                {adminPendingjobsDetail?.rate}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                No of Officer :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.totalOfficer}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="work-title-flex">
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Information :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.information}
              </Index.Typography>
            </Index.Box>
            <Index.Box
              className="work-title-flex"
            >
              <Index.Typography
                component="p"
                variant="p"
                className="work-title-lable"
              >
                Description :&nbsp;
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="work-value-text"
              >
                {adminPendingjobsDetail?.description}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <>
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Organizer (Client)
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Mobile Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.userId?.mobileNumber}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminPendingjobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </>
      <>
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Job Log
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(
                    adminPendingjobsDetail?.createdAt
                  ).format("MM-DD-YYYY")}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Transferred To :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Transferred On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </>

    </>
  );
};

export default AdminDetailsOfPendingOfficer;

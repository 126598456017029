import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AdminPendingJobModal = (props) => {
  const {
    pandingRequestopen,
    handleClosePandingRequest,
    pendingjobId,
    getJobListData,
  } = props;

  const [jobList, setJobList] = useState();
  const [loading, setLoading] = useState(false);


  const getSingleJobList = () => {
    setLoading(true);
    // if (pendingjobId || pandingRequestopen) {
    const jobId = pendingjobId?._id || pandingRequestopen?._id;
    PagesIndex?.DataService?.get(
      PagesIndex?.Api?.Admin?.GET_SINGLE_JOB_LIST + `/${jobId}`
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          setJobList(res?.data?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        PagesIndex?.toast?.error(err?.response?.data?.mes);
        setLoading(false);
      });
    // } else {
    //     setLoading(false);
    // }
  };

  useEffect(() => {
    if (pendingjobId || pandingRequestopen) {
      getSingleJobList();
    }
  }, [pendingjobId, pandingRequestopen]);

  // job approverd / Rejected
  const handleApproveRequest = (status) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", pendingjobId?._id);
    urlEncoded.append("status", status);
    PagesIndex?.DataService.post(
      PagesIndex?.Api?.Admin?.JOB_APPROVE_REJECT,
      urlEncoded
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          // setJobList(res?.data?.data);
          handleClosePandingRequest();
          getJobListData();
          PagesIndex?.toast?.success(res?.data?.message);
        }
      })
      .catch((err) => {
        PagesIndex?.toast?.error(err?.response?.data?.mes);
      });
  };

  return (
    <>
      <Index.Modal
        open={pandingRequestopen}
        onClose={handleClosePandingRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          {loading ? (
            <Loader />
          ) : (
            <Index.Box>
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  User's Pending Job Request
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.closeblack}
                  className="modal-close-icon"
                  onClick={handleClosePandingRequest}
                  alt=""
                />
              </Index.Box>
              <Index.Box className="modal-body">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Title :
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.title}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Posted By :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.dayjs(jobList?.createdAt).format("MM-DD-YYYY")}
                  </Index.Typography>
                </Index.Box>

                {
                  jobList?.amount !== undefined ? (
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Total Payment :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {jobList?.amount}

                      </Index.Typography>
                    </Index.Box>
                  ) : null
                }
                {
                  jobList?.paymentIntentId !== undefined ? (
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Transaction Id :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {jobList?.paymentIntentId}

                      </Index.Typography>
                    </Index.Box>
                  ) : null
                }
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Phone Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.mobileNumber}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.userId?.email}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Point Of Contact Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.pointOfContactName}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Point Of Contact Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.pointOfContactNumber || "-"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Location :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.location}
                  </Index.Typography>
                </Index.Box>
                {/* <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    {pendingjobId?.department == "Police department" ? "Associated Department" : "Associated Agency"} :&nbsp;

                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {pendingjobId?.approveBy?.agencyName}
                  </Index.Typography>
                </Index.Box> */}

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Service :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.service}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Department :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.department}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Date/Time :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                    sx={{ whiteSpace: "break-spaces" }}
                  >
                    {PagesIndex.showDateTime(jobList)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Type :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.jobType?.jobType}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Zip Code :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.zipCode}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    No of Officer :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.totalOfficer}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Information :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.information}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Hours :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.timeDifferenceCalculation(jobList)} hour
                  </Index.Typography>
                </Index.Box>
                {jobList?.rate == undefined ? null : (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Pay Rate (Per hour) :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {" "}
                      {jobList?.rate ? "$" : null}
                      {jobList?.rate}
                    </Index.Typography>
                  </Index.Box>
                )}

                {jobList?.supervisor ? (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Supervisor :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.supervisor}
                    </Index.Typography>
                  </Index.Box>) : (null)}
                {jobList?.nonSupervisor ? (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Non Supervisor :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.nonSupervisor}
                    </Index.Typography>
                  </Index.Box>
                ) : (null)}

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Description :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {jobList?.description}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              {/* <Index.Box className="modal-footer">
                {jobList?.isRate == true ? (
                  <>
                    <Index.Box className="modal-footer-btn-flex">
                      {
                        (jobList?.status === "APPROVED" || jobList?.status === "REJECTED") ?
                          (<></>) :
                          (<>
                            <Index.PrimaryButton
                              btnLabel={
                                jobList?.status === "APPROVED"
                                  ? "Approved"
                                  : "Approve"
                              }
                              disabled={jobList?.status === "REJECTED" ? true : false}
                              className="btn-primary bg-approve appr-rej-btn"
                              onClick={() => handleApproveRequest("APPROVED")}
                            />

                            <Index.PrimaryButton
                              btnLabel={
                                jobList?.status === "REJECTED" ? "Rejected" : "Reject"
                              }
                              disabled={jobList?.status === "APPROVED" ? true : false}
                              className="btn-primary bg-suspend appr-rej-btn"
                              onClick={() => handleApproveRequest("REJECTED")}
                            />
                          </>)
                      }
                    </Index.Box>
                  </>
                ) : null}
              </Index.Box> */}
            </Index.Box>
          )}
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AdminPendingJobModal;

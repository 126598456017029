import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataService from "../../../../config/DataService";
import PagesIndex from "../../../../container/PageIndex";
import Index from '../../..';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';

const AgencyPaymentType = (props) => {
    const navigate = useNavigate();
    const agencyLoginData = useSelector(
        (state) => state.AgencySlice.agencyLoginData
    );
    const roleName = agencyLoginData?.roleId?.Permission_name;
    // Define payment types
    const paymentTypes = [
        { title: "Direct Pay" },
        { title: "Through Police Department" },
    ];

    // Initial form values and validation
    const [initialValues, setInitialValues] = useState({
        type: ""
    });

    // Define validation schema
    const validationSchema = Yup.object({
        type: Yup.string().required("Please select payment type")
    });

    // Handle form submission
    const handlePaymentPercentageForm = async (values) => {
        console.log("33", values);
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("paymentType", values?.type);
        try {
            const response = await DataService.post(
                PagesIndex.Api.Agency.PAYMENT_TYPE_CHOOSE,
                urlEncoded
            );
            if (response?.data?.status === 200) {
                PagesIndex.toast.success(response?.data?.message);
                navigate(`/${roleName?.replace(/ /g, "-")}/dashboard`);
            }
        } catch (error) {
            PagesIndex.toast.error(error?.response?.data?.message);
        }
    };

    return (
        <>
            <Index.Box className="extra-work-permit-request-wrape">
                <Index.Box className="user-list-flex">
                    <Index.Typography
                        className="admin-page-title user-list-page-title"
                        component="h2"
                        variant="h2"
                    >
                        Payment Type
                    </Index.Typography>
                </Index.Box>
                <Index.Box className="common-card">
                    <Index.Box className="page-container">
                        <PagesIndex.Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handlePaymentPercentageForm}
                        >
                            {({
                                values,
                                errors,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                touched,
                                handleBlur
                            }) => (
                                console.log("787", errors),
                                < form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <Index.Box className="form-container" sx={{ padding: '20px' }}>
                                        <Index.Box className="form-body">
                                            <Index.Grid container spacing={2}>
                                                <Index.Grid item xs={12}>
                                                    <Index.Box className="input-box">
                                                        {/* <Index.FormHelperText className="form-label" sx={{ marginBottom: "6px" }}>
                                                Payment Type
                                            </Index.FormHelperText> */}
                                                        <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                                <Index.Select
                                                                    className="dropdown-select drop-select"
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                    name="type"
                                                                    value={values.type}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors.type && touched.type}
                                                                    renderValue={
                                                                        values.type ? undefined : () => "Select payment type"
                                                                    }
                                                                >
                                                                    {paymentTypes.map((data, index) => (
                                                                        <Index.MenuItem
                                                                            key={index}
                                                                            value={data.title}
                                                                        >
                                                                            {data.title}
                                                                        </Index.MenuItem>
                                                                    ))}
                                                                </Index.Select>
                                                            </Index.FormControl>
                                                            <Index.FormHelperText error>
                                                                {errors.type && touched.type && errors.type}
                                                            </Index.FormHelperText>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Grid>
                                            </Index.Grid>
                                        </Index.Box>
                                        <Index.Box className="userlist-btn-flex">
                                            <Index.Box className="userlist-inner-btn-flex">
                                                <Index.Box className="adduser-btn-main btn-main-primary">
                                                        <Index.Button type="submit" className="adduser-btn btn-primary">
                                                        Submit
                                                    </Index.Button>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </form>
                            )}
                        </PagesIndex.Formik>
                    </Index.Box>
                </Index.Box>
            </Index.Box >
        </>
    );
};

export default AgencyPaymentType;
